.join-session-step {
  width: 100%;
  margin: 0 auto;
}

.join-session-step .select .form-label {
  display: block;
  color: black;
  margin-bottom: 5px;
  font-size: 14px;
  font-family: "Neometric Medium";
}

.join-session-step .select .form-select {
  display: block;
  color: black;
  margin-bottom: 15px;
  font-size: 14px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  width: 100%;
  padding: 10px 15px;
  font-family: "Neometric Regular";
  background: #fff;
  font-weight: 600;
}
.join-session-step .select .form-select:focus {
  outline: none;
}

.join-session-title {
  font-size: 20px;
}
.audiovideoinput {
  border: 1px solid rgba(0, 0, 0, 0.4);
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 16px;
  font-family: "Neometric Regular";
  font-weight: 600;
  font-size: 14px;
}
.audiovideolabel {
  display: block;
  color: black;
  margin-bottom: 5px;
  font-size: 14px;
  font-family: "Neometric Medium";
}
