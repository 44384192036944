.mobile-modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 555555;
  left: 0;
  top: 0px;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* mobile-Modal Content */
.mobile-modal-content {
  background-color: #fefefe;
  border-radius: 20px;
  /* margin: 0 auto; */
  border: 1px solid #888;
  width: calc(100vw - 40px);
  height: 80vh;
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* The Close Button */
.close {
  color: #000000;
  font-size: 28px;
  font-weight: 600;
  position: absolute;
  top: 10px;
  right: 20px;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.mobile-modal-content-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 26px;
  letter-spacing: 0.8px;
}

.modal-first-paragragh {
  letter-spacing: 0.8px;
  margin-top: 17px;
  line-height: 18px;
  text-align: center;
}
.modal_image {
  margin-top: 47px;
  margin-bottom: 17px;
}
.modal-second-paragragh {
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.8px;
  color: #2c205b;
}
