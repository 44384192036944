.mentee-dashboard-find-mentor-modal {
  position: fixed; /* Stay in place */
  z-index: 555555;
  left: 0;
  top: 0px;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content */
.mentee-dashboard-find-mentor-modal-content {
  background-color: #fefefe;
  border-radius: 20px;
  margin: auto;
  border: 0px solid #888;
  width: 864px;
  height: 646px;
  overflow-x: hidden;
  overflow-y: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* The Close Button */
.mentee-dashboard-find-mentor-modal-close {
  color: white;
  font-size: 28px;
  font-weight: bold;
  position: absolute;
  right: 20px;
  top: 22px;
  transition: 0.2s ease-in-out;
}

.mentee-dashboard-find-mentor-modal-close:hover,
.mentee-dashboard-find-mentor-modal-close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
.mentee-dashboard-find-mentor-modal-header {
  position: sticky;
  top: 0;
  z-index: 111;
  background: #5555db;
  height: 143px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #5555db;
}
.mentee-dashboard-find-mentor-modal-header-container {
  padding: 0 90px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mentee-dashboard-find-mentor-modal-header-mentor-avatar {
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 100%;
  width: 87px;
  height: 87px;
}
.mentee-dashboard-find-mentor-modal-header-mentor-name {
  color: #ffffff;
  line-height: 25px;
}
.mentee-dashboard-find-mentor-modal-header-mentor-industry {
  color: #ffffff;
  line-height: 25px;
}
.mentee-dashboard-find-mentor-modal-header-mentor-credits {
  background: #6666df;
  border-radius: 10px;
  width: 95px;
  height: 87px;
  font-family: "Neometric Medium";
  font-size: 12px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.mentee-dashboard-find-mentor-modal-header-mentor-credits-value {
  background: #8b8be7;
  border-radius: 5px;
  width: 73px;
  height: 27px;
  display: flex;
  font-size: 14px;
  font-family: "Neometric Regular";
  justify-content: center;
  align-items: center;
}
.mentee-dashboard-find-mentor-modal-popup-content {
  overflow-y: hidden;
}
.mentee-dashboard-find-mentor-modal-mentor-info {
  margin-top: 20px;
  display: flex;
  width: 100%;
  justify-content: center;
}
.mentee-dashboard-find-mentor-modal-mentor-info > div {
  width: calc(50% - 90px);
}
.mentee-dashboard-find-mentor-modal-mentor-info-about-me {
  min-height: 470px;
  background: #f5f7f9;
  border-radius: 10px;
}
.mentee-dashboard-find-mentor-modal-mentor-info-about-me-container {
  word-wrap: break-word;
  margin: 30px;
}
.mentee-dashboard-find-mentor-modal-mentor-info-mentorship-container {
  height: 354px;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}
.mentee-dashboard-find-mentor-modal-mentor-info-mentorship-focus {
  min-height: 230px;
  background: #f5f7f9;
  margin-bottom: 10px;
  border-radius: 10px;
}
.mentee-dashboard-find-mentor-modal-mentor-info-mentorship-focus-trails {
  height: 30px;
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-right: 10px;
  border-radius: 10px;
  padding: 0px 20px;
  font-family: "Neometric Medium";
}

.mentee-dashboard-find-mentor-modal-mentor-info-mentorship-focus-help-tags-container {
  display: flex;
  flex-wrap: wrap;
  width: 313px;
}
.mentee-dashboard-find-mentor-modal-mentor-info-mentorship-focus-help-tags {
  height: 30px;
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-right: 10px;
  border-radius: 10px;
  padding: 0px 20px;
  font-family: "Neometric Regular";
  background: #e5e5e5;
  color: #5555db;
}
.mentee-dashboard-find-mentor-modal-mentor-info-uniqueness {
  min-height: 160px;
  margin-top: 10px;
  background: #f5f7f9;
  border-radius: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px 30px 20px 30px;
}
.mentor-dashboard-find-mentor-modal-interview-type-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  width: 630px;
  justify-content: flex-start;
}
.mentor-dashboard-find-mentor-modal-interview-type {
  margin: 10px 5px;
  border: 1px solid #5555db;
  box-sizing: border-box;
  border-radius: 10px;
  width: 200px;
  height: 170px;
  cursor: pointer;
  position: relative;
  color: #2c205b;
}
.mentor-dashboard-find-mentor-modal-interview-type:hover {
  color: white !important;
  background: #5555db;
}
.mentor-dashboard-find-mentor-modal-interview-type-active {
  color: white !important;
  background: #5555db;
}
.mentor-dashboard-find-mentor-modal-interview-type-cost-container {
  display: flex;
  position: absolute;
  width: 170px;
  bottom: 10px;
}
.mentor-dashboard-find-mentor-modal-interview-type-time {
  display: flex;
  justify-content: flex-start;
  padding-left: 9px;
  align-items: center;
  background: #eeeefb;
  color: #2c205b;
  height: 28px;
  width: 70%;
  border-radius: 5px;
  margin-right: 5px;
  font-size: 14px;
  font-family: "eina";
}
.mentor-dashboard-find-mentor-modal-interview-type-coins {
  background: #eeeefb;
  color: #2c205b;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  height: 28px;
  margin-left: 5px;
  font-size: 14px;
  font-family: "eina";
}

.calendly-inline-widget {
  min-width: 320px;
  min-height: 470px;
  height: 570px !important;
}

.popupBtn:hover {
  background-color: #5555db !important;
  color: white !important;
}
.mentee-dashboard-find-mentor-confimation-modal {
  position: fixed; /* Stay in place */
  z-index: 555555;
  left: 0;
  top: 0px;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: hidden; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content */
.mentee-dashboard-find-mentor-confirmation-modal-content {
  background-color: #fefefe;
  border-radius: 10px;
  margin: auto;
  border: 1px solid #888;
  width: 400px;
  height: 617px;
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.mentee-dashboard-find-mentor-confirmation-modal-header {
  background: #5555db;
  height: 180px;
  border-radius: 10px 10px 0px 0px;
}
.mentee-dashboard-find-mentor-confirmation-modal-header-container {
  /* padding: 30px 100px; */
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
}
.mentee-dashboard-find-mentor-confirmation-modal-schedule {
  margin-top: 35px;
  display: flex;
  flex-wrap: wrap;
  width: 220px;
}
.mentee-dashboard-find-mentor-confirmation-modal-schedule > div {
  /* width: 100px; */
  margin: 15px;
}
.mentee-dashboard-find-mentor-confirmation-modal-cancel-link {
  margin-top: 25px;
  font-family: "Neometric Medium";
  color: #adadad;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
}
.mentee-dashboard-find-mentor-confirmation-modal-cancel-link:hover {
  text-decoration: underline;
}
@media screen and (max-height: 730px) {
  .mentee-dashboard-find-mentor-modal-popup-content {
    height: 400px;
    overflow-y: scroll;
  }
  .mentee-dashboard-sessions-detail-modal-popup-content {
    height: 375px;
    overflow-y: scroll;
  }
  .mentee-dashboard-find-mentor-modal-popup-content::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  .mentee-dashboard-find-mentor-modal-popup-content::-webkit-scrollbar-track {
    visibility: hidden;
    /* background: #c0c0c0; */
  }

  /* Handle */
  .mentee-dashboard-find-mentor-modal-popup-content::-webkit-scrollbar-thumb {
    background: rgba(44, 32, 91, 0.5);
    opacity: 50%;
    border-radius: 10px;
  }

  /* Handle on hover */

  .mentee-dashboard-find-mentor-modal-popup-content::-webkit-scrollbar-thumb:hover {
    visibility: visible;

    background: grey;
  }

  .mentee-dashboard-find-mentor-modal-confirmation-popup-content {
    height: 375px;
    overflow-y: scroll;
  }
  .mentee-dashboard-find-mentor-modal-confirmation-popup-content::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  .mentee-dashboard-find-mentor-modal-confirmation-popup-content::-webkit-scrollbar-track {
    visibility: hidden;
  }
  /* Handle */
  .mentee-dashboard-find-mentor-modal-confirmation-popup-content::-webkit-scrollbar-thumb {
    background: rgba(44, 32, 91, 0.5);
    opacity: 50%;
    border-radius: 10px;
  }

  /* Handle on hover */

  .mentee-dashboard-find-mentor-modal-confirmation-popup-content::-webkit-scrollbar-thumb:hover {
    visibility: visible;
    background: grey;
  }
  .mentee-dashboard-find-mentor-modal-content {
    height: 515px !important;
  }
  .mentee-dashboard-find-mentor-confirmation-modal-content {
    height: 520px !important;
  }
  .mentee-dashboard-find-mentor-confirmation-modal-header {
    position: sticky;
    top: 0;
  }
  .calendly-inline-widget {
    height: 375px !important;
    min-height: 375px;
  }
  /* .mentee-dashboard-find-mentor-modal-container-wrapper {
    margin-bottom: 20px;
  } */
}

.rs-loader-spin::after {
  border-color: #5555db transparent transparent !important;
}
