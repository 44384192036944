.mentee-dashboard-home-welcome-banner {
  height: 141px;
  background: #5555db;
  border-radius: 10px;
  position: relative;
  display: flex;
  align-items: center;
}
.mentee-dashboard-home-components-line {
  margin: 5px 0 35px 0px;
}
.mentee-dashboard-home-welcome-banner-image-container {
  position: absolute;
  bottom: 0;
  left: 40px;
}
.mentee-dashboard-home-welcome-banner-heading-container {
  margin-left: 320px;
}
.mentee-dashboard-home-welcome-banner-heading-container h3 {
  color: white;
  line-height: 25px;
}
.mentee-dashboard-home-upcoming-sessions-view-all {
  font-size: 14px;
  cursor: pointer;
  position: absolute;
  right: 0px;
  bottom: 10px;
}
.mentee-dashboard-home-upcoming-sessions-view-all:hover {
  text-decoration: underline;
}
.mentee-dashboard-home-upcoming-sessions-container {
  display: flex;
}
.mentee-dashboard-home-upcoming-sessions-container > div {
  height: 138.38px;
  width: 100%;
  border-radius: 5px;
  margin-right: 20px;
}
.mentee-dashboard-home-upcoming-sessions-container > div:last-child {
  margin: 0;
}
.mentee-dashboard-home-upcoming-sessions-empty {
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 60px;
  border-radius: 10px;
  width: 860px;
  height: 466px;
}
.mentee-dashboard-home-upcoming-sessions-empty-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.mentee-dashboard-home-upcoming-sessions-empty-container > div:nth-child(2) {
  margin: 40px 0 0 0;
}
.mentee-dashboard-home-upcoming-sessions-item {
  background: #fac4dc;
  display: flex;
}
.mentee-dashboard-home-upcoming-sessions-item-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 30px 25px 20px 25px;
}
.mentee-dashboard-home-upcoming-sessions-item-date-time-container {
  display: flex;
  font-family: "Neometric Regular";
  font-size: 13px;
}
.mentee-dashboard-home-upcoming-sessions-item-date-time {
  font-family: "Neometric Medium";
  line-height: 22px;
}
.mentee-dashboard-home-mentorship-areas {
  background: #ffffff;
  border-radius: 10px;
  height: 275px;
}
.mentee-dashboard-home-mentorship-areas-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
}
.mentee-dashboard-home-mentorship-areas-container > div {
  width: 50%;
}

.mentee-dashboard-home-mentorship-areas-container-leftCol {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 30px;
}

.mentee-dashboard-home-mentorship-area-label-item {
  font-family: "Neometric Regular";
  width: 250px;
  display: flex;
  font-size: 14px;

  justify-content: space-between;
  border-bottom: 1px solid #f4f4f4;
  padding: 5px 0px;
  margin: 5px 0px;
}
.mentee-dashboard-home-mentorship-area-label-title {
  display: flex;
  align-items: center;
}
.mentee-dashboard-home-mentorship-area-label-title-before {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  margin-right: 10px;
}
.mentee-dashboard-home-row {
  display: flex;
  margin-top: 25px;
}
.mentee-dashboard-home-row > div {
  margin: 0px 20px;
}
.mentee-dashboard-home-leftCol {
  width: 685px;
}
.mentee-dashboard-home-rightCol {
  width: 360px;
  height: fit-content;
  min-height: 665px;
  /* overflow-y: auto; */
  background: #ffffff;
  border-radius: 10px;
}
.mentee-dashboard-home-rightCol {
  z-index: 1;
}
.mentee-dashboard-home-rightCol-container {
  margin: 35px 30px;
}
.mentee-dashboard-home-accordion-container {
  margin-top: 50px;
}
.mentee-dashboard-home-accordion {
  width: 100%;
  background-color: transparent;
  margin: auto;
  /* margin-top: 500px; */
}

.mentee-dashboard-home-title {
  font-family: "Neometric Medium";
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* width: 300px; */
  margin-top: 2px;
  height: auto;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
  text-align: left;
  position: relative;
  z-index: 2000;
  border-radius: 4px;

  transition: all 0.2s ease-in;
}
.mentee-dashboard-home-title:hover {
  cursor: pointer;
}
.mentee-dashboard-home-title-before {
  background: #eeeefb;
  border-radius: 5px;
  width: 23px;
  height: 24px;
}
.mentee-dashboard-home-title-text {
  margin-left: 20px;
}

.mentee-dashboard-home-content {
  height: 50px;
  width: 100%;
  background-color: transparent;
  font-size: 14px;
  font-size: 14px;
  line-height: 106.5%;
  text-align: center;
  position: relative;
  z-index: 1000;
  margin-top: -40px;
  padding-bottom: 15px;
  text-align: left;
  transition: all 200ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.mentee-dashboard-home-content-open {
  margin-top: 0px;
  height: 100%;
  transition: all 350ms cubic-bezier(0.08, 1.09, 0.32, 1.275);
}

.mentee-dashboard-home-content-text {
  width: 91%;
  padding: 15px 0 5px 25px;
  visibility: hidden;
  margin-left: 6px;
  opacity: 0;
  overflow: auto;
  position: relative;
  transition: all 0.2s ease-in;
}

.mentee-dashboard-home-content-text-open {
  visibility: visible;
  opacity: 1;
  transition: all 0.8s ease-in;
}

.fa-angle-right {
  color: rgba(0, 0, 0, 1);
  transition: all 0.6s cubic-bezier(0.08, 1.09, 0.32, 1.275);
}

.fa-rotate-90 {
  color: rgba(0, 0, 0, 1);
}

.mentee-dashboard-home-arrow {
  background: #eeeefb;
  border-radius: 5px;
  width: 23px;
  height: 24px;
}
.mentee-dashboard-home-accordian-tip-container {
  display: flex;
  align-items: center;
}
.mentee-dashboard-home-accordian-tip-container > div {
  margin: 10px 0px;
  word-break: break-word;
}

.mentee-dashboard-home-accordian-tip-before {
  background: #5555db;
  width: 11px;
  height: 11px;
  border-radius: 100%;
  position: absolute;
  left: 1px;
}
.mentee-dashboard-home-accordian-tip-before::before {
  content: "";
  position: absolute;
  height: 500px;
  width: 2px;
  background-color: #5555db;
  bottom: 0;
  left: 4px;
  z-index: 111;
  color: #5555db;
}
.mentee-dashboard-home-accordian-tip-title {
  color: #5555db;
  font-family: "Neometric Regular";
  font-size: 14px;
}
@media screen and (max-width: 1410px) {
  .mentee-dashboard-home-leftCol {
    width: 655px;
  }
  .mentee-dashboard-home-rightCol {
    width: 325px;
  }
}
@media screen and (max-width: 1024px) {
  .mentee-dashboard-home-upcoming-sessions-empty {
    margin-top: 20px;
    margin-left: 10px;
  }
  .mentee-dashboard-home-leftCol {
    width: 575px;
  }
  .mentee-dashboard-home-rightCol {
    width: 265px;
  }
}
