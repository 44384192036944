/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

/* Menu */
.navBar_container {
  z-index: 1;
  position: absolute;
  width: 100vw;
  height: 100px;
  background-color: transparent;
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}
.navBar_container.active {
  position: fixed;
  background-color: white;
  z-index: 99999;
  -webkit-box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
  top: 0;
}

.navBar_container.active div div .navBar_ul_wrapper ul div li a {
  color: black !important;
}
.navBar_container.active div div .navBar_ul_wrapper ul div li a::before {
  background: black !important;
}
.navBar_container div {
  height: 100%;
}
.navBar_logo {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}
.brand-link {
  width: 183px;
  height: 41px;
  padding-left: 10px;
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}
.brand-link img {
  width: 100%;
}

.navBar_ul_wrapper {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.navBar_ul {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
  margin: 0;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.navBar_ul div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.navBar_li {
  font-size: 14px;
  font-weight: 600;
  margin-left: 30px;
}
.navBar_li a {
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
  font-family: "Mulish", sans-serif;
  padding: 4px 0;
  position: relative;
}
.navBar_li a::before {
  content: "";
  width: 100%;
  height: 2px;
  position: absolute;
  left: 0;
  bottom: 0;
  background: black;
  -webkit-transition: 0.3s -webkit-transform ease;
  transition: 0.3s -webkit-transform ease;
  -o-transition: 0.3s transform ease;
  transition: 0.3s transform ease;
  transition: 0.3s transform ease, 0.3s -webkit-transform ease;
  -webkit-transform: scale3d(0, 1, 1);
  transform: scale3d(0, 1, 1);
  -webkit-transform-origin: 100% 50%;
  -ms-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
}
.navBar_li .selected-link::before {
  content: "";
  width: 100%;
  height: 2px;
  position: absolute;
  left: 0;
  bottom: 0;
  background: black;
  -webkit-transition: 0.3s -webkit-transform ease;
  transition: 0.3s -webkit-transform ease;
  -o-transition: 0.3s transform ease;
  transition: 0.3s transform ease;
  transition: 0.3s transform ease, 0.3s -webkit-transform ease;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  -webkit-transform-origin: 0 50%;
  -ms-transform-origin: 0 50%;
  transform-origin: 0 50%;
}
.navBar_li.white a::before {
  content: "";
  width: 100%;
  height: 2px;
  position: absolute;
  left: 0;
  bottom: 0;
  background: white;
  -webkit-transition: 0.3s -webkit-transform ease;
  transition: 0.3s -webkit-transform ease;
  -o-transition: 0.3s transform ease;
  transition: 0.3s transform ease;
  transition: 0.3s transform ease, 0.3s -webkit-transform ease;
  -webkit-transform: scale3d(0, 1, 1);
  transform: scale3d(0, 1, 1);
  -webkit-transform-origin: 100% 50%;
  -ms-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
}
.navBar_li a:hover::before {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  -webkit-transform-origin: 0 50%;
  -ms-transform-origin: 0 50%;
  transform-origin: 0 50%;
}
.navBar_li.white .selected-link::before {
  content: "";
  width: 100%;
  height: 2px;
  position: absolute;
  left: 0;
  bottom: 0;
  background: white;
  -webkit-transition: 0.3s -webkit-transform ease;
  transition: 0.3s -webkit-transform ease;
  -o-transition: 0.3s transform ease;
  transition: 0.3s transform ease;
  transition: 0.3s transform ease, 0.3s -webkit-transform ease;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  -webkit-transform-origin: 0 50%;
  -ms-transform-origin: 0 50%;
  transform-origin: 0 50%;
}
.menu_btn {
  font-size: 14px;
  font-weight: 600;
  /* padding: 11px 27px; */
  width: 96px;
  height: 40px;
}
.login_menu_btn {
  background-color: #2c205b;
  border: 0;
  color: white;
  border-radius: 10px;
  margin-left: 37px;
  cursor: pointer;
  font-family: "Mulish", sans-serif;
  font-weight: 700;
  transition: 0.3s ease-in;
}
.apply_menu_btn {
  background-color: #fac4dc;
  border: 0;
  color: #0d0a1b;
  border-radius: 10px;
  margin-left: 10px;
  font-family: "Mulish", sans-serif;
  font-weight: 700;
  cursor: pointer;
  transition: 0.3s ease-in;
}
.fa-bars {
  display: none;
}

.login_menu_btn:hover,
.apply_menu_btn:hover {
  transform: scale(1.005) translatey(-2px);
  box-shadow: 0 5px 5px 1px rgba(189, 189, 189, 0.25);
}
@media screen and (max-width: 1280px) {
  .nav_container {
    width: 100%;
    max-width: 1155px;
  }
}
@media screen and (max-width: 1024px) {
  .navBar_container {
    height: auto;
    height: 65px;
    display: block;
    position: absolute;
    z-index: 999;
  }
  .navBar_logo {
    width: 46%;
    display: flex;
    padding-top: 0;
    margin: 0px;
    margin-left: -5px;
  }

  .navBar_ul_wrapper {
    width: 42%;
  }

  .brand-link {
    display: flex;
    width: 108px;
    height: 25px;
  }
  .brand-link img {
    width: 100%;
  }
  .navBar_container.active div div .navBar_ul_wrapper .fa {
    color: black !important;
  }
  .fa-bars,
  .fa-times {
    display: inline-block;
    cursor: pointer;
    font-size: x-large;
    -webkit-transition: 0.2s ease-in;
    -o-transition: 0.2s ease-in;
    transition: 0.2s ease-in;
  }
  .navBar_ul {
    position: absolute;
    width: calc(100% - 50px);
    padding: 0 25px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    top: 0;
    padding-top: 65px;
    overflow: hidden;
    background-color: white;
    height: calc(100vh - 65px);
    left: -100%;
    -webkit-transition: 0.2s ease-in;
    -o-transition: 0.2s ease-in;
    transition: 0.2s ease-in;
    z-index: -1;
  }
  .navBar_ul div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .is-expanded {
    height: calc(100vh - 65px);
    left: 0;
    z-index: -1;
  }
  .navBar_li {
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    padding: 35px 0 15px 0;
    margin: 0;
  }
  .navBar_li a {
    color: black !important;
  }
  .menu_btn {
    margin: 0px;
    width: 226px;
    height: 44px;
  }
  .navBar-btns-div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding-bottom: 80px;
  }
  .navBar-btn {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .login_menu_btn {
    position: relative;
    bottom: auto;
    margin-bottom: 10px;
  }
  .apply_menu_btn {
    position: relative;
    bottom: auto;
  }
}

@media only screen and (max-width: 420px) {
  .navBar_container {
    height: auto;
    height: 65px;
    display: block;
    position: absolute;
    z-index: 999;
  }
  .navBar_logo {
    width: 170px;
    display: flex;
    padding-top: 0;
    margin: 0px;
    margin-left: -5px;
  }

  .navBar_ul_wrapper {
    width: 42%;
  }
  .brand-link {
    display: flex;
    width: 108px;
    height: 25px;
    -webkit-transition: 0.2s ease-in;
    -o-transition: 0.2s ease-in;
    transition: 0.2s ease-in;
  }
  .brand-link img {
    width: 100%;
  }

  .navBar_container.active div div .navBar_ul_wrapper .fa {
    color: black !important;
  }
  .fa-bars,
  .fa-times {
    display: inline-block;
    cursor: pointer;
    font-size: x-large;
    -webkit-transition: 0.2s ease-in;
    -o-transition: 0.2s ease-in;
    transition: 0.2s ease-in;
  }
  .navBar_ul {
    position: absolute;
    width: calc(100% - 50px);
    padding: 0 25px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    top: 0;
    padding-top: 65px;
    overflow: hidden;
    background-color: white;
    height: calc(100vh - 65px);
    left: -100%;
    -webkit-transition: 0.2s ease-in;
    -o-transition: 0.2s ease-in;
    transition: 0.2s ease-in;
    z-index: -1;
  }
  .navBar_ul div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .is-expanded {
    height: calc(100vh - 65px);
    left: 0;
    z-index: -1;
  }
  .navBar_li {
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    padding: 25px 0 25px 0;
    margin: 0;
  }

  .navBar_li a {
    color: black !important;
  }

  .navBar_li .selected-link::before {
    height: 1px !important;
    bottom: -21px !important;
  }
  .menu_btn {
    margin: 0px;
    width: 226px;
    height: 44px;
  }
  .navBar-btns-div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding-bottom: 80px;
  }
  .navBar-btn {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .login_menu_btn {
    position: relative;
    bottom: auto;
    margin-bottom: 10px;
  }
  .apply_menu_btn {
    position: relative;
    bottom: auto;
  }
}
