/* aboutus-Header */
.aboutus-header {
  width: 100%;
  background-color: hsl(240, 65%, 60%);
  max-height: 868px;
  height: 100vh;
}
.aboutus-header_container,
.aboutus-header_row {
  height: 100%;
}
.aboutus-header_row {
  display: flex;
}

.aboutus-header_leftCol,
.aboutus-header_rightCol {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.aboutus-header-img-container {
  width: 100%;
}
.aboutus-header_rightCol_text {
  display: flex;
  justify-content: center;
  align-items: center;
}
.aboutus-header_rightCol_text h1 {
  color: white;
}
/* Section 1 'Our mission */
.aboutus-section_one {
  background-color: #fac4dc;
  width: 100%;
  padding-bottom: 20px;
}
.aboutus-section_one_leftCol,
.aboutus-section_one_rightCol {
  padding-top: 60px;
  padding-bottom: 30px;
}
.aboutus-section_one_leftCol {
  width: 32%;
}
.aboutus-section_one_rightCol {
  width: 60%;
}

/* Section 2 'Our values' */
.aboutus-section_two {
  background-color: #f5f7f9;
  width: 100%;
}
.aboutus-section_two_leftCol {
  padding-top: 60px;
}
.aboutus-section_two_leftCol p {
  padding-top: 26px;
}

.aboutus-section_two_rightCol {
  padding-top: 55px;
  padding-bottom: 120px;
}
.aboutus-section_two_rightCol_row {
  display: inline-block;
  padding-top: 55px;
  height: 168px;
}
.aboutus-section_two_rightCol_img,
.aboutus-section_two_rightCol_text {
  float: left;
}
.aboutus-section_two_rightCol_img {
  position: relative;
  background-color: white;
  width: 168px;
  height: 168px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s ease-in;
}
.aboutus-section_two_rightCol_img:hover {
  transform: translateY(-5px);
  box-shadow: 0px 9px 10px -6px rgba(0, 0, 0, 0.2);
}

.aboutus-section_two_rightCol_text {
  height: 100%;
  padding-left: 56px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.aboutus-section_two_rightCol_text h3 {
  margin-bottom: 15px !important;
}
.aboutus-section_two_leftCol {
  width: 32%;
}
.aboutus-section_two_rightCol {
  width: 60%;
}

/* Section 3 'Who we serve' */
.aboutus-section_three {
  position: relative;
  background-color: #5555db;
  padding-bottom: 65px;
  height: 538px;
}
.aboutus-section_three_container {
  height: 100%;
}
.aboutus-section_three_ballon_image {
  position: absolute;
  top: -230px;

  transition: 1.5s cubic-bezier(0.5, 1, 0.5, 1.5);
}
.aboutus-section_three_row {
  height: 100%;
}
.aboutus-section_three_ballon_image:hover {
  top: -260px;
  transform: translateX(-10px) rotate(1deg);
}
.aboutus-section_three_leftCol {
  padding-top: 120px;
}
.aboutus-section_three_leftCol h2,
.aboutus-section_three_leftCol p {
  color: white;
}
.aboutus-section_three_leftCol p {
  padding-top: 40px;
}
.aboutus-section_three_rightCol {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 55px;
  height: 100%;
}
.aboutus-section_three_rightCol img {
  width: 100%;
}
.aboutus-section_three_row_bubbles {
  font-family: "Neometric Regular";
  padding-top: 30px;
  height: 69px;
}
.aboutus-section_three_col_bubbles {
  height: 100%;
  margin: 0 !important;
  margin-right: 2% !important;
}
.aboutus-section_three_bubble {
  width: 100%;
  color: white;
  margin-top: 3px;
  background-color: #7777e2;
  padding: 3px 2px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  height: 100%;
  text-align: center;
}

.aboutus-section_three_bubble_multi {
  width: 100%;
  color: white;
  margin-top: 3px;
  background-color: #7777e2;
  padding: 3px 2px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  height: calc((100% - 18px) / 3);
}

@media screen and (max-width: 1023px) {
  .desk_br {
    display: none;
  }
  .container_narrow {
    width: 90%;
  }
  /* header */
  .aboutus-header_container {
    margin: 0 auto;
    padding-top: 0px;
  }
  .aboutus-header_row {
    display: block;
  }
  .aboutus-header {
    max-height: 100vh;
    height: 90vh;
  }

  .aboutus-header_leftCol {
    height: 65%;
    width: 100%;
  }
  .aboutus-header_rightCol {
    height: 35%;
    width: 100%;
  }
  .aboutus-header_leftCol {
    align-items: flex-end;
  }
  .aboutus-header_rightCol {
    justify-content: flex-start;
    align-items: flex-start;
  }

  .aboutus-header_leftCol img {
    width: 100%;
  }

  .aboutus-header_rightCol_text {
    text-align: left;
    margin-top: 20px;
  }
  .aboutus-header_rightCol_text h1 {
    color: white;
  }
  /* Section 1 'Our mission */
  .aboutus-section_one {
    padding: 30px 0 0 0;
    background-color: #fac4dc;
    width: 100%;
  }
  .aboutus-section_one_leftCol {
    padding-top: 30px;
    padding-bottom: 20px;
  }
  .aboutus-section_one_leftCol h2,
  .aboutus-section_one_rightCol p {
    text-align: left;
  }
  .aboutus-section_one_rightCol {
    padding-top: 0px;
    padding-bottom: 40px;
  }
  /* Section 2 'Our values' */
  .aboutus-section_two {
    padding-bottom: 100px;
  }
  .aboutus-section_two_leftCol {
    padding-top: 45px;
  }
  .aboutus-section_two_leftCol h2 {
    text-align: left;
  }
  .aboutus-section_two_leftCol p {
    text-align: left;
    padding-top: 26px;
  }

  .aboutus-section_two_rightCol {
    padding-top: 0;
    padding-bottom: 0;
  }
  .aboutus-section_two_rightCol_row {
    display: inline-block;
    padding-top: 0;
    height: auto;
  }

  .aboutus-section_two_rightCol_img,
  .aboutus-section_two_rightCol_text {
    float: none;
  }
  .aboutus-section_two_rightCol_text h3 {
    margin-bottom: 15px !important;
  }
  .aboutus-section_two_rightCol_img {
    width: 90px;
    height: 90px;
    float: left;
    margin: 30px auto 0 auto;
  }
  .aboutus-section_two_rightCol_img img {
    width: 40%;
  }
  .aboutus-section_two_rightCol_img:hover {
    transform: translateY(-5px);
    box-shadow: 0px 9px 10px -6px rgba(0, 0, 0, 0.2);
  }

  .aboutus-section_two_rightCol_text {
    padding-left: 30px;
    text-align: left;
  }
  .aboutus-section_two_rightCol_text h3 {
    padding-top: 20px;
    padding-bottom: 10px;
  }

  /* third section */
  .aboutus-section_three {
    padding-bottom: 315px;
  }
  .aboutus-section_three_row {
    display: flex;
    flex-direction: column-reverse;
  }
  .aboutus-section_three_ballon_image {
    width: 80px;
    top: -80px;
  }
  .aboutus-section_three_rightCol {
    width: 80%;
    margin: 0 auto !important;
  }
  .aboutus-section_three_rightCol img {
    width: 100%;
  }
  .aboutus-section_three_leftCol {
    padding-top: 30px;
    width: 98%;
  }
  .aboutus-section_three_leftCol h2,
  .aboutus-section_three_leftCol p {
    text-align: center;
  }
  .aboutus-section_three_leftCol p {
    padding-top: 20px;
  }
  .aboutus-section_three_row_bubbles {
    height: auto;
  }
  .aboutus-section_three_col_bubbles {
    width: 44% !important;
    margin: 1.5% 3% !important;
  }
  .aboutus-section_three_bubble {
    height: 69px;
  }
  .aboutus-section_three_bubble_multi {
    height: calc((69px - 18px) / 3);
  }
}

@media screen and (max-width: 767px) {
  .desk_br {
    display: none;
  }
  /* header */
  .aboutus-header_container {
    margin: 0 auto;
    padding-top: 0px;
  }
  .aboutus-header_row {
    display: block;
  }
  .aboutus-header {
    max-height: 100vh;
    height: 90vh;
  }

  .aboutus-header_leftCol {
    height: fit-content;
    width: 100%;
  }
  .aboutus-header_rightCol {
    height: 35%;
    width: 100%;
  }
  .aboutus-header_leftCol {
    align-items: flex-end;
    margin: 100px 0 0 0 !important;
  }
  .aboutus-header_rightCol {
    justify-content: flex-start;
    align-items: flex-start;
  }

  .aboutus-header_leftCol img {
    width: 100%;
  }

  .aboutus-header_rightCol_text {
    text-align: left;
    margin-top: 20px;
  }
  .aboutus-header_rightCol_text h1 {
    color: white;
  }
  /* Section 1 'Our mission */
  .aboutus-section_one {
    background-color: #fac4dc;
    width: 100%;
  }
  .aboutus-section_one_leftCol {
    padding-top: 30px;
    padding-bottom: 20px;
    width: 100%;
  }
  .aboutus-section_one_leftCol h2,
  .aboutus-section_one_rightCol p {
    text-align: center;
  }
  .aboutus-section_one_rightCol {
    padding-top: 0px;
    padding-bottom: 40px;
    width: 98%;
  }
  .aboutus-section_one_rightCol p {
    font-size: 18px;
    line-height: 20px;
  }
  /* Section 2 'Our values' */
  .aboutus-section_two {
    padding-bottom: 190px;
  }
  .aboutus-section_two_leftCol {
    padding-top: 45px;
    width: 98%;
  }
  .aboutus-section_two_leftCol h2 {
    text-align: center;
  }
  .aboutus-section_two_leftCol p {
    text-align: center;
    padding-top: 26px;
  }

  .aboutus-section_two_rightCol {
    padding-top: 0;
    padding-bottom: 0;
    width: 100%;
  }
  .aboutus-section_two_rightCol_row {
    display: inline-block;
    padding-top: 0;
    height: auto;
  }

  .aboutus-section_two_rightCol_img,
  .aboutus-section_two_rightCol_text {
    float: none;
  }
  .aboutus-section_two_rightCol_img {
    width: 130px;
    height: 130px;
    margin: 30px auto 0 auto;
  }
  .aboutus-section_two_rightCol_img img {
    width: 40%;
  }
  .aboutus-section_two_rightCol_img:hover {
    transform: translateY(-5px);
    box-shadow: 0px 9px 10px -6px rgba(0, 0, 0, 0.2);
  }

  .aboutus-section_two_rightCol_text {
    padding-left: 0;
    text-align: center;
  }
  .aboutus-section_two_rightCol_text h3 {
    padding-top: 20px;
    padding-bottom: 10px;
    font-size: 18px;
  }

  /* third section */
  .aboutus-section_three_row {
    display: flex;
    flex-direction: column-reverse;
  }
  .aboutus-section_three_ballon_image {
    width: 80px;
    top: -80px;
  }

  .aboutus-section_three_rightCol img {
    width: 100%;
  }
  .aboutus-section_three_leftCol {
    padding-top: 30px;
  }
  .aboutus-section_three_leftCol h2,
  .aboutus-section_three_leftCol p {
    text-align: center;
  }
  .aboutus-section_three_leftCol p {
    padding-top: 20px;
    line-height: 19.5px;
  }
  .aboutus-section_three_row_bubbles {
    height: auto;
  }
  .aboutus-section_three_col_bubbles {
    width: 44% !important;
    margin: 1.5% 3% !important;
  }
  .aboutus-section_three_bubble {
    height: 69px;
    line-height: 19.5px;
  }
  .aboutus-section_three_bubble_multi {
    height: calc((69px - 18px) / 3);
  }
}
@media screen and (max-width: 420px) {
  .aboutus-header {
    min-height: 650px;
  }
  .aboutus-header-img-container {
    width: 90%;
    margin: 0 auto;
  }
  .aboutus-header_leftCol {
    margin: 100px 0 0 0 !important;
  }
  .aboutus-header_rightCol_text h1 {
    margin-left: 20px;
    line-height: 117.02%;

    color: #ffffff;
  }
  .aboutus-section_two_rightCol {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
  }
}
