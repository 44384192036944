/* Footer */
.desktop_wave,
.desktop_footer_container {
  display: block;
}
.mobile_wave,
.mobile_footer_container {
  display: none;
}

.footer_wave_shape {
  margin-top: -1px;
  background-color: transparent;
}
.footer_section {
  margin-top: 0px;
}
.footer {
  margin-top: -1px;
  padding-top: 30px;
  font-family: "Mulish";
}
.footer_first_row {
  height: 50px;
}
.footer_first_col_leftCol {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.footer_first_col_middleCol {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer_first_col_rightCol {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.footer_first_col_rightCol i {
  color: white;
  font-size: 25px;
  margin-left: 42px;
  transition: 0.3s ease-in;
}
.footer_first_col_rightCol i:hover {
  color: #2c205b;
}
.footer_second_row {
  padding-top: 80px;
  padding-bottom: 30px;
  height: 116px;
}
.footer_second_col_leftCol {
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.footer_second_col_middleCol {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.footer_second_col_rightCol {
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.footer_second_col_leftCol a,
.footer_second_col_middleCol a,
.footer_second_col_rightCol a {
  color: white;
  text-decoration: none;
  font-size: 14px;
}
.footer_second_col_leftCol a {
  margin-right: 40px;
  transition: 0.3s ease-in;
}
.footer_second_col_middleCol a {
  margin-top: 13px;
  transition: 0.3s ease-in;
}
.footer_second_col_rightCol a {
  margin-left: 40px;
  transition: 0.3s ease-in;
}

.footer_second_col_middleCol a:hover,
.footer_second_col_leftCol a:hover,
.footer_second_col_rightCol a:hover {
  color: #2c205b;
}

.copywrite {
  height: 45px;
}
.copywrite_text {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
}

@media screen and (max-width: 1023px) {
  .desktop_wave,
  .desktop_footer_container {
    display: none;
  }
  .mobile_wave,
  .mobile_footer_container {
    display: block;
  }
  .footer_section {
    margin-top: -1px;
  }
  .footer {
    margin-top: -1px;
    padding-top: 0px;
  }
  .footer_mobile_first_col_logo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 0;
  }
  .footer_first_col_rightCol {
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 25px;
  }
  .footer_first_col_rightCol i {
    margin: 0 25px;
  }
  .footer_first_col_rightCol i:hover {
    color: white;
  }
  .footer_second_col_top {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .footer_second_col_top a {
    color: white;
    text-decoration: none;
    font-size: 14px;
    margin: 0;
    text-align: center;
    width: 50%;
  }
  .footer_second_col_rightCol {
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 40px;
  }
  .footer_second_col_rightCol a {
    margin: 0;
    width: 50%;
    text-align: center;
  }
  .footer_second_col_middleCol a:hover,
  .footer_second_col_leftCol a:hover,
  .footer_second_col_rightCol a:hover {
    color: white;
  }
  /* .social-btn {
    cursor: pointer;
  } */
}
