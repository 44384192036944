.admin_dashboard_container {
  width: 1044px;
  margin-left: 300px !important;
}
.admin_dashboard_btn {
  font-family: "Neometric Bold";
  font-size: 9px;
  border-radius: 5px;
}
.mentee_mentor_btn_container {
  display: flex;
  background: #d4d4ee;
  width: fit-content;
  height: 32px;
  border-radius: 10px;
}
.mentee_mentor_swith_btn {
  width: 80px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  font-family: "Neometric Medium";
  font-size: 12px;
  color: #fff;
  opacity: 0.5;
  cursor: pointer;
}
.mentee_mentor_swith_btn_active {
  background: #5555db;
  opacity: 1;
  cursor: default;
}
