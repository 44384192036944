.mentor_img_back {
  position: relative;
}
.mentor_img_back::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(360deg, #9f9f9f, #000303, #9f9f9f);
  background-size: 200% 200%;

  -webkit-animation: AnimationName 3s ease infinite;
  -moz-animation: AnimationName 3s ease infinite;
  animation: AnimationName 3s ease infinite;
}
@-webkit-keyframes AnimationName {
  0% {
    background-position: 50% 0%;
  }
  50% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 50% 0%;
  }
}
@-moz-keyframes AnimationName {
  0% {
    background-position: 50% 0%;
  }
  50% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 50% 0%;
  }
}
@keyframes AnimationName {
  0% {
    background-position: 50% 0%;
  }
  50% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 50% 0%;
  }
}

.mentor_card_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 100px;
}
.mentor_card {
  position: relative;
  margin: 0px 20px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 502.7px;
  width: 332.6px;
  border-radius: 16.798px;
}
.mentor_card_data_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 40px 0;
  text-align: center;
  height: 95%;
}
.mentor_card_name {
  color: white;
  font-family: Neometric Bold;
  font-weight: 600;
  font-size: 19px;
  line-height: 23px;
  text-align: center;
  margin: 3px 0px;
}
.mentor_card_area {
  color: white;
  font-family: eina;
  font-size: 14px;
  line-height: 16px;
  margin: 3px 0px;
}
.mentor_card_skills_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 4px 0px;
}
@media screen and (max-width: 768px) {
  .mentor-mobile-container {
    width: 92% !important;
  }
  .mentor-mobile-container div:first-child {
    padding-bottom: 20px;
    height: 650px !important;
  }
}
@media screen and (max-width: 420px) {
  .mentor-mobile-container {
    height: 470px !important;
    width: 95% !important;
  }
  .mentor-mobile-container div:first-child {
    padding-bottom: 20px;
    height: 380px !important;
  }
}
