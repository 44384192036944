.mentee-dashboad-find-mentor-banner {
  /* width: 1080px; */
  height: 124px;
  background: #5555db
    url("../../../assets/dashboard/mentee-dashboard/mentee-dashboard-find-mentor/Group\ 781.png");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  margin: 25px;
  position: relative;
  display: flex;
  align-items: center;
}
.mentee-dashboad-find-mentor-banner-img {
  position: absolute;
  bottom: 10px;
  left: 30px;
}
.mentee-dashboad-find-mentor-banner-title {
  color: white;
  font-family: "Neometric Regular";
  margin-left: 250px;
}
.tooltip {
  font-family: "Montserrat";
  margin-left: 10px;
  position: relative;
  font-size: 12px;
  width: 18px;
  height: 18px;
  background: #5555db;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  color: white;
  cursor: pointer;
  z-index: 11;
}

.tooltip .tooltiptext {
  font-family: "eina";
  font-size: 12px;
  line-height: 14px;

  color: #2c205b;

  visibility: hidden;
  width: 286px;
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 15px;
  border-radius: 6px;

  display: flex;
  align-items: center;
  justify-content: center;
  /* Position the tooltip */
  position: absolute;
  bottom: 0;
  left: 25px;

  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
.mentee-dashboad-find-mentor-banner-title > div {
  line-height: 30px;
}
.mentee-dashboard-find-mentor-filter-container {
  display: flex;
}
.mentee-dashboard-find-mentor-filter-container > div {
  position: relative;
}
.mentee-dashboard-find-mentor-filter {
  font-family: "Neometric Medium";
  font-size: 14px;
  padding: 0 20px;
  height: 43px;
  background: white;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.mentee-dashboard-find-mentor-filter-availability-dropdown-body {
  position: absolute;
  right: 0;
  background: #ffffff;
  height: 90px;
  width: 326px;
  height: 149px;
  margin-top: 8px;
  display: flex;
  padding-top: 8px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  display: none;
}

.mentee-dashboard-find-mentor-filter-availability-dropdown-body.open {
  display: block;
  z-index: 111;
}
.mentee-dashboard-find-mentor-filter-session-type-dropdown-body {
  position: absolute;
  right: 0;
  background: #ffffff;
  width: 198px;
  height: 148px;
  margin-top: 8px;
  justify-content: center;

  padding-top: 8px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  display: none;
}

.mentee-dashboard-find-mentor-filter-session-type-dropdown-body.open {
  display: flex;
  z-index: 111;
}
.filterInput:focus {
  border: 0;
  outline: none;
}
.filterSkill-container {
  display: flex;

  flex-wrap: wrap;
  padding-top: 10px;
}

.filterSkill {
  cursor: pointer;
  font-family: "Neometric Regular";
  font-size: 12px;
  padding: 8px;
  margin: 3px 5px;
  color: #c4c4c4;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 20px;
}
.filterSkill-selected {
}
.mentee-dashboard-find-mentor-cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-left: 15px;
}
.mentee-dashboard-find-mentor-cards-container > div {
  margin: 20px 11px;
}
.mentee-dashboard-find-mentor-card-item {
  width: 254px;
  height: 330px;
  background: #ffffff;
  border-radius: 10.1794px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.mentee-dashboard-find-mentor-card-item-img-container {
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 100%;
  width: 60px;
  height: 60px;
  margin: 20px 0px 15px 0px;
}
.mentee-dashboard-find-mentor-card-item-name {
  font-family: "Neometric Medium";
  font-size: 18px;
  line-height: 22px;
}
.mentee-dashboard-find-mentor-card-item-company {
  font-family: "eina";
  font-size: 12px;
  line-height: 20px;
}
.mentee-dashboard-find-mentor-card-item-skills-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 4px 0px;
}
.mentee-dashboard-find-mentor-card-item-skill {
  font-family: "eina";
  color: #5555db;
  background-color: #eeeefb;
  border-radius: 11.7857px;
  font-size: 12px;
  padding: 4px 15px;
  margin: 5px 2px;
}
.mentee-dashboard-find-mentor-card-item-schedule-btn {
  margin: 0 0 12px 0;
  font-family: "Neometric Regular";
  color: #5555db;
  border: 1px solid #5555db;
  width: 102.81px;
  height: 32.23px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.mentee-dashboard-find-mentor-card-item-schedule-btn:hover {
  background: #5555db;
  color: #ffffff;
}
