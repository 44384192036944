.profile-header {
  height: 150px;
  background: #4d4dc6;
}
.profile-logo {
  position: absolute;
  left: 60px;
  top: 50px;
}
.profile-body {
  width: 700px;
  margin: 0 auto;
  position: relative;
}
.profile-back-to-dashboard-btn {
  display: inline-block;
  position: relative;
  top: 20px;
  right: 160px;
}
.profile-body-container {
  position: relative;
  top: -140px;
  margin: 0px 20px;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.profile-body-user-header {
  display: flex;
  align-items: flex-end;
}
.profile-body-user-avatar-img {
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  border-radius: 10px;
  width: 135px;
  height: 135px;
  cursor: pointer;
}

.hover-overlay .mask {
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.mask {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 135px;
  height: 135px;
  overflow: hidden;
  background-attachment: fixed;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.51)
    url("../../../assets/dashboard/profile/changePicture.svg") no-repeat center;
}
.hover-overlay .mask:hover {
  opacity: 1;
}
.profile-body-user-header-name-type {
  margin: 10px;
}
.profile-body-user-header-name {
  line-height: 36px;
}
.profile-body-user-header-type {
  font-family: "Neometric Regular";
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #b5b5b5;
  text-transform: capitalize;
}

.profile-body-user-profile-completion {
  margin-top: 35px;
  font-family: "Neometric Regular";
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: #5555db;
}
progress {
  margin-top: 10px;
  border-radius: 7px;
  height: 12px;
  width: 90%;
}
progress::-webkit-progress-bar {
  background-color: #eeeefb;
  border-radius: 7px;
}
progress::-webkit-progress-value {
  background-color: #5555db;
  border-radius: 7px;
}
.user-profile-edit-profile {
  margin-top: 40px;
}
.user-profile-edit-profile-tabs {
  display: flex;
}
.user-profile-edit-profile-tab {
  font-family: "Neometric Regular";
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* margin-right: 20px; */
  color: #ababab;
  border-bottom: 2px solid #ababab;

  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;

  padding: 4px 40px 4px 40px;
  position: relative;
  cursor: pointer;
}

.user-profile-edit-profile-tab::before {
  content: "";
  width: 100%;
  height: 2px;
  position: absolute;

  left: 0;
  bottom: -2px;
  -webkit-transition: 0.3s -webkit-transform ease;
  transition: 0.3s -webkit-transform ease;
  -o-transition: 0.3s transform ease;
  transition: 0.3s transform ease;
  transition: 0.3s transform ease, 0.3s -webkit-transform ease;
  -webkit-transform: scale3d(0, 1, 1);
  transform: scale3d(0, 1, 1);
  -webkit-transform-origin: 100% 50%;
  -ms-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
}
.user-profile-edit-profile-tab:last-child:before {
  width: 100%;
}
.user-profile-edit-profile-tab:hover:before {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  -webkit-transform-origin: 0 50%;
  -ms-transform-origin: 0 50%;
  transform-origin: 0 50%;
  background: #5555db;
}
.user-profile-edit-profile-tab:hover {
  /* color: #5555db; */
}
.user-profile-edit-profile-tab-active {
  color: #5555db;
}
.user-profile-edit-profile-tab-active::before {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  -webkit-transform-origin: 0 50%;
  -ms-transform-origin: 0 50%;
  transform-origin: 0 50%;
  background: #5555db;
}
.user-profile-edit-profile-content {
  margin-top: 70px;
}
.delete-account-text {
  font-size: 14px;
  line-height: 17px;

  color: #2c205b;
}
.delete-account-btn {
  height: 62px;
  width: 256px;
  background: #eeeefb;
  color: #5555db;
}
.calender-column-text {
  font-family: "eina";
  font-size: 12px;
  line-height: 14px;

  color: #2c205b;
}
.delete-account-confirmation-popup {
  position: fixed; /* Stay in place */
  z-index: 555555;
  left: 0;
  top: 0px;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}
.delete-account-confirmation-popup-content {
  background-color: #fefefe;
  border-radius: 10px;
  margin: auto;
  border: 1px solid #888;
  width: 564px;
  height: 236px;
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.delete-account-confirmation-popup-content > div {
  margin: 5px 0px;
}
.delete-account-confirmation-popup-content > div:first-child {
  margin-top: 40px;
}

/* The Close Button */
.profile-information-resume-uploaded-true {
  font-family: "eina";
}

.cursor-disable {
  cursor: not-allowed !important;
}
