.sign-up-status-approved {
  background: #5555db;
  height: 100vh;
  display: flex;
}
.sign-up-status-denied {
  background: #fac4dc;
  height: 100vh;
  display: flex;
}
.sign-up-status-pending {
  background: #2c205b;
  height: 100vh;
  display: flex;
}
.sign-up-status-row {
  display: flex;
}
.sign-up-status-leftCol {
  display: flex;
  justify-content: center;
  align-items: center;
}
.sign-up-status-rightCol {
  display: flex;
  justify-content: center;
  align-items: center;
}
.sign-up-status-desc-container {
  width: 446px;
  height: 501px;
  background: #ffffff;
  border-radius: 20px;
}
.sign-up-status-approved-rightCol-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.start-now-btn {
  background: #fac4dc;
  margin-left: 0px;
  margin-right: 0px;
}
.sign-up-status-desc {
  margin: 0px 45px;
  padding: 55px 0px;
}
.sign-up-status-rightCol .sign-up-status-rightCol-title {
  color: #2c205b;
}
.sign-up-status-rightCol-desc {
  color: #2c205b;
}
