.mentor-dashboard-home-rightCol {
  display: flex;
  flex-direction: column;
}

.mentor-dashboard-home-rightCol-row-1 {
  width: 360px;
  height: fit-content;
  /* min-height: 665px; */
  min-height: 355px;

  /* overflow-y: auto; */
  background: #ffffff;
  border-radius: 10px;
  margin-bottom: 18px;
}

.mentor-dashboard-home-rightCol-row-1-container {
  margin: 35px 30px;
}
.mentor-dashboard-home-rightCol {
  z-index: 1;
}

.mentor-dashboard-home-rightCol-row2 {
  margin-top: 18px;
  width: 360px;
  height: 195px;
  background: #ffffff;
  border-radius: 10px;
}
.mentor-dashboard-home-rightCol-row2-container {
  margin: 35px 30px;
  display: flex;
}
.mentor-dashboard-home-rightCol-row2-container > div {
  margin: 0 10px;
}
