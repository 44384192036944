.become-mentor-header {
  width: 100%;
  height: 100vh;
  max-height: 868px;
  background-image: url("../assets/Slice 4.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.become-mentor-header_container {
  height: 100%;
  padding-top: 200px;
  text-align: center;
}

.become-mentor-btn {
  font-size: 13px;
}

.become-mentor-header_container .become-mentor-text {
  font-family: "Neometric Regular";

  font-size: 16px;
  line-height: 105%;
  /* or 17px */
  text-align: center;

  color: #ffffff;
  margin-bottom: 20px;
}
.become-mentor-header_container .pay-it-forward-text {
  font-weight: 600;
  line-height: 105%;
  /* or 49px */
  text-align: center;

  color: #ffffff;
  margin-bottom: 30px;
}
.become-mentor-header_container .help-underserved-text {
  line-height: 21px;
  color: #ffffff;
  margin: 0px auto;
  width: 42%;
  margin-bottom: 40px;
}
.become-mentor-header_container .apply-now-container {
  margin-bottom: 100px;
}
.become-mentor-header_container .apply_now_btn {
  width: 154.89px;
  height: 45px;
  background-color: #fac4dc;
  border: 0;
  display: flex;
  align-items: center;
  margin: 0 auto;
  color: #0d0a1b;
  border-radius: 10px;
  cursor: pointer;
  font-family: "Mulish";
  font-weight: 700;
  justify-content: center;
}
.become-mentor-header_container .brands-container {
  margin: 0 auto;
  width: 614px;
}
.become-mentor-header_container .brands {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.become-mentor-section-one {
  height: 600px;
  background: #f5f7f9;
}
.become-mentor-section_one_container {
  padding: 40px;
}
.become-mentor-section_one_container .become-mentor-section-one-heading {
  font-weight: 500;
  line-height: 43px;
  text-align: center;

  color: #0d0a1b;
}
.become-mentor-section-body .card-container {
  margin-top: 50px;
  display: flex;
  justify-content: center;
}
.become-mentor-section-body .card-container .card {
  width: 250px;
  height: 250px;
  padding: 43px 30px;
  background: #ffffff;
  margin: 20px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
}
.become-mentor-section-body .card-container .card .card-icon {
  background-color: #fac4dc;
  border-radius: 10px;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}
.first_card_heading {
  margin-bottom: 22px !important;
}
.become-mentor-section-body .card-container .card .card-heading {
  margin-top: 0px;
  font-family: "Neometric Medium";

  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
}
.become-mentor-section-body .card-container .card .card-desc {
  margin-top: 30px;

  font-size: 14px;
  line-height: 16px;
}
.become-mentor-section-two {
  height: 474px;

  background-color: #eef1f4;
}
.become-mentor-section_two_container {
  padding: 50px 0px;
}
.become-mentor-section_two_container .heading {
  text-align: center;
  font-weight: 500;
  line-height: 43px;
  text-align: center;
}
.become-mentor-section-two-body {
  margin-top: 60px;
}
.become-mentor-section-two-body .steps-container {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.become-mentor-section-two-body .steps-container .steps {
  margin: 20px 0px;
  flex: 0 0 30%;
}

.become-mentor-section-two-body .steps-container .step {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.become-mentor-section-two-body .steps-container .step .step-icon {
  width: 55px;
  height: 55px;
  background: #ffffff;
  border-radius: 12.5px;
  margin-right: 20px;
  margin-top: 10px;
  position: relative;
}
.become-mentor-section-two-body .steps-container .step .step-icon img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.become-mentor-section-two-body .steps-container .step-content {
  width: 70%;
  display: inline;
}
.become-mentor-section-two-body .steps-container .step .step-no {
  font-family: "Neometric Medium";
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 10px;
}
.become-mentor-section-two-body .steps-container .step .step-desc {
  font-family: "Neometric Regular";
  font-size: 14px;
  line-height: 17px;
}
.become-mentor-section-three {
  height: 581px;
  background: #2c205b;
}
.become-mentor-section_three_container {
  padding: 80px 0px;
}

.become-mentor-section-three-leftCol .strike-heading {
  font-family: "Calibri";
  font-weight: 300;
  font-size: 11px;
  line-height: 117.02%;
  /* or 13px */
  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #ffffff;
}

.become-mentor-section-three-leftCol .strike-heading:before {
  content: "\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0";
  text-decoration: line-through;
  margin-right: 1rem;
}

.become-mentor-section-three-leftCol .heading {
  font-family: "Neometric Medium";

  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;

  color: #ffffff;
}

.become-mentor-section-three-leftCol .desc {
  margin-top: 30px;
  font-size: 16px;
  line-height: 19px;

  color: #ffffff;
}

.examples-container {
  margin-top: 40px;
  display: flex;
}

.example-item {
  text-align: center;
  width: 15%;
  padding: 10px 15px;
  margin: 0px 8px;
  background-color: #564d7c;
  border-radius: 10px;
  font-size: 14px;
  line-height: 138.52%;
  /* or 19px */
  text-align: center;

  color: #ffffff;
}
.example-pill-container {
  margin-left: 0px !important ;
  padding: 0px 0px !important;
  background-color: transparent !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.example-pill-container span {
  padding: 2px;
  background-color: #564d7c;
  border-radius: 10px;
  font-size: 14px;
  line-height: 138.52%;
  /* or 19px */
  text-align: center;

  color: #ffffff;
}
/* Mentor Section Four */

.become-mentor-section-four {
  height: 100%;
  padding-bottom: 50px;
  background: white;
}

.become-mentor-section_four_container {
  margin: 100px 0px;
}
.become-mentor-section_four_container .heading {
  font-weight: 500;
  line-height: 43px;
  text-align: center;
  margin-bottom: 10px;
}
.become-mentor-section_four_container .desc {
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  margin-bottom: 50px;
  color: #0d0a1b;
}
.become-mentor-section-four-row-container {
  margin-top: 100px;
  max-width: 1440px;
  margin: 0 auto;
}
.become-mentor-section-four-row-blue {
  display: flex;
}

.become-mentor-section-four-row-blue .become-mentor-section-four-box {
  background-size: cover;
  height: 538px;
  min-width: 720px;
  position: relative;
}
.become-mentor-section-four-row-blue .become-mentor-section-four-box img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 85%;
}
.become-mentor-section-four-row-blue-col-two {
  padding: 100px 0 100px 100px;
}
.become-mentor-section-four-row-blue-col-two .col-strike-text {
  font-family: "eina";

  font-size: 11px;
  line-height: 117.02%;
  /* identical to box height, or 13px */
  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #4d4dc6;
}
.become-mentor-section-four-row-blue-col-two .col-strike-text:before {
  content: "\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0";
  text-decoration: line-through;
  margin-right: 1rem;
}
.become-mentor-section-four-row-blue-col-two .col-heading {
  margin-top: 25px;
  font-weight: 500;
  line-height: 43px;
}

.become-mentor-section-four-row-blue-col-two .col-desc {
  margin-top: 25px;
  line-height: 19px;
}

/* Row Two */
.become-mentor-section-four-row-pink {
  display: flex;
}

.become-mentor-section-four-row-pink .become-mentor-section-four-box {
  background-size: cover;
  height: 538px;
  min-width: 720px;
  position: relative;
}
.become-mentor-section-four-row-pink .become-mentor-section-four-box img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 85%;
}
.become-mentor-section-four-row-pink-col-one {
  padding: 100px 75px 0px 150px;
}
.become-mentor-section-four-row-pink-col-one .col-strike-text {
  font-family: "eina";

  font-size: 11px;
  line-height: 117.02%;
  /* identical to box height, or 13px */
  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #4d4dc6;
}
.become-mentor-section-four-row-pink-col-one .col-strike-text:before {
  content: "\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0";
  text-decoration: line-through;
  margin-right: 1rem;
}
.become-mentor-section-four-row-pink-col-one .col-heading {
  margin-top: 25px;
  font-weight: 500;
  line-height: 43px;
}

.become-mentor-section-four-row-pink-col-one .col-desc {
  margin-top: 25px;
  line-height: 19px;
}
.become-mentor-section-four-row-pink-col-one .col-pill-container {
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
}
.become-mentor-section-four-row-pink-col-one .col-pill-container span {
  flex: 0 0 48%;
  background: rgba(0, 0, 0, 0.04);
  border-radius: 11.7022px;
  text-align: center;
  font-size: 14.0426px;
  line-height: 16px;
  margin: 2px;
  padding: 2px 0px;
  font-family: "eina";
}
/* Become Mentor Section 5 */

.become-mentor-section-five {
  background: white;
  height: 100%;
  padding-bottom: 50px;
}
.section-five-heading {
  font-weight: 500;
  line-height: 106.5%;
}
.become-mentor-section-five-row {
  margin: 0 auto;
  max-width: 1440px;
}
.become-mentor-section-five-leftCol {
  padding-top: 30px;
  text-align: center;
}
.become-mentor-section-five-rightCol .accordion {
  width: 100%;
  background-color: transparent;
  margin: auto;
  margin-top: 50px;
}

.become-mentor-section-five-rightCol .title {
  margin-top: 2px;
  height: auto;
  width: 100%;
  font-weight: 500;
  font-size: 24px;
  line-height: 106.5%;
  /* or 26px */

  color: #000000;
  text-align: left;
  position: relative;
  padding: 20px 10px 5px 10px;
  z-index: 2000;
  border-radius: 4px;

  transition: all 0.2s ease-in;
}

.become-mentor-section-five-rightCol .title-text {
  margin-left: 20px;
}

.become-mentor-section-five-rightCol .title:hover {
  cursor: pointer;
}

.become-mentor-section-five-rightCol .content {
  height: 50px;
  width: 100%;
  background-color: transparent;
  border-radius: 4px;
  font-size: 14px;
  font-size: 14px;
  line-height: 106.5%;
  text-align: center;
  position: relative;
  z-index: 1000;
  margin-top: -30px;
  padding-left: 15px;
  text-align: left;
  transition: all 200ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.become-mentor-section-five-rightCol .content-open {
  margin-top: 15px;
  height: 100%;
  transition: all 350ms cubic-bezier(0.08, 1.09, 0.32, 1.275);
}

.become-mentor-section-five-rightCol .content-text {
  width: 90%;
  padding: 0 0 5px 15px;
  visibility: hidden;
  opacity: 0;
  overflow: auto;
  transition: all 0.2s ease-in;
}

.become-mentor-section-five-rightCol .content-text-open {
  visibility: visible;
  opacity: 1;
  transition: all 0.8s ease-in;
}

.become-mentor-section-five-rightCol .fa-angle-right {
  color: rgba(0, 0, 0, 1);
  transition: all 0.6s cubic-bezier(0.08, 1.09, 0.32, 1.275);
}

.become-mentor-section-five-rightCol .fa-rotate-90 {
  color: rgba(0, 0, 0, 1);
}

.become-mentor-section-five-rightCol .arrow-wrapper {
  position: absolute;
  margin-left: -10px;
}

.become-mentor-section-five .apply-now-container {
  margin-top: 50px;
  margin-bottom: 50px;
}
.become-mentor-section-five .apply_now_btn {
  width: 154.89px;
  height: 45px;
  background-color: #fac4dc;
  border: 0;
  margin: 0;
  color: #0d0a1b;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 700;
}
.become-mentor-section-body-mobile {
  display: none;
}
@media screen and (max-width: 1439px) {
}
@media screen and (max-width: 1439px) {
  .become-mentor-section-four-row-blue .become-mentor-section-four-box,
  .become-mentor-section-four-row-pink .become-mentor-section-four-box {
    min-width: 50%;
  }
  .become-mentor-section-four-row-blue-col-two,
  .become-mentor-section-four-row-pink-col-one {
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .become-mentor-section-four-row-blue .become-mentor-section-four-box img,
  .become-mentor-section-four-row-pink .become-mentor-section-four-box img {
    width: 90%;
  }
}
@media screen and (max-width: 1280px) {
  .become-mentor-section-three-leftCol {
    width: 48% !important;
  }
}
@media screen and (max-width: 1064px) {
  .first_card_heading {
    margin-bottom: 0px !important;
  }
}
@media screen and (max-width: 1024px) {
  .become-mentor-section_two_container {
    width: 100%;
  }
  .become-mentor-section-two {
    height: 100%;
  }
  .become-mentor-section-three {
    height: 100%;
  }
  .become-mentor-section-three-row {
    width: 100%;
    display: flex;
  }
  .become-mentor-section-three-leftCol .br_desk {
    display: none;
  }
  .become-mentor-section-three-row .become-mentor-section-three-rightCol {
    padding-top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .become-mentor-section-three-row .become-mentor-section-three-rightCol img {
    width: 140%;
  }
  .become-mentor-section-three-row .become-mentor-section-three-leftCol {
    width: 45%;
  }
  .become-mentor-section-three-leftCol .examples-container {
    flex-wrap: wrap;
    justify-content: space-between;
    width: 295px;
  }
  .example-item {
    width: 35% !important;
  }
  .example-pill-container {
    width: 42% !important;
  }
}
@media screen and (max-width: 1023px) {
  .become-mentor-header_container {
    padding-top: 220px;
  }
  .brands-container {
    width: 90% !important;
  }
  .brands {
    width: 100% !important;
    flex-wrap: wrap;
  }
  .brands div {
    flex: 0 0 33%;
    margin: 8px 0px;
  }
  .become-mentor-section-one {
    height: 390px;
  }

  .become-mentor-section_two_container {
    width: 90% !important;
  }
  .become-mentor-section-two {
    height: 100%;
  }
  .become-mentor-section-two-body {
    margin-top: 40px;
  }
  .become-mentor-section-two-body .steps-container .steps {
    flex: 0 0 100%;
  }

  .become-mentor-section-four-box {
    height: 538px;
    width: 100%;
  }
  .become-mentor-section-four-row-blue {
    flex-direction: column;
  }
  .become-mentor-section-four-row-pink {
    flex-direction: column-reverse;
  }
  .become-mentor-section-four-row-blue-col-two {
    width: 100%;
    padding: 15% 0px;
  }
  .become-mentor-section-four-row-blue-col-two .col-strike-text {
    font-family: "eina";

    font-size: 9px;
    line-height: 117.02%;
    /* identical to box height, or 11px */
    text-align: center;
    letter-spacing: 0.1em;
  }
  .become-mentor-section-four-row-blue-col-two .col-strike-text:before {
    content: "";
  }
  .become-mentor-section-four-row-blue-col-two .col-heading {
    font-weight: 500;
    line-height: 29px;
    text-align: center;
  }
  .become-mentor-section-four-row-blue-col-two .col-desc {
    line-height: 16px;
    text-align: center;
    width: 80%;
    margin: 0 auto;
    margin-top: 20px;
  }

  .become-mentor-section-four-row-pink-col-one {
    width: 100%;
    padding: 15% 0px;
  }
  .become-mentor-section-four-row-pink-col-one .col-strike-text {
    font-family: "eina";

    font-size: 9px;
    line-height: 117.02%;
    /* identical to box height, or 11px */
    text-align: center;
    letter-spacing: 0.1em;
  }
  .become-mentor-section-four-row-pink-col-one .col-strike-text:before {
    content: "";
  }
  .become-mentor-section-four-row-pink-col-one .col-heading {
    font-weight: 500;
    line-height: 29px;
    text-align: center;
  }
  .become-mentor-section-four-row-pink-col-one .col-desc {
    line-height: 16px;
    text-align: center;
    width: 80%;
    margin: 0 auto;
    margin-top: 20px;
  }
  .become-mentor-section-four-row-pink-col-one .col-pill-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .become-mentor-section-four-row-pink-col-one .col-pill-container span {
    width: 70%;
    margin: 1.5% 0;
  }
}
@media screen and (max-width: 960px) {
  .become-mentor-section-four {
    padding-bottom: 0px;
  }
  .become-mentor-section_four_container {
    margin-bottom: 0px;
  }
  .become-mentor-section-five-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 85%;
  }
  .become-mentor-section-five-leftCol,
  .become-mentor-section-five-rightCol {
    width: 100% !important;
  }
  .become-mentor-section-five-rightCol {
    padding-top: 100px;
  }
  .become-mentor-section-five-leftCol img {
    width: 100%;
  }
  .become-mentor-section-five-rightCol .apply-now-container {
    text-align: center;
  }
  .become-mentor-section-five-rightCol .contact-for-help-text {
    text-align: center;
  }
}
@media screen and (max-width: 959px) {
  .become-mentor-section_one_container {
    width: 95% !important;
    padding: 50px 0px !important;
    margin: 0 auto;
  }
  .become-mentor-section_one_container .card-container .card {
    margin: 0px 8px;
    padding: 20px 17px;
  }
  .become-mentor-section_one_container .card-container .card .card-heading {
    font-size: 14px;
  }
  .become-mentor-section-body .card-container .card .card-desc {
    font-size: 14px;
  }
  .become-mentor-section-one {
    height: 100%;
  }
}
@media screen and (max-width: 768px) {
  .become-mentor-section-three-row {
    display: flex;
    flex-direction: column-reverse;
  }
  .become-mentor-section-three-leftCol,
  .become-mentor-section-three-rightCol {
    width: 98% !important;
  }
  .become-mentor-section-three-rightCol {
    padding-left: 0px;
    padding-bottom: 50px;
    align-items: center;
  }
  .become-mentor-section-three-leftCol {
    text-align: center;
  }
  .become-mentor-section-three-leftCol .strike-heading {
    text-align: center;
  }
  .become-mentor-section-three-leftCol .strike-heading:before {
    content: "";
  }
  .become-mentor-section-three-rightCol img {
    width: 90% !important;
  }
  .become-mentor-section-three-leftCol .examples-container {
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 500px;
    margin: 20px auto 20px auto;
  }
  .example-item {
    width: 35% !important;
    margin: 0px 10px !important;
  }
  .example-pill-container {
    width: 45% !important;
  }
}
@media screen and (max-width: 600px) {
  .become-mentor-section-body .become-mentor-section-body-desktop {
    display: none;
  }

  .become-mentor-section-body-mobile {
    display: block;
    padding-top: 50px;
  }
  .become-mentor-section-body-mobile .card-mobile {
    width: 400px;
    height: 120px;
    padding: 30px 0px 40px 0px;
    background: #ffffff;
    margin: 0;
    margin-right: 20px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 10px;
  }
  .become-mentor-section-body .card-mobile .card-icon-mobile {
    background-color: #fac4dc;
    border-radius: 10px;
    width: 54px;
    height: 54px;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 30px;
  }
  .become-mentor-section-body .card-mobile .card-heading-mobile {
    font-family: "Neometric Medium";
    width: 300px;
    margin-top: 0;
    margin-left: 10px;
    padding-left: 5px;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
  }
  .become-mentor-section-body .card-mobile .card-desc-mobile {
    margin-top: 20px;
    width: 320px;
    font-size: 14px;
    line-height: 16px;
    margin-left: 30px;
  }
}
@media screen and (max-width: 420px) {
  .become-mentor-header {
    height: 720px;
    background-position: left center;
  }
  .become-mentor-header_container {
    text-align: start;
  }

  .become-mentor-section-four-box img {
    width: 326.45px;
    /* height: 166.72px; */
    position: absolute;
    transform: translate(-100%, -50%) !important;
  }
  .become-mentor-header_container .become-mentor-text {
    font-family: "Neometric Regular";
    font-size: 14px;
    line-height: 105%;
    /* or 15px */
    text-align: center;

    color: #ffffff;
  }
  .become-mentor-header_container .pay-it-forward-text {
    margin: 0 auto;
    width: 80%;
    font-weight: 600;
    font-size: 27px;
    line-height: 105%;
    /* or 28px */
    text-align: center;
    margin-bottom: 20px;
    color: #ffffff;
  }
  .become-mentor-header_container .help-underserved-text {
    margin: 0 auto;

    width: 80%;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
  }
  .become-mentor-header_container .apply-now-container {
    margin-bottom: 40px;
  }
  .become-mentor-header_container .apply_now_btn {
    margin-top: 50px;
  }
  .brands-container {
    width: 90% !important;
  }
  .brands {
    margin: 0 auto;
    width: 314px !important;
    flex-wrap: wrap;
  }
  .brands div {
    flex: 0 0 33%;
    margin: 7px 0px;
  }
  .brands div img {
    max-width: 85%;
  }
  .become-mentor-section-one {
    height: 321px;
  }
  .become-mentor-section_one_container {
    padding: 35px 0 0 0;
  }

  .become-mentor-section_one_container .heading {
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
  }
  .become-mentor-section-body-desktop {
    display: none;
  }
  .become-mentor-section-body-mobile {
    display: block;
    margin-top: 30px;
    padding-top: 0px;
  }
  .become-mentor-section-body-mobile .slick-slider {
    margin-left: 35px;
  }

  .become-mentor-section-body-mobile .slick-initialized .slick-slide {
    width: 305px !important;
  }
  /* Home Section 2 mobile css */
  .become-mentor-section-body-mobile .card-mobile {
    width: 281px;
    height: 165px;
    padding: 0px;
    background: #ffffff;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 10px;
  }
  .become-mentor-section-body .card-mobile .card-icon-mobile {
    background-color: #fac4dc;
    border-radius: 10px;
    width: 54px;
    height: 54px;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 15px;
  }
  .become-mentor-section-body .card-mobile .card-heading-mobile {
    font-family: "Neometric Medium";
    width: 175px;
    margin-top: 0;

    padding-left: 5px;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
  }
  .become-mentor-section-body .card-mobile .card-desc-mobile {
    margin-top: 20px;
    width: 239px;
    font-size: 14px;
    line-height: 16px;
    margin-left: 20px;
  }
  /* Home Section Ends Here */
  .become-mentor-section-two {
    height: 100%;
  }
  .become-mentor-section_two_container {
    padding: 45px 0px;
  }
  .become-mentor-section-two-body {
    margin-top: 32px;
  }
  .become-mentor-section_two_container .heading {
    font-weight: 500;
    line-height: 29px;
    text-align: center;
  }
  .become-mentor-section-two-body .steps-container {
    flex-direction: column;
  }

  .become-mentor-section-three {
    height: 100%;
  }
  .become-mentor-section_three_container {
    padding: 40px 0px;
  }

  .become-mentor-section-three-leftCol .strike-heading {
    font-family: "eina";

    margin-top: 20px;
    text-align: center;
  }

  .become-mentor-section-three-leftCol .heading {
    font-family: "Neometric Medium";
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
  }
  .become-mentor-section-three-leftCol .desc {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
  }
  .become-mentor-section-three-leftCol .examples-container {
    flex-wrap: wrap;
    justify-content: space-between;
    width: 249px;
  }
  .example-item {
    width: 35% !important;
    margin: 2% 0px !important;
  }
  .example-pill-container {
    width: 45% !important;
  }
  .section_four_container {
    padding: 50px 0px;
  }
  .become-mentor-section_four_container {
    padding: 0px 0px 60px 0px;
    margin-top: 50px;
  }
  .become-mentor-section_four_container .heading {
    font-size: 38px;
    font-weight: 500;
    line-height: 40px;
    text-align: center;
    margin-bottom: 10px;
  }
  .become-mentor-section_four_container .desc {
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 70px;
  }
  .become-mentor-section-four-box {
    height: 280.21px !important;
  }

  .become-mentor-section-four-row-blue {
    flex-direction: column;
  }
  .become-mentor-section-four-row-blue .become-mentor-section-four-box img,
  .become-mentor-section-four-row-pink .become-mentor-section-four-box img {
    transform: translate(-50%, -50%) !important;
  }
  .become-mentor-section-four-box-screen-shot-one {
    background: url("../assets/Become-a-Mentor/Group 702.png");
  }
  .become-mentor-section-four-row-pink {
    flex-direction: column-reverse;
  }
  .become-mentor-section-four-row-blue-col-two {
    width: 100%;
    padding: 40px 0px;
  }
  .become-mentor-section-four-row-blue-col-two .col-strike-text {
    font-family: "eina";

    font-size: 9px;
    line-height: 117.02%;
    /* identical to box height, or 11px */
    text-align: center;
    letter-spacing: 0.1em;
  }
  .become-mentor-section-four-row-blue-col-two .col-strike-text:before {
    content: "";
  }
  .become-mentor-section-four-row-blue-col-two .col-heading {
    font-weight: 500;
    line-height: 29px;
    text-align: center;
  }
  .become-mentor-section-four-row-blue-col-two .col-desc {
    line-height: 16px;
    text-align: center;
    width: 100%;
    margin: 0 auto;
    margin-top: 20px;
  }

  .become-mentor-section-four-row-pink-col-one {
    width: 100%;
    padding: 30px 0 55px 0px;
  }
  .become-mentor-section-four-row-pink-col-one .col-strike-text {
    font-family: "eina";

    font-size: 9px;
    line-height: 117.02%;
    /* identical to box height, or 11px */
    text-align: center;
    letter-spacing: 0.1em;
  }
  .become-mentor-section-four-row-pink-col-one .col-strike-text:before {
    content: "";
  }
  .become-mentor-section-four-row-pink-col-one .col-heading {
    font-weight: 500;
    line-height: 29px;
    text-align: center;
  }
  .become-mentor-section-four-row-pink-col-one .col-desc {
    line-height: 16px;
    text-align: center;
    width: 100%;
    margin: 0 auto;
    margin-top: 20px;
  }
  .become-mentor-section-four-row-pink-col-one .col-pill-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .become-mentor-section-four-row-pink-col-one .col-pill-container span {
    width: 280px;
    font-size: 12px;
    margin: 3px 0;
  }
  .become-mentor-section-five {
    height: 100%;
  }
  .become-mentor-section-five-leftCol {
    padding-top: 0px;
  }
  .become-mentor-section-five-leftCol img {
    padding-top: 0px;
    width: 100%;
  }
  .become-mentor-section-five-row {
    display: flex;
    flex-direction: column;
  }
  .become-mentor-section-five-rightCol {
    width: 80%;
    margin: 0 auto !important;
    padding-top: 100px;
  }
  .become-mentor-section-five-rightCol .section-five-heading {
    font-weight: 500;
    font-size: 24px;
    line-height: 106.5%;
  }
  .become-mentor-section-five-rightCol .title {
    font-weight: 500;
    font-size: 14px;
    line-height: 106.5%;
  }
  .become-mentor-section-five-rightCol .title-text {
    font-size: 14px;
  }
  .become-mentor-section-five-rightCol .contact-for-help-text {
    line-height: 106.5%;
    /* or 15px */
    text-align: center;
  }
  .become-mentor-section-five-rightCol .content {
    height: fit-content;
  }
  .become-mentor-section-five-rightCol .content-text {
    overflow: hidden;
    font-weight: normal;
    font-size: 14px;
    line-height: 100%;
    text-align: start !important;
    /* or 15px */
  }
  .become-mentor-section-five-rightCol .content-open {
    margin-bottom: 20px;
    margin-top: 5px;
  }
}
