.form-loader {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 8px;
}

.form-loader div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin: 8px;
  border: 2px solid #3d3d3d;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #3d3d3d transparent transparent transparent;
}
.form-loader div:nth-child(1) {
  animation-delay: -0.45s;
}
.form-loader div:nth-child(2) {
  animation-delay: -0.3s;
}
.form-loader div:nth-child(3) {
  animation-delay: -0.15s;
}
