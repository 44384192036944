.mentee-profile-form-wrapper {
  margin: 40px 0px;
}
.inline-field {
  display: flex;
}
.inline-field > div {
  width: 100%;
  margin: 0px 20px;
}
.inline-field > div:first-child {
  margin-left: 0px;
}
.inline-field > div:last-child {
  margin-right: 0px;
}
/* Text Field CSS */
.user-profile-edit-profile-content .form-textbox {
  font-family: "Neometric Regular";

  background: #ffffff;
  border: 1.5px solid #d8d8d8;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  margin: 5px 0px;
}
.user-profile-edit-profile-content .form-textbox:focus {
  border: 1.5px solid #5555db;
}
.user-profile-edit-profile-content .form-textbox:not(:focus) {
  border: 1.5px solid #d8d8d8;
}
/* Number Field CSS */
.user-profile-edit-profile-content .form-number-field {
  font-family: "Neometric Regular";

  background: #ffffff;
  border: 1.5px solid #d8d8d8;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px;
  width: 20%;
  margin: 5px 0px;
}
.user-profile-edit-profile-content .form-number-field:focus {
  border: 1.5px solid #5555db;
}
.user-profile-edit-profile-content .form-number-field:not(:focus) {
  border: 1.5px solid #d8d8d8;
}
/* Form Label */
.user-profile-edit-profile-content .form-label {
  font-family: "Neometric Regular";
  display: inline-block;
  /* margin: 8px 0px; */
  color: #2c205b;
  font-size: 14px;
  line-height: 20px;
}
.user-profile-edit-profile-content .form-textbox:disabled {
  color: #ababab;
}
/* Form Line */
.user-profile-edit-profile-content .form-line {
  margin: 15px 0px;
}
.form-line .Mentor-info-error {
  color: red;
}

/* TextArea CSS */
.user-profile-edit-profile-content .form-textarea {
  font-family: "Neometric Regular";

  background: #ffffff;
  border: 1.5px solid #d8d8d8;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  height: 190px;
  margin-top: 5px;
  resize: none;
}
.user-profile-edit-profile-content .form-textarea:focus {
  border: 1.5px solid #5555db;
}
.user-profile-edit-profile-content .form-textarea:not(:focus) {
  border: 1.5px solid #d8d8d8;
}
.user-profile-edit-profile-content .edit-profile-checkbox-container {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  width: 786px;
  position: relative;
  left: -6px;
}
.edit-profile-button {
  /* flex: 1 0 21%;
  padding: 0px 5px; */
  margin-left: 15px;
  font-family: "Neometric Regular";

  width: 175px;
  height: 50px;
  background: #5555db;
  color: white;
  border-radius: 10px;
  border: 0;
  display: inline-flex;
  text-align: center;
  justify-content: center;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  overflow-wrap: wrap;
  word-wrap: break-word;
  white-space: initial;
}
.user-profile-edit-profile-content .edit-profile-checkbox {
  /* flex: 1 0 21%;
  padding: 0px 5px; */
  margin: 6px;
  font-family: "Neometric Regular";
  position: relative;
  width: 175px;
  height: 58px;
  background: #eeeefb;
  color: #5555db;
  border-radius: 10px;
  display: inline-flex;
  text-align: center;
  justify-content: center;
  justify-content: space-around;
  align-items: center;
  cursor: nesw-resize;
  overflow-wrap: wrap;
  word-wrap: break-word;
  white-space: initial;
}
.user-profile-edit-profile-content .edit-profile-checkbox:hover::before {
  position: absolute;
  width: 100%;
  height: 100%;
  content: "";
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
}
.user-profile-edit-profile-content .edit-profile-checkbox-selected {
  background: #2c205b;
  color: #ffffff !important;
}

.user-profile-edit-profile-content .edit-profile-radio-btn-container {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  width: 700px;
  position: relative;
  left: -8px;
}
.user-profile-edit-profile-content .edit-profile-radio {
  /* flex: 1 0 21%;
  padding: 0px 5px; */
  margin: 8px;
  font-family: "Neometric Regular";

  width: 175px;
  height: 58px;
  background: #eaeaea;
  color: #5555db;
  border-radius: 10px;
  display: inline-flex;
  text-align: center;
  justify-content: center;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  overflow-wrap: wrap;
  word-wrap: break-word;
  white-space: initial;
}
.user-profile-edit-profile-content .edit-profile-radio-selected {
  background: #2c205b;
  color: #ffffff !important;
}

.calendar-connection-icon i {
  font-size: 30px;
  color: #5555db;
}
.calendar-connection-icon {
  position: relative;
  align-items: flex-start;
  left: -10px;
  margin-top: 15px;
}

.calendar-connection-details h3 {
  line-height: 1;
}

.calendar-connection-icon:before {
  position: absolute;
  content: "";
  height: calc(100% - 10px);
  width: 2px;
  background-color: #5555db;
  bottom: -18px;
  left: calc(50% - 1px);
  z-index: 111;
}
.calendar-connection-icon-before-none.calendar-connection-icon:before {
  display: none;
}
.calendar-connection-img-box.edit-profile-checkbox {
  width: 100%;
  margin: 5px 0;
  height: 200px;
  background-color: #f1f1f1;
  font-weight: 700;
  background-size: cover !important;

  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  box-shadow: 0 3px 15px -5px rgb(0 0 0 / 20%);
}
.new_calendly_accept_invite-bg {
  background-image: url("../../../assets/dashboard/mentor-dashboard/mentor-dashboard-home/Product_GIFs_Calendly/Step\ 1/new_calendly_accept_invite.gif") !important;
}
.connect_calendars_bg {
  background-image: url("../../../assets/dashboard/mentor-dashboard/mentor-dashboard-home/Product_GIFs_Calendly/Step\ 2/connect_calendars.gif") !important;
}
.edit_availability_bg {
  background-image: url("../../../assets/dashboard/mentor-dashboard/mentor-dashboard-home/Product_GIFs_Calendly/Step\ 2/edit_availability.gif") !important;
}
.bio_and_save_bg {
  background-image: url("../../../assets/dashboard/mentor-dashboard/mentor-dashboard-home/Product_GIFs_Calendly/Step\ 3/bio_and_save.gif") !important;
}
.turn_profile_on_bg {
  background-image: url("../../../assets/dashboard/mentor-dashboard/mentor-dashboard-home/Product_GIFs_Calendly/Step\ 3/turn_profile_on.gif") !important;
}
.calendar_email_input {
  font-family: "Neometric Regular";
  font-size: 16px;
}

.cardo-btn.calendar-connection-btn {
  margin: 0 0 0 60px;
}

.mentee-dashboard-find-mentor-modal-mentor-info-uniqueness.mentor-dashboard-find-mentor-modal-mentor-info-uniqueness {
  height: 100%;
}

.mentor-dashboard-find-mentor-modal-interview-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.resume-download-btn {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  color: #5555db;
  align-items: center;
  cursor: pointer;
}

.profile-body-user-header {
  justify-content: space-between;
  width: 100%;
}

.profile-body-user-avatar-img-mentor {
  align-items: flex-end;
}

.profile-body-user-header-name-type {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.on-off-btn .btn-text {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  color: #5555db;
  font-family: "Neometric Regular";
  font-size: 12px;
  line-height: 14px;
}
.on-off-btn-none {
  display: none;
}

@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  .on-off-btn .switch[type="checkbox"] {
    --active: #eeeefb;
    --active-inner: #eeeefb;
    --focus: 2px rgba(39, 94, 254, 0.3);
    --border: #bbc1e1;
    --border-hover: #275efe;
    --background: #eeeefb;
    --disabled: #f6f8ff;
    --disabled-inner: #e1e6f9;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 45px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    background: var(--b, var(--background));
    transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
  }

  .on-off-btn input.switch[type="checkbox"]:after {
    content: "";
    display: block;
    left: 0;
    top: 0;
    position: absolute;
    transition: transform var(--d-t, 0.3s) var(--d-t-e, ease),
      opacity var(--d-o, 0.2s);
  }

  .on-off-btn input.switch[type="checkbox"]:checked {
    --b: var(--active);
    --d-o: 0.3s;
    --d-t: 0.6s;
    --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
  }

  .on-off-btn input.switch[type="checkbox"]:disabled {
    --b: var(--disabled);
    cursor: not-allowed;
    opacity: 0.9;
  }

  .on-off-btn input.switch[type="checkbox"]:disabled:checked {
    --b: var(--disabled-inner);
    --bc: var(--border);
  }

  .on-off-btn input.switch[type="checkbox"]:disabled + label {
    cursor: not-allowed;
  }

  .on-off-btn input.switch[type="checkbox"]:hover:not(:checked):not(:disabled) {
    --bc: var(--border-hover);
  }

  .on-off-btn input.switch[type="checkbox"]:focus {
    box-shadow: none;
  }

  .on-off-btn input.switch[type="checkbox"]:not(.switch) {
    width: 33px;
  }

  .on-off-btn input.switch[type="checkbox"]:not(.switch):after {
    opacity: var(--o, 0);
  }

  .on-off-btn input.switch[type="checkbox"]:not(.switch):checked {
    --o: 1;
  }

  .on-off-btn input.switch[type="checkbox"] + label {
    font-size: 14px;
    line-height: 45px;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    margin-left: 4px;
  }

  .on-off-btn input.switch[type="checkbox"]:not(.switch) {
    border-radius: 7px;
  }

  .on-off-btn input.switch[type="checkbox"]:not(.switch):after {
    width: 5px;
    height: 9px;
    border: 2px solid var(--active-inner);
    border-top: 0;
    border-left: 0;
    left: 7px;
    top: 4px;
    transform: rotate(var(--r, 20deg));
  }

  .on-off-btn input.switch[type="checkbox"]:not(.switch):checked {
    --r: 43deg;
  }

  .on-off-btn input.switch[type="checkbox"].switch {
    width: 85px;
    border-radius: 50px;
  }

  .on-off-btn input.switch[type="checkbox"].switch:after {
    left: 5px;
    top: 50%;
    border-radius: 50%;
    width: 33px;
    height: 33px;
    background: #5555db;
    transform: translateX(var(--x, 0)) translateY(-50%);
  }

  .on-off-btn input.switch[type="checkbox"].switch:checked {
    --ab: var(--active-inner);
    --x: 40px;
  }

  .on-off-btn
    input.switch[type="checkbox"].switch:disabled:not(:checked):after {
    opacity: 0.6;
  }
}
