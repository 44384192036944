.msg_toast {
  position: absolute;
  right: 0;
  top: 100px;
  height: 50px;
  padding: 0 50px 0 20px;
  display: flex;
  align-items: center;
  border-radius: 10px 10px 10px 10px;
  font-family: "Neometric Regular";
  -moz-animation: autoHide 0s ease-in 4s forwards;
  -webkit-animation: autoHide 0s ease-in 4s forwards;
  -o-animation: autoHide 0s ease-in 4s forwards;
  animation: autoHide 0s ease-in 4s forwards;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.toast_error {
  background: #f5c6cb;
  color: #721c24;
}
.toast_success {
  background: #c3e6cb;
  color: #155724;
}
.msg_toast_type {
  text-transform: capitalize;
  font-family: "Neometric Medium";
}
@keyframes autoHide {
  to {
    width: 0;
    height: 0;
    overflow: hidden;
  }
}
@-webkit-keyframes autoHide {
  to {
    width: 0;
    height: 0;
    visibility: hidden;
  }
}
