.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 555555;
  left: 0;
  top: 0px;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  border-radius: 20px;
  margin: auto;
  border: 1px solid #888;
  width: 1069px;
  height: 617px;
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* The Close Button */
.close {
  color: #aaaaaa;
  font-size: 28px;
  font-weight: bold;
  position: absolute;
  right: 20px;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
.popup-row {
  display: flex;
  height: 100%;
}

.popup-leftCol {
  background-color: #fac4dc;
  height: 100%;
  width: 100%;
  padding: 40px 0px 40px 40px;
  border-radius: 20px 0px 0px 20px;
  position: relative;
}
.popup-rightCol {
  width: 100%;
  padding: 12px 15px 0 15px;
}
.popup-leftCol h2 {
  font-weight: 500;
  font-size: 36px;
  line-height: 43px;

  color: #2c205b;
  margin-bottom: 10px;
}
.popup-leftCol p {
  width: 85%;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
}

.leftColLogo {
  width: 130px;
  position: absolute;
  top: 540px;
  left: 50px;
}

.popup-rightCol .form-label-left {
  width: 150px;
}

.popup-rightCol .form-line {
  padding-top: 6px;
  padding-bottom: 6px;
  position: relative;
}

.popup-rightCol .form-label-right {
  width: 150px;
}

.popup-rightCol .form-all {
  width: 690px;
  color: #555 !important;
  font-family: "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Verdana,
    sans-serif;
  font-size: 14px;
}

.popup-rightCol ul {
  list-style: none;
  padding: 10px 0px;
  width: 90%;
  margin: 0 auto;
  margin-top: 10px;
}
.popup-rightCol .form-submit-button {
  border: 0;
  background: #5555db;
  border-radius: 10px;
  color: white;
  width: 151px;
  height: 47px;
  font-family: "Neometric Medium";

  margin-top: 8px;
  margin-bottom: 10px;
  cursor: pointer;
}
/* Injected CSS Code */
/*PREFERENCES STYLE*/
.popup-rightCol .form-all {
  font-family: "Neometric Regular";
}

.popup-rightCol .form-all .qq-upload-button,
.popup-rightCol .form-all .form-submit-reset,
.popup-rightCol .form-all .form-submit-print {
  font-family: "Neometric Regular";
}

.popup-rightCol .form-all .form-pagebreak-back-container,
.popup-rightCol .form-all .form-pagebreak-next-container {
  font-family: "Neometric Regular";
}

.popup-rightCol .form-header-group {
  font-family: "Neometric Regular";
}
.popup-rightCol .submit-button .loader {
  display: none;
  position: relative;
  width: 80px;
  height: 80px;
}
.popup-rightCol .submit-button .loader.active {
  display: inline-block;
}
.popup-rightCol .submit-button .loader div {
  box-sizing: border-box;
  display: block;
  position: absolute;

  width: 30px;
  height: 30px;
  margin: 8px;
  border: 5px solid #2c205b;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #2c205b transparent transparent transparent;
}
.popup-rightCol .submit-button .loader div:nth-child(1) {
  animation-delay: -0.45s;
}
.popup-rightCol .submit-button .loader div:nth-child(2) {
  animation-delay: -0.3s;
}
.popup-rightCol .submit-button .loader div:nth-child(3) {
  animation-delay: -0.15s;
}
.popup-rightCol .form-label {
  font-family: "Neometric Regular";
}

.popup-rightCol .form-label.form-label-auto {
  display: inline-block;
  text-align: left;
}

.popup-rightCol .form-line {
  margin-top: 10x;
  margin-bottom: 10px;
}

.popup-rightCol .form-all {
  max-width: 690px;
  width: 100%;
}

.popup-rightCol .form-label.form-label-left,
.popup-rightCol .form-label.form-label-right,
.popup-rightCol .form-label.form-label-left.form-label-auto,
.popup-rightCol .form-label.form-label-right.form-label-auto {
  width: 100%;
  margin-bottom: 23px;
  color: #2c205b;
}

.popup-rightCol .form-all {
  font-size: 14px;
}

.popup-rightCol .form-all .qq-upload-button,
.popup-rightCol .form-all .qq-upload-button,
.popup-rightCol .form-all .form-submit-button,
.popup-rightCol .form-all .form-submit-reset,
.popup-rightCol .form-all .form-submit-print {
  font-size: 14px;
}

.popup-rightCol .form-all .form-pagebreak-back-container,
.popup-rightCol .form-all .form-pagebreak-next-container {
  font-size: 14px;
}

.popup-rightCol .supernova .form-all,
.popup-rightCol .form-all {
  background-color: #fff;
}

.popup-rightCol .form-all {
  color: #555;
}

.popup-rightCol .form-header-group .form-header {
  color: #555;
}

.popup-rightCol .form-header-group .form-subHeader {
  color: #555;
}

.popup-rightCol .form-label-top,
.popup-rightCol .form-label-left,
.popup-rightCol .form-label-right,
.popup-rightCol .form-html,
.popup-rightCol .form-checkbox-item label,
.popup-rightCol .form-radio-item label {
  color: #555;
}
.popup-rightCol .form-checkbox-item label {
  font-size: 16px;
}

.popup-rightCol .form-sub-label {
  color: #6f6f6f;
}

.popup-rightCol .supernova {
  background-color: undefined;
}

.popup-rightCol .supernova body {
  background: transparent;
}

.popup-rightCol .form-textbox,
.popup-rightCol .form-textarea,
.popup-rightCol .form-dropdown,
.popup-rightCol .form-radio-other-input,
.popup-rightCol .form-checkbox-other-input,
.popup-rightCol .form-captcha input,
.popup-rightCol .form-spinner input {
  background-color: undefined;
}

.popup-rightCol .supernova {
  background-image: none;
}

.popup-rightCol #stage {
  background-image: none;
}

.popup-rightCol .form-all {
  background-image: none;
}

.popup-rightCol .ie-8 .form-all:before {
  display: none;
}

.popup-rightCol .ie-8 {
  margin-top: auto;
  margin-top: initial;
}

/*PREFERENCES STYLE*/
/*__INSPECT_SEPERATOR__*/
/* Injected CSS Code */

/*custom radio button*/
.popup-rightCol .form-radio-item {
  position: relative;
}
.popup-rightCol .form-radio-item input[type="radio"] {
  position: absolute;
  visibility: hidden;
  transition: 0.3s ease-in;
}

.popup-rightCol .form-radio-item label {
  cursor: pointer;
  font-size: 16px;
  padding: 10px 0 10px 34px;
  z-index: 10;
  display: inline-block;
  position: relative;
}
.popup-rightCol .form-radio-item .check {
  cursor: pointer;
  display: block;
  position: absolute;
  border: 1px solid #aaaaaa;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  /*  */
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  z-index: 1;
  transition: border 0.25s linear;
  -webkit-transition: border 0.25s linear;
}

.popup-rightCol input[type="radio"]:checked ~ .check {
  border: 1px solid #5555db;
  background: #5555db;
}

.popup-rightCol input[type="radio"]:checked ~ .check::before {
  background: #5555db;
}

/*check box style*/

.popup-rightCol input[type="checkbox"] {
  display: none;
}
.popup-rightCol .form-checkbox-item label {
  padding: 10px 0 10px 35px;
  cursor: pointer;
  position: relative;
}
.popup-rightCol .form-checkbox-item {
  margin-bottom: 20px;
  max-height: 100px;
  width: 50%;
}
.popup-rightCol .form-checkbox-item label::before {
  content: "";
  height: 25px;
  width: 25px;
  background-color: transparent;
  border: 1px solid #aaaaaa;
  border-radius: 5px;
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  cursor: pointer;
}

.popup-rightCol input[type="checkbox"]:checked ~ label::before {
  border-color: #5555db;
  background-color: #5555db;
}
.popup-rightCol .list-checkbox {
  margin-top: 4px;
  display: flex;
  flex-direction: column;
  height: 110px;
  width: 370px;
  flex-wrap: wrap;
  align-items: flex-start;
}

/*input text style*/
.popup-rightCol .form-textbox {
  background: #fbfbff;
  border: 1.5px solid #d8d8d8;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 10px;
  width: 90%;
}
.popup-rightCol .form-textbox:focus {
  border: 1.5px solid #5555db;
}
.popup-rightCol .form-textbox:not(:focus) {
  border: 1.5px solid #d8d8d8;
}
.thankyou-container {
  text-align: center;
  padding: 50px 40px;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}
.thankyou-container > div {
  width: 100%;
  padding: 0px 0px;
  margin: 20px 0px;
}
.thankyou-heading-container h3 {
  font-family: "Neometric Medium";
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
}
.thankyou-container .be-one-the-lookout-text {
  font-family: "Neometric Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
}
.popup-rightCol .form-buttons-wrapper {
  text-align: center;
}
.thankyou-container .popup-done-btn-container {
  /* position: absolute; */
  margin: 30px;
}
.thankyou-container .popup-done-btn-container .done-btn {
  border: 0px;
  background: #5555db;
  border-radius: 10px;
  width: 151px;
  height: 47px;
  color: white;
  cursor: pointer;
}
.popup-rightCol .pop-policy-text {
  margin: 5px 0px;
  padding: 5px 0px;
  font-family: "eina";
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: #2c205b;
}
.popup-rightCol .last-form-line {
  position: absolute;
  margin: 0 auto;
  width: 41%;
  bottom: 5px;
}
.popup-rightCol .popup-mobile-header {
  display: none;
}
@media screen and (max-width: 1279px) {
  .modal-content {
    width: 1000px;
    height: 640px;
  }
}
@media screen and (max-width: 1023px) {
  .modal-content {
    width: 555px;
    height: 670px;
  }
  .popup-leftCol {
    display: none;
  }
  .popup-rightCol {
    width: 91%;
  }
  .popup-mobile-header {
    display: block;
    width: 92%;
    margin: 0 auto;
    margin-top: 20px;
  }
  .popup-mobile-header-heading {
    font-family: "Neometric Regular";
    margin-bottom: 10px;
  }
  .popup-mobile-header-content {
    font-size: 14px;
  }
  .popup-rightCol input[type="text"],
  .popup-rightCol input[type="email"] {
    width: 100%;
  }

  .popup-rightCol .list-checkbox {
    height: 105px;
    width: 300px;
    height: 110px;
    width: 370px;
  }
  .popup-rightCol .form-buttons-wrapper {
    margin-left: 0px !important;
  }
  .popup-rightCol .form-line {
    padding: 0px;
  }
  .popup-rightCol .last-form-line {
    width: 456px;
  }
  .popup-rightCol .form-submit-button {
    width: 100%;
  }
}
@media screen and (max-width: 420px) {
  .thankyou-container > div {
    padding: 0px 0px;
    margin: 10px 0px;
  }
  .modal-content {
    width: 345px;
    height: 560px;
  }
  .popup-mobile-header {
    display: block;
    width: 90%;
    margin: 0 auto;
    margin-top: 20px;
  }
  .popup-mobile-header-heading {
    color: #2c205b;
    font-family: "Neometric Medium";
    margin-bottom: 5px;
  }
  .popup-mobile-header-content {
    font-size: 11px;
    line-height: 13px;
    margin-bottom: 20px;
  }
  .thankyou-container {
    text-align: center;
    padding: 0 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .thankyou-container h3 {
    font-size: 27px;
    line-height: 32px;
  }
  .popup-rightCol .form-all {
    font-size: 11px;
  }
  .popup-rightCol .form-radio-item .check {
    width: 17px;
    height: 17px;
  }
  .popup-rightCol .form-radio-item label {
    font-size: 11px;
    padding: 4px 0 10px 27px;
  }
  .popup-rightCol .form-checkbox-item label {
    font-size: 11px;
    padding: 5px 0px 5px 27px;
  }
  .popup-rightCol .form-checkbox-item label::before {
    width: 17px;
    height: 17px;
  }
  .popup-rightCol .form-textbox {
    font-size: 11px;
  }
  .popup-rightCol ul {
    margin-top: 0px;
    padding-top: 0px;
  }
  .popup-rightCol .list-checkbox {
    height: 94px;
    width: 281px;
  }
  .popup-rightCol .form-label.form-label-left,
  .popup-rightCol .form-label.form-label-right,
  .popup-rightCol .form-label.form-label-left.form-label-auto,
  .popup-rightCol .form-label.form-label-right.form-label-auto {
    margin-bottom: 0px;
    margin: 5px 0 20px 0;
    color: #2c205b;
  }
  .popup-rightCol .form-line {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .popup-rightCol .form-submit-button {
    margin-bottom: 0px;
    height: 32px;
    font-size: 11px !important;
  }
  .popup-rightCol .last-form-line {
    width: 82%;
  }
  .popup-rightCol .pop-policy-text {
    font-size: 8px;
  }
}
