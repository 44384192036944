.donate-header {
  width: 100%;
  background-image: url("../assets/donate/Slice1.svg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 1100px;
}

.donate-container {
  position: absolute;
  left: 50%;
  top: 150px;
  transform: translateX(-50%);
  width: 550px;
  height: 700px;
  border-radius: 10px;
  box-shadow: 0px 0px 8px 2px #888888;
}
.frame-container {
  height: 100%;
}
.frame-container iframe {
  height: 100%;
}
@media screen and (max-width: 1279px) {
  .donate-header {
    height: 1100px;
  }
  .donate-container {
    top: 150px;
    width: 80%;
  }
  .frame-container iframe {
    height: 800px;
  }
}
@media screen and (max-width: 1023px) {
  .donate-header {
    height: 1100px;
  }
  .donate-container {
    top: 100px;
    width: 90%;
  }
  .frame-container iframe {
    height: 800px;
  }
}
@media screen and (max-width: 420px) {
  .donate-header {
    height: 1200px;
  }
  .donate-container {
    top: 100px;
    width: 90%;
  }
  .frame-container iframe {
    height: 912px;
  }
}
