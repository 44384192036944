.mentor-dashboard-invite-modal {
  position: fixed; /* Stay in place */
  z-index: 555555;
  left: 0;
  top: 0px;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content */
.mentor-dashboard-invite-modal-content {
  background-color: #fac4dc;
  border-radius: 20px;
  margin: auto;
  border: 0px solid #888;
  width: 864px;
  height: 649px;
  overflow-x: hidden;
  overflow-y: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* The Close Button */

.mentor-dashboard-invite-modal-close {
  color: black;
  font-size: 28px;
  font-weight: bold;
  position: absolute;
  right: 20px;
  top: 10px;
  transition: 0.2s ease-in-out;
}

.mentor-dashboard-invite-modal-close:hover,
.mentor-dashboard-invite-modal-close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.mentor-dashboard-invite-popup-row {
  display: flex;
  height: 100%;
}
.mentor-dashboard-invite-popup-row > div {
  width: 50%;
}
.mentor-dashboard-invite-popup-leftCol {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.mentor-dashboard-invite-popup-rightCol {
  background: #ffffff;
  border-radius: 20px;
  display: flex;
  align-items: center;
}
.mentor-dashboard-invite-popup-rightCol-container {
  margin: 0 25px;
}
.mentor-dashboard-invite-popup-rightCol-container .form-textbox {
  font-family: "Neometric Regular";

  background: #fbfbff;
  color: #000000;

  border: 1.5px solid #d8d8d8;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 10px;
  width: 90%;
  margin: 5px 0px;
}
.mentor-dashboard-invite-popup-rightCol-container .form-textbox:focus {
  border: 1.5px solid #5555db;
}
.mentor-dashboard-invite-popup-rightCol-container .form-textbox:not(:focus) {
  border: 1.5px solid #d8d8d8;
}

.err_msg {
  color: red;
}
