.admin_login_page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Neometric Regular;
}

.admin_login_page > div {
  margin: 20px 0;
}

.admin_login_page_logo img {
  width: 250px;
}
.admin_login_page_input {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 5px 10px;
  transition: 0.2s;
  outline: none;
}

.admin_login_page_input:focus {
  border: 1px solid black;
}

.admin_login_page_form {
  width: 250px;
}

.admin_login_page_form_control {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  margin: 10px 0;
}
.admin_login_page_form_control label {
  margin: 5px;
  color: #5555db;
  font-weight: bold;
}

.admin_login_page_button {
  cursor: pointer;
  padding: 10px 15px;
  color: white;
  border-radius: 20px;
  background-color: #5555db;
  width: fit-content;
  transition: 0.2s;
  width: 80px;
  text-align: center;
  letter-spacing: 0.5px;
}
.admin_login_page_button:hover {
  background-color: #3a3ab4;
}
