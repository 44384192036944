.inputWrapper {
  display: flex;
  width: 100%;
  justify-content: center;
}
.totalSeats,
.seatPrice {
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: space-between;
}

#totalSeats,
#seatPrice {
  width: 70px;
  height: 40px;
  border: 1px solid #5555db;
  border-radius: 5px;
  caret-color: #5555db;
  color: #5555db;
  font-family: "Neometric Medium";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
}

#totalSeats::placeholder,
#seatPrice::placeholder {
  color: #5555db;
}

#totalSeats:focus-visible,
#seatPrice:focus-visible {
  outline: 1px solid #5555db;
}

.totalSeats label,
.seatPrice label {
  padding-top: 5px;
  font-family: "Neometric Medium";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}

.calendly_seats_save {
  /* margin-top: 30px !important; */
  margin: 0 !important;
  align-self: start;
}
