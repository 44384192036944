.dashboard-sidebar {
  height: 100%;
  width: 255px;
  position: fixed;
  border-radius: 0px 30px 0px 0px;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: white;
}
.dashboard-sidebar-inner-container {
  position: relative;
  margin: 60px 0px;
  height: 100%;
}
.dashboard-sidebar .dashboard-logo {
  position: relative;
  left: 60px;
}
.dashboard-sidebar-links-container {
  margin: 90px 0px;
  height: 100%;
}
.dashboard-sidebar-link-container {
  position: relative;
}
.dashboard-sidebar-faq-link-container {
  position: absolute;
  bottom: 100px;
  left: 50%;
  transform: translate(-50%);
}
.dashboard-sidebar .sidebar-link {
  font-family: "Neometric Regular";
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-decoration: none;
  display: flex;
  align-items: center;
  color: #818181;
  /* margin: 10px 0px; */
  padding: 0 0 0 15px;
  color: #2c205b;
  margin-left: 60px;
  width: 195px;
  height: 43px;
  border-radius: 10px;
  transition: 0.3s ease-in;
}
.dashboard-sidebar .sidebar-link-active {
  width: 195px;
  color: #f1f1f1;
  background: #5555db;
  pointer-events: none;
}
.dashboard-sidebar .sidebar-link-active::before {
  content: " ";

  position: absolute;
  left: 0;
  top: 0;
  background: #5555db;
  width: 6px;
  height: 43px;
  border-radius: 0px 10px 10px 0px;
}
.dashboard-sidebar .sidebar-faq-link {
  position: relative;
  background: #eeeefb;
  border-radius: 10px;
  width: 155px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar-faq-link .sidebar-faq-img {
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translate(-50%);
}
.sidebar-faq-link .faq-text {
  text-align: center;
  margin: 10px 0;
}
.sidebar-faq-link .faq-btn {
  width: 107px;
  height: 38px;
  margin: 10px auto;
}
.dashboard-sidebar .dashboard-logo-sm {
  display: none;
}
.dashboard-sidebar .sidebar-link-sm {
  display: none;
}

/* .dashboard-sidebar .sidebar-link:hover {
  color: #ffffff;
  background: #5555db;
}
.dashboard-sidebar .sidebar-link:hover:before {
  content: " ";

  position: absolute;
  left: 0;
  top: 0;
  background: #5555db;
  width: 10px;
  height: 43px;
  border-radius: 0px 10px 10px 0px;
} */
@media screen and (max-width: 1410px) {
  .dashboard-sidebar {
    width: 230px;
  }
  .dashboard-sidebar .dashboard-logo {
    left: 35px;
  }
  .dashboard-sidebar .sidebar-link {
    margin-left: 35px;
  }
}
@media screen and (max-width: 1024px) {
  .dashboard-sidebar {
    width: 75px;
  }
  .dashboard-sidebar .dashboard-logo {
    display: none;
  }
  .dashboard-sidebar .dashboard-logo-sm {
    display: block;
    position: relative;
    left: 20px;
  }
  .dashboard-sidebar .sidebar-link {
    display: none;
  }
  .dashboard-sidebar .sidebar-link-sm {
    display: block;
    font-family: "Neometric Regular";
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-decoration: none;
    display: flex;
    align-items: center;
    color: #818181;
    /* margin: 10px 0px; */
    padding: 0 0 0 15px;
    color: #2c205b;
    margin-left: 20px;
    width: 63px;
    height: 43px;
    border-radius: 10px;
    transition: 0.3s ease-in;
  }
  .sidebar-link-sm-tooltip {
    display: none;
  }
  .sidebar-link-sm:hover .sidebar-link-sm-tooltip {
    display: block;
    height: 30px;
    display: flex;
    align-items: center;
    position: absolute;
    left: 62px;

    padding: 0 15px;
    background: #ffffff;
    border: 0.317962px solid rgba(44, 32, 91, 0.2);
    box-sizing: border-box;
    box-shadow: 0px 2.5437px 15.8981px rgba(0, 0, 0, 0.1);
    border-radius: 5.08739px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .dashboard-sidebar .sidebar-faq-link {
    position: relative;
    background: none;

    display: flex;
    align-items: center;
  }
  .dashboard-sidebar .faq-text {
    display: none;
  }
  .sidebar-faq-link .faq-btn {
    width: 75px;
    margin-left: 50px;
  }
  .sidebar-faq-link .sidebar-faq-img {
    top: -20px;
  }
}
