.my-sessions-details-popup-qacol {
  font-family: "Neometric Medium";
  font-size: 14px;
  margin-bottom: 15px;
}

.my-sessions-details-popup-qacol-answers {
  background: #ffffff;
  border-radius: 5px;
  width: 270px;
  height: 38px;
  margin-bottom: 15px;
  padding-left: 12px;
  display: flex;
  align-items: center;

  font-family: "eina";
  font-size: 14px;
}

.join_call_btn::after {
  content: "You can join your session 10 minutes before the scheduled start time";
  width: 270px;
  padding: 15px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.1);
  position: absolute;
  bottom: -70px;
  left: -70px;
}
