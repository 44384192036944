.alert {
  width: 100vw;
  padding: 10px 0px;
  background-color: #6565fc;
  position: relative;
  top: 0;
}
.alert-container {
  color: white;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */
  text-align: center;
  font-family: "Mulish";
}
.alert-container span {
  font-weight: 700;
}
@media screen and (max-width: 420px) {
  .alert-container {
    width: 90%;
    margin: 0 auto;
  }
}
