.mentee-dashboard-modalbutton .btn-finish {
  padding: 10px 33px;
  background-color: #5555db;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  color: #fff;
  border-radius: 5px;
  font-size: 16px;
  line-height: 16px;
  font-family: "Neometric Medium";
  text-transform: capitalize;
}

.mentee-dashboard-modalbutton .btn-finish:disabled {
  background-color: rgb(239, 239, 239);
  color: rgba(16, 16, 16, 0.5);
}

.mentee-dashboard-modalbutton .btn-finish i {
  display: block;
  margin-right: 10px;
}
.review_err {
  font-family: "Neometric Regular";
  font-size: 14px;
  color: red;
}
