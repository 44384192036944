.resizeable_table {
  display: inline-block;
  border-spacing: 0;
  padding-bottom: 20px;
  width: 100%;
  overflow: auto;
}

.resizeable_table_body {
  padding: 10px;
  background-color: white;
  width: fit-content;
  /* min-width: 100%; */
  border-radius: 10px;
}
.resizeable_table_row {
  width: 1013px !important;
  padding: 5px;
}
.resizeable_table_row:nth-child(even) {
  background: #f5f7f9;
  border-radius: 5px;
}
.resizeable_table_data,
.resizeable_table_header {
  margin: 0;
  padding: 0.5rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;

  position: relative;
}

.resizeable_table_header {
  font-size: 18px;
  text-transform: capitalize;
  color: #2c205b;
  background-color: #f5f7f9;

  font-family: "Neometric Medium";
  font-size: 14px;
  line-height: 16px;
}
.resizeable_table_data {
  font-family: "eina";
  font-size: 14px;
  line-height: 24px;
  color: #000000;
}
.resizer {
  display: inline-block;
  background: #d4d4ee;
  width: 10px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  opacity: 0;
  transform: translateX(50%);
  z-index: 1;
  /* touch-action: none; */

  /* &.isResizing {
    background: red;
  } */
}
.resizer:hover {
  opacity: 1;
}
.editable_table_input {
  width: 90%;
  outline: none;
  font-family: "eina";
  border: 0;
  background-color: transparent;
  /* background-image: url("../../../../assets/icons/editpen.svg");
  background-repeat: no-repeat;
  background-position: right center; */
  padding: 0;
  white-space: normal;

  cursor: pointer;
}
.editable_table_input:focus + img {
  display: none;
  /* background-image: none; */
}
.editable_table_input:focus {
  cursor: text;
}
.expanded_table_sub_component_container {
  display: flex;
  justify-content: space-between;
}
.expanded_table_sub_component_container_leftCol {
  width: 50%;
  display: flex;
  justify-content: space-between;
  font-family: "eina";
}
.expanded_table_sub_component_container_rightCol_handler {
  width: 25%;
  font-family: "Neometric Medium";
}
.expanded_table_sub_component_container_rightCol_handler > div {
  cursor: pointer;
  width: fit-content;
}
.expanded_table_sub_component_container_leftCol_item_container {
  display: flex;
  flex-direction: column;
}
.expanded_table_sub_component_container_leftCol_item_key {
  text-transform: capitalize;
  color: #5555db;
}
