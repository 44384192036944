.admin_dashboard_calendly_tabs {
  display: flex;
  position: relative;
  border-bottom: 2px solid #d4d4ee;

  font-family: "Neometric Medium";
  font-size: 14px;
  line-height: 16px;
  color: #2c205b;
  margin: 42px 0;
}
.admin_dashboard_calendly_tabs > div {
  margin: 0 16px;
  /* border-bottom: 3px solid #5555db; */
  padding: 10px 0;

  position: relative;
  bottom: -2px;
  cursor: pointer;
}
.admin_dashboard_calendly_tabs > div:first-child {
  margin-left: 0;
}
.admin_dashboard_calendly_tabs_active_tab {
  border-bottom: 3px solid #5555db;
}
.calendly-header-container{
  display: flex;
  justify-content: space-between;
}
.calendly-header-container input{
  width: 240px;
  padding: 10px;
  border: 1px;
  border-radius: 10px;
}
.calendly-header-container input::placeholder{
  color: gray;
  font-size: 12px;
  font-family: "Neometric Regular";
  font-weight: bold;
}