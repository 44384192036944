.dashboard-body-color {
  background-color: #f5f7f9;
}
.dashboard-body {
  min-height: 768px;
  display: flex;
  flex-direction: column;
  position: relative;
}
.dashboard-main {
  margin: 35px auto;
  width: 1370px;
}

.dashboard-main-container {
  margin-left: 240px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}
.dashboard-header {
  position: relative;
  margin-bottom: 85px;
}
.dashboard-header-container {
  margin: 10px;
  display: flex;

  justify-content: flex-end;
  position: absolute;
  right: 0;
}
.dashboard-header-container > div {
  margin: 0 12px;
}
.dashboard-header-currency {
  background: #fac4dc;
  border-radius: 10px;
  padding: 0 20px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-family: "Neometric Regular";
}
.dashboard-header-notification {
  font-family: "Neometric Regular";
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border-radius: 10px;
  width: 70px;
  height: 65px;
}
.dashboard-header-current-user-dropdown {
  display: flex;
  flex-direction: column;
}
.dashboard-header-current-user {
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 65px;
  background: #ffffff;
  border-radius: 10px;
  cursor: pointer;
  z-index: 1111;
}
.dashboard-header-current-user-dropdown-body {
  background: #ffffff;
  /* height: 90px; */
  display: flex;
  margin-top: -8px;
  padding-top: 8px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  border-radius: 0px 0px 10px 10px;
  display: none;
  /* position: absolute; */
  /* width: 179px; */
  top: 98px;
}

.dashboard-header-current-user-dropdown-body.open {
  display: block;
  z-index: 111;
}

.dashboard-header-current-user-dropdown-item {
  display: flex;
  padding: 8px;
  font-family: "Neometric Regular";
  cursor: pointer;
}
.dashboard-header-current-user-dropdown-item:first-child {
  margin-top: 16px;
}
.dashboard-header-current-user-dropdown-item:last-child {
  margin-bottom: 10px;
}
.dashboard-header-current-user-dropdown-item-before {
  width: 19px;
  height: 20px;

  margin-right: 10px;
  background: #eeeefb;
  border-radius: 5px;
}
.dashboard-header-current-user-dropdown-item:hover {
  background: #f6f6f6;
  /* border-radius: 10px; */
}
.dashboard-header-user-avatar {
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  width: 48px;
  height: 48px;
}

@media screen and (max-width: 1410px) {
  .dashboard-main {
    width: 1300px;
  }
}
@media screen and (max-width: 1290px) {
  .dashboard-main {
    width: 1240px;
  }
  .mentee-dashboard-home-leftCol {
    width: 600px !important;
  }
  .mentor-dashboard-home-rightCol-row-1 {
    width: 320px !important;
  }
  .mentor-dashboard-home-rightCol-row2 {
    width: 320px !important;
  }
}
@media screen and (max-width: 1024px) {
  .dashboard-main-container {
    margin-left: 75px;
  }
  .dashboard-main {
    width: 1000px;
  }
  .mentee-dashboard-home-leftCol {
    width: 560px !important;
  }
  .mentor-dashboard-home-rightCol-row-1 {
    width: 285px !important;
  }
  .mentor-dashboard-home-rightCol-row2 {
    width: 285px !important;
  }
}

/*----------------Dropdown Notification Css------------*/
.navbar .navbar_right {
  display: flex;
}

.navbar .navbar_right img {
  width: 30px;
}

.navbar .navbar_right .icon_wrap {
  cursor: pointer;
}

.navbar .navbar_right .notifications .icon_wrap {
  font-size: 28px;
}

.navbar .navbar_right .profile,
.navbar .navbar_right .notifications {
  position: relative;
  cursor: pointer;
  user-select: none;
}

.navbar .navbar_right .notifications-unread:before {
  position: absolute;
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: red;
  right: 22px;
  top: 19px;
}

.navbar .profile .profile_dd,
.notification_dd {
  position: absolute;
  top: 76px;
  right: 0px;
  user-select: none;
  background: #fff;
  border: 1px solid #c7d8e2;
  width: 350px;
  height: auto;
  display: none;
  border-radius: 3px;
  box-shadow: 10px 10px 35px rgba(0, 0, 0, 0.125),
    -10px -10px 35px rgba(0, 0, 0, 0.125);
  z-index: 2;
}

.navbar .profile .profile_dd:before,
.notification_dd:before {
  content: "";
  position: absolute;
  top: -20px;
  right: 15px;
  border: 10px solid;
  border-color: transparent transparent #fff transparent;
}

.notification_dd li {
  display: flex;
  justify-content: center;
}

.notification_dd li .notify_icon {
  display: flex;
}

.notification_dd li .notify_icon .icon {
  display: inline-block;
  background: url("https://i.imgur.com/MVJNkqW.png") no-repeat 0 0;
  width: 40px;
  height: 42px;
}

.notification_dd li.baskin_robbins .notify_icon .icon {
  background-position: 0 -43px;
}

.notification_dd li.mcd .notify_icon .icon {
  background-position: 0 -86px;
}

.notification_dd li.pizzahut .notify_icon .icon {
  background-position: 0 -129px;
}

.notification_dd li.kfc .notify_icon .icon {
  background-position: 0 -178px;
}

.notification_dd li .notify_data {
  margin: 0;
  width: 100%;
}

.notification_dd li .notify_data .title {
  color: #000;
  font-size: 18px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 10px 15px;
}

.notification_dd li .notify_data .title.unread {
  background: #f1f1f1;
}

.notification_dd li .notify_data .title:last-child {
  border-bottom: none;
}

.notification_dd li .notify_data .sub_title {
  font-size: 10px;
  white-space: none;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 5px;
  color: #6b7280;
}

.notification_dd li .notify_data .time {
  font-size: 9px;
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 5px;
  color: #6b7280;
}

.notification_dd li .notify_status p {
  font-size: 12px;
}

.notification_dd li.success .notify_status p {
  color: #47da89;
}

.notification_dd li.failed .notify_status p {
  color: #fb0001;
}

.notification_dd li.show_all {
  padding: 20px;
  display: flex;
  justify-content: center;
}

.notification_dd li.show_all p {
  font-weight: 700;
  color: #3b80f9;
  cursor: pointer;
}

.notification_dd li.show_all p:hover {
  text-decoration: underline;
}

.navbar .navbar_right .profile .icon_wrap {
  display: flex;
  align-items: center;
}

.navbar .navbar_right .profile .name {
  display: inline-block;
  margin: 0 10px;
}

.navbar .navbar_right .icon_wrap:hover,
.navbar .navbar_right .profile.active .icon_wrap,
.navbar .navbar_right .notifications.active .icon_wrap {
  color: #3b80f9;
}

.navbar .profile .profile_dd {
  width: 225px;
}
.navbar .profile .profile_dd:before {
  rigth: 10px;
}

.navbar .profile .profile_dd ul li {
  border-bottom: 1px solid #f1f2f4;
}

.navbar .profile .profile_dd ul li a {
  display: block;
  padding: 15px 35px;
  position: relative;
}

.navbar .profile .profile_dd ul li a .picon {
  display: inline-block;
  width: 30px;
}

.navbar .profile .profile_dd ul li a:hover {
  color: #3b80f9;
  background: #f0f5ff;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.navbar .profile .profile_dd ul li.profile_li a:hover {
  background: transparent;
  cursor: default;
  color: #7f8db0;
}

.navbar .profile .profile_dd ul li .btn {
  height: 32px;
  padding: 7px 10px;
  color: #fff;
  border-radius: 3px;
  cursor: pointer;
  text-align: center;
  background: #3b80f9;
  width: 125px;
  margin: 5px auto 15px;
}

.navbar .profile .profile_dd ul li .btn:hover {
  background: #6593e4;
}

.navbar .profile.active .profile_dd,
.navbar .notifications.active .notification_dd {
  display: block;
}

.navbar .notifications.active .notification_dd {
  width: 300px;
  border-radius: 10px;
  z-index: 99999;
  /*overflow: auto;*/
}
.navbar
  .notifications.active
  .notification_dd
  .notification_ul::-webkit-scrollbar {
  width: 0;
}
.notification_ul {
  padding: 0;
  margin: 0;
  border-radius: 10px;
  overflow: auto;
  max-height: 350px;
}
.notification_ul .kfc.success {
  border-bottom: 0;
}
