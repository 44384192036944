.find-mentor-header {
  width: 100%;
  height: 120vh;
  max-height: 868px;
  background-color: #5555db;
}
.find-mentor-header_container {
  height: 100%;
  width: 90%;
  max-width: 1300px;
  margin: 0 auto;
  padding-top: 100px;
}
.find-mentor-header-row {
  display: flex;
  width: 100%;
  height: 600px;
  justify-content: space-between;
}
.find-mentor-header-rightCol {
  margin: 0px !important;
  width: 60%;
  display: flex;
  align-items: center;
}
.find-mentor-header-rightCol .find-mentor-header-desktop-img {
  display: block;
  width: 100%;
}
.find-mentor-header-rightCol .find-mentor-header-mobile-img {
  display: none;
}

.find-mentor-header-leftCol {
  width: 40%;
  display: flex;
  align-items: center;
}
.find-mentor-header-leftCol .find-your-mentor-text {
  font-size: 16px;
  line-height: 105%;
  font-family: "Neometric Regular";

  /* or 17px */
  margin-left: 5px;
  color: #ffffff;
  margin-bottom: 20px;
}
.find-mentor-header-leftCol .get-tailored-text {
  font-weight: 600;
  line-height: 105%;
  /* or 49px */
  color: #ffffff;
  margin-bottom: 20px;
}
.find-mentor-header-leftCol .easily-access-text {
  line-height: 21px;

  color: #ffffff;
  margin-bottom: 20px;
}
.find-mentor-header-leftCol .apply-now-container {
  margin-bottom: 20px;
}
.find-mentor-header-leftCol .apply_now_btn {
  width: 154.89px;
  height: 45px;
  background-color: #fac4dc;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  color: #0d0a1b;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 700;
  margin: 0;
}
.find-mentor-header-leftCol .brands-container {
  width: 380px;
}
.find-mentor-header-leftCol .brands {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  justify-content: space-between;
}
.find-mentor-header-leftCol .brands div {
  flex: 0 0 33.3%;
  margin-top: 10px;
}
.find-mentor-section-one {
  height: 100%;
  background: #2c205b;
}
.find-mentor-section-one_container {
  width: 1440px;
  margin: 0 auto;
  padding: 60px 0px;
}

.find-mentor-section-one-leftCol .strike-heading {
  font-family: "eina";

  font-weight: 300;
  font-size: 11px;
  line-height: 117.02%;
  /* or 13px */
  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #ffffff;
}

.find-mentor-section-one-leftCol .strike-heading:before {
  content: "\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0";
  text-decoration: line-through;
  margin-right: 1rem;
}

.find-mentor-section-one-leftCol .heading {
  font-family: "Neometric Medium";

  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 24px;

  font-weight: 500;
  line-height: 29px;

  color: #ffffff;
}

.find-mentor-section-one-leftCol .desc {
  margin-top: 30px;
  line-height: 19px;
  font-size: 14px;
  color: #ffffff;
}

.examples-container {
  font-family: "Neometric Regular";
  margin-top: 40px;
  display: flex;
}

.example-item {
  text-align: center;
  width: 120px;
  padding: 10px 15px;
  margin: 0px 8px;
  background-color: #564d7c;
  border-radius: 10px;
  font-size: 14px;
  line-height: 138.52%;
  /* or 19px */
  text-align: center;

  color: #ffffff;
}
.example-pill-container {
  margin-left: 0px !important ;
  padding: 0px 0px !important;
  background-color: transparent !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.example-pill-container span {
  padding: 2px;
  background-color: #564d7c;
  border-radius: 10px;
  font-size: 14px;
  line-height: 138.52%;
  /* or 19px */
  text-align: center;

  color: #ffffff;
}

.find-mentor-section-two {
  height: 579px;
  background: #f5f7f9;
}
.find-mentor-section-two_container {
  padding: 80px 40px;
}
.find-mentor-section-two_container .heading {
  font-weight: 500;
  line-height: 43px;
  text-align: center;

  color: #0d0a1b;
}
.find-mentor-section-two-body .card-container {
  margin-top: 50px;
  display: flex;
  justify-content: center;
}
.find-mentor-section-two-body .card-container .card {
  width: 259.03px;
  height: 263px;
  padding: 30px 28px;
  background: #ffffff;
  margin: 20px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  border-radius: 10px;
}
.find-mentor-section-two-body .card-container .card .card-icon {
  background-color: #fac4dc;
  border-radius: 10px;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.find-mentor-section-two-body .card-container .card .card-heading {
  font-family: "Neometric Medium";
  margin-top: 30px;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
}
.find-mentor-section-two-body .card-container .card .card-desc {
  margin-top: 30px;

  font-size: 1em;
  line-height: 20px;
}
.find-mentor-section-two-body-mobile {
  display: none;
}
.find-mentor-section-three {
  height: 497px;

  background-color: #eef1f4;
}
.find-mentor-section-three_container {
  padding: 50px 0px;
}
.find-mentor-section-three_container .heading {
  text-align: center;
  font-weight: 500;
  line-height: 43px;
  text-align: center;
}
.find-mentor-section-three-body {
  margin-top: 60px;
}
.find-mentor-section-three-body .steps-container {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.find-mentor-section-three-body .steps-container .steps {
  margin: 20px 0px;
  flex: 0 0 30%;
}

.find-mentor-section-three-body .steps-container .step {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.find-mentor-section-three-body .steps-container .step .step-icon {
  width: 55px;
  height: 55px;
  background: #ffffff;
  border-radius: 12.5px;
  margin-right: 20px;
  margin-top: 10px;
  position: relative;
}
.find-mentor-section-three-body .steps-container .step .step-icon img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.find-mentor-section-three-body .steps-container .step-content {
  width: 70%;
  display: inline;
}
.find-mentor-section-three-body .steps-container .step .step-no {
  font-family: "Neometric Medium";
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 10px;
}
.find-mentor-section-three-body .steps-container .step .step-desc {
  font-family: "Neometric Regular";
  font-size: 14px;
  line-height: 17px;
}
.find-mentor-section-four {
  height: 100%;
  padding-bottom: 20px;
}

.find-mentor-section-four_container {
  padding: 50px 0px;
}
.find-mentor-section-four-mobile {
  display: none;
}
.find-mentor-section-four_container .section-heading {
  font-weight: 500;
  line-height: 43px;
  text-align: center;
  margin-bottom: 10px;
}
.find-mentor-section-four_container .section-desc {
  font-size: 18px;
  line-height: 22px;
  text-align: center;

  color: #0d0a1b;
}
.find-mentor-section-four_container .cards-container {
  padding-top: 50px;
}
.find-mentor-section-four_container .cards-container .industry-card-container {
  display: flex;
  justify-content: center;
}

.find-mentor-section-four_container
  .cards-container
  .industry-card-container
  .industry-card {
  width: 257px;
  height: 240px;
  margin: 0px 20px;
  background: #eaeaea;
  border-radius: 10px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.find-mentor-section-four_container
  .cards-container
  .industry-card-container
  .industry-card
  .card-desc {
  font-family: "Neometric Regular";
  display: flex;
  flex-direction: column;
}
.find-mentor-section-four_container
  .cards-container
  .industry-card-container
  .industry-card
  .card-desc
  span {
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;

  color: #737373;
}
.find-mentor-section-four_container
  .cards-container
  .industry-card-container
  .industry-card
  .card-heading {
  font-family: "Neometric Medium";
  font-style: normal;
  font-weight: 500;
  line-height: 29px;
  font-size: 24px;
  color: #777777;
  margin-bottom: 25px;
}
.find-mentor-section-four_container
  .cards-container
  .industry-card-container
  .industry-card
  .card-btn {
  background: #cbcbcb;
  border-radius: 10px;
  border: 0;
  width: 100%;
  height: 54px;
  cursor: pointer;
  font-family: "Neometric Regular";
}

/*  */
.find-mentor-section-four_container
  .cards-container
  .industry-card-container
  .active-card {
  background: #5555db;
}

.find-mentor-section-four_container
  .cards-container
  .industry-card-container
  .active-card
  .card-desc
  span {
  color: white;
}
.find-mentor-section-four_container
  .cards-container
  .industry-card-container
  .active-card
  .card-heading {
  font-family: "Neometric Medium";
  font-style: normal;
  font-weight: 500;
  line-height: 29px;

  color: white;
  margin-bottom: 25px;
}
.find-mentor-section-four_container
  .cards-container
  .industry-card-container
  .active-card
  .card-btn {
  background: #2c205b;
  border-radius: 10px;
  border: 0;
  width: 100%;
  height: 54px;
  color: white;
  cursor: pointer;
  font-family: "Neometric Regular";
}

.find-mentor-section-five {
  background-color: #f9f9fa;
  height: 352px;
  padding: 120px 0 30px 0;
}

.find-mentor-section-five-mobile {
  display: none;
}
.find-mentor-section-five-desktop .section-five-heading {
  font-weight: 500;
  line-height: 43px;
  text-align: center;
  margin-bottom: 10px;
  color: #0d0a1b;
}
.find-mentor-section-five-desktop .section-five-desc {
  font-size: 18px;
  line-height: 22px;
  text-align: center;

  color: #0d0a1b;
}
.find-mentor-section-five-desktop .trail-cards-container {
  padding-top: 50px;
}
.find-mentor-section-five-desktop .trail-card-container {
  display: flex;
  justify-content: center;
}

.find-mentor-section-five-desktop .trail-card {
  width: 178.61px;
  height: 127px;
  margin: 0px 20px;
  background: #eeeefb;
  border: 1px solid #7777e2;
  box-sizing: border-box;
  border-radius: 6.79144px;
  cursor: pointer;
  padding: 15px 17px 0 17px;
  transition: 0.3s ease-in;
}
.find-mentor-section-five-desktop .trail-card:hover {
  background: #5555db;
  border-radius: 6.79144px;
}
.find-mentor-section-five-desktop .trail-card:hover .trail-card-heading,
.find-mentor-section-five-desktop .trail-card:hover .trail-card-desc {
  color: white;
}
.find-mentor-section-five-desktop .trail-card .trail-card-heading {
  font-family: "Neometric Medium";
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #5555db;

  margin-bottom: 10px;
}
.find-mentor-section-five-desktop .trail-card .trail-card-desc {
  font-size: 14px;

  line-height: 16px;

  color: #5555db;
}

.find-mentor-section-five-desktop .trail-card-active {
  background: #5555db;
  border-radius: 6.79144px;
}
.find-mentor-section-five-desktop .trail-card-active .trail-card-heading {
  color: #ffffff;
}
.find-mentor-section-five-desktop .trail-card-active .trail-card-desc {
  color: #ffffff;
}
.find-mentor-section-six {
  height: 100%;
  padding-bottom: 100px;
}
.mentor_img_back {
  position: relative;
}
.mentor_img_back::before {
  z-index: -1;
  content: "";
  border-radius: 17px;
  position: absolute;
  top: 1%;
  left: 1%;
  width: 98%;
  height: 98%;
  background: linear-gradient(360deg, #b9b9b9, #353535, #b9b9b9);
  background-size: 200% 200%;

  -webkit-animation: AnimationName 2s ease infinite;
  -moz-animation: AnimationName 2s ease infinite;
  animation: AnimationName 2s ease infinite;
}
@-webkit-keyframes AnimationName {
  0% {
    background-position: 50% 0%;
  }
  50% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 50% 0%;
  }
}
@-moz-keyframes AnimationName {
  0% {
    background-position: 50% 0%;
  }
  50% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 50% 0%;
  }
}
@keyframes AnimationName {
  0% {
    background-position: 50% 0%;
  }
  50% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 50% 0%;
  }
}

.find-mentor-section-six_container {
  padding-top: 100px;
}
.find-mentor-section-six .find-mentor-section-six-heading {
  font-weight: 500;
  line-height: 43px;
  text-align: center;
  margin-bottom: 10px;
}
.find-mentor-section-six .find-mentor-section-six-desc {
  font-size: 18px;
  line-height: 22px;
  text-align: center;
}
.find-mentor-section-six-mobile {
  display: none;
}
.find-mentor-section-seven {
  height: 100%;
  padding-bottom: 50px;
}
.section-seven-heading {
  font-weight: 500;
  line-height: 106.5%;
}
.find-mentor-section-seven-row {
  margin: 0 auto;
  margin-top: 100px;
  max-width: 1440px;
}
.find-mentor-section-seven-leftCol {
  padding-top: 30px;
  text-align: center;
}
.find-mentor-section-seven .accordion {
  width: 100%;
  background-color: transparent;
  margin: auto;
  margin-top: 50px;
}

.find-mentor-section-seven .title {
  margin-top: 2px;
  height: auto;
  width: 100%;
  font-weight: 500;
  font-size: 24px;
  line-height: 106.5%;
  /* or 26px */

  color: #000000;
  text-align: left;
  position: relative;
  padding: 20px 10px 5px 10px;
  z-index: 2000;
  border-radius: 4px;

  transition: all 0.2s ease-in;
}

.find-mentor-section-seven .title-text {
  margin-left: 20px;
}

.find-mentor-section-seven .title:hover {
  cursor: pointer;
}

.find-mentor-section-seven .content {
  height: fit-content;
  width: 100%;
  overflow: hidden;
  background-color: transparent;
  border-radius: 4px;
  color: black;
  font-size: 14px;
  font-size: 14px;
  line-height: 106.5%;
  text-align: center;
  position: relative;
  z-index: 1000;
  margin-top: -30px;
  padding-left: 15px;
  text-align: left;
  transition: all 200ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.find-mentor-section-seven .content-open {
  margin-top: 15px;
  height: 100%;
  transition: all 350ms cubic-bezier(0.08, 1.09, 0.32, 1.275);
}

.find-mentor-section-seven .content-text {
  width: 90%;
  padding: 0 0 5px 15px;
  visibility: hidden;
  opacity: 0;
  overflow: auto;
  transition: all 0.2s ease-in;
}

.find-mentor-section-seven .content-text-open {
  visibility: visible;
  opacity: 1;
  transition: all 0.8s ease-in;
}

.find-mentor-section-seven .fa-angle-right {
  color: rgba(0, 0, 0, 1);
  transition: all 0.6s cubic-bezier(0.08, 1.09, 0.32, 1.275);
}

.find-mentor-section-seven .fa-rotate-90 {
  color: rgba(0, 0, 0, 1);
}

.find-mentor-section-seven .arrow-wrapper {
  position: absolute;
  margin-left: -10px;
}
.find-mentor-section-seven .apply-now-container {
  margin-top: 50px;
  margin-bottom: 50px;
}
.find-mentor-section-seven .apply_now_btn {
  width: 154.89px;
  height: 45px;
  background-color: #fac4dc;
  border: 0;
  color: #0d0a1b;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 700;
  margin: 0;
}

.find-mentor-btn {
  font-size: 13px;
}
@media screen and (max-width: 1280px) {
  .example-item {
    font-size: 12px;
    padding: 10px;
  }
  .find-mentor-section-five-desktop .trail-card {
    height: 127px;
  }

  .find-mentor-section-one .find-mentor-section-one_container {
    width: 90% !important;
  }
  .find-mentor-section-one-row {
    width: 90%;
  }
  .find-mentor-section-three_container {
    width: 90% !important;
  }
  .find-mentor-section-four_container {
    width: 90%;
    margin: 0 auto;
  }
  .find-mentor-section-five_container {
    width: 90%;
    margin: 0 auto;
  }
  .find-mentor-section-five {
    height: 100%;
    padding-bottom: 20px;
  }
  .find-mentor-section-five-desktop .trail-card-container {
    flex-wrap: wrap;
  }
  .find-mentor-section-five-desktop .trail-card-container .trail-card {
    flex: 0 0 25%;
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 1024px) {
  .find-mentor-section-one-row {
    width: 100%;
    display: flex;
  }
  .find-mentor-section-one-row .find-mentor-section-one-rightCol {
    padding-top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .find-mentor-section-one-row .find-mentor-section-one-rightCol img {
    width: 140%;
  }
  .find-mentor-section-one-row .find-mentor-section-one-leftCol {
    width: 45%;
  }
  .find-mentor-section-one-leftCol .examples-container {
    flex-wrap: wrap;
    justify-content: space-between;
    width: 295px;
  }
  .example-item {
    width: 35% !important;
    margin: 2% 0px !important;
  }
  .example-pill-container {
    width: 42% !important;
  }

  .find-mentor-section-three_container {
    width: 100% !important;
  }
}

@media screen and (max-width: 960px) {
  .find-mentor-header {
    height: 100%;
    max-height: 100%;
    padding-bottom: 100px;
    background-image: url("../assets/Mask Group-5.png");
    background-size: cover;

    background-position: center center;
  }

  .find-mentor-header-row {
    display: flex;
    flex-direction: column-reverse;
    height: 100%;
  }
  .find-mentor-header-row .find-mentor-header-rightCol {
    width: 100%;
    text-align: center;
  }
  .find-mentor-header-row
    .find-mentor-header-rightCol
    .find-mentor-header-desktop-img {
    display: none;
  }
  .find-mentor-header-row
    .find-mentor-header-rightCol
    .find-mentor-header-mobile-img {
    display: block;
    width: 80%;
    margin: 0 auto;
  }
  .find-mentor-header-leftCol {
    padding: 60px 0px 0px 0px !important;
    width: 100%;
  }

  .find-mentor-header-leftCol .find-mentor-header-leftCol-container {
    width: 70%;
    text-align: center;
    margin: 0 auto;
  }
  .find-mentor-header-leftCol .get-tailored-text {
    margin-left: auto;
    margin-right: auto;
  }
  .find-mentor-header-leftCol .easily-access-text {
    margin-left: auto;
    margin-right: auto;
  }

  .find-mentor-header-leftCol-container .brands-container {
    width: 100% !important;
  }
  .find-mentor-section-two_container {
    width: 90%;
  }
  .find-mentor-section-three {
    height: 100%;
    padding-bottom: 50px;
  }
  .find-mentor-section-three_container {
    width: 85% !important;
  }
  .find-mentor-section-three-body {
    margin-top: 40px;
  }
  .find-mentor-section-three-body .steps-container .steps {
    flex: 0 0 50%;
  }
  .find-mentor-section-seven-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
  }
  .find-mentor-section-seven-leftCol,
  .find-mentor-section-seven-rightCol {
    width: 100% !important;
  }
  .find-mentor-section-seven-rightCol {
    padding-top: 100px;
  }
  .find-mentor-section-seven-leftCol img {
    width: 100%;
  }
  .find-mentor-section-seven-rightCol .apply-now-container {
    text-align: center;
  }
  .find-mentor-section-seven-rightCol .contact-for-help-text {
    text-align: center;
  }
}
@media screen and (max-width: 959px) {
  .find-mentor-section-two_container {
    width: 95% !important;
    padding: 50px 0px !important;
  }
  .find-mentor-section-two_container .card-container .card {
    margin: 0px 8px;
    padding: 20px 17px;
  }
  .find-mentor-section-two_container .card-container .card .card-heading {
    font-size: 14px;
  }
  .find-mentor-section-two-body .card-container .card .card-desc {
    font-size: 14px;
  }
  .find-mentor-section-two {
    height: 100%;
  }
}

@media screen and (max-width: 768px) {
  .find-mentor-section-one-row {
    display: flex;
    flex-direction: column-reverse;
  }
  .find-mentor-section-one-leftCol,
  .find-mentor-section-one-rightCol {
    width: 98% !important;
  }
  .find-mentor-section-one-rightCol {
    padding-left: 0px;
    padding-bottom: 50px;
    align-items: center;
  }
  .find-mentor-section-one-leftCol {
    text-align: center;
  }
  .find-mentor-section-one-leftCol .strike-heading {
    text-align: center;
  }
  .find-mentor-section-one-leftCol .strike-heading:before {
    content: "";
  }
  .find-mentor-section-one-rightCol img {
    width: 90% !important;
  }
  .find-mentor-section-one-leftCol .examples-container {
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 500px;
    margin: 20px auto 20px auto;
  }
  .example-item {
    width: 35% !important;
    margin: 0px 10px !important;
  }
  .example-pill-container {
    width: 45% !important;
  }

  .find-mentor-section-three_container {
    width: 93% !important;
  }
  .find-mentor-section-three-body .steps-container .steps {
    flex: 0 0 100%;
  }
  .find-mentor-section-four-desktop {
    display: none;
  }
  .find-mentor-section-four {
    height: 100%;
  }
  .find-mentor-section-four-mobile {
    display: block;
  }
  .find-mentor-section-four-mobile .section-heading {
    font-weight: 500;
    line-height: 29px;
    text-align: center;
  }
  .find-mentor-section-four-mobile .section-desc {
    font-size: 14px;
    line-height: 17px;
    text-align: center;
  }
  .find-mentor-section-four_container {
    padding: 50px 0px 20px 0px;
  }
  .find-mentor-section-four-mobile .industry-card-item-header-mobile {
    position: relative;
    width: 467px;
    height: 69px;
    background: #eaeaea;
    border-radius: 10px;
    cursor: pointer;
    margin: 0 auto;
    margin-bottom: 15px;
    transition: 0.3s ease-in;
    color: #777777;
  }
  .find-mentor-section-four-mobile .industry-card-container-mobile {
    margin-top: 40px;
  }
  .find-mentor-section-four-mobile .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    width: 90%;
    height: 69px;
  }
  .find-mentor-section-four-mobile .head h3 {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }
  .find-mentor-section-four-mobile .comingSoon {
    color: black;
  }
  .find-mentor-section-four-mobile .head-active .head p {
    font-size: 14px;

    color: white;
  }
  .find-mentor-section-four-mobile .head-active .head .select {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #2c205b;
    width: 80px;
    height: 47px;
    border-radius: 10px;
  }
  .find-mentor-section-four-mobile .arrow {
    display: inline-block;
    margin-right: 10px;
    transform: rotate(0);
    transition: 0.4s cubic-bezier(0.43, -0.85, 0.41, 1.84);
  }
  .find-mentor-section-four-mobile .arrow-active {
    transform: rotate(90deg);
    color: white;
  }
  .find-mentor-section-four-mobile .head-active {
    color: white;
    background: #5555db;
    margin-bottom: 110px;
  }
  .find-mentor-section-four-mobile .head-active h3 {
    color: white;
  }

  .find-mentor-section-four-mobile .select {
    display: flex;
    color: black;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0);
    width: 80px;
    height: 47px;
    border-radius: 10px;
    transition: 0.8s cubic-bezier(0.43, -0.85, 0.41, 1.84);
  }
  .find-mentor-section-four-mobile
    .industry-card-item-content-mobile-container {
    opacity: 0;
    padding-top: 0;
    padding-left: 20px;
    padding-right: 10px;
    position: absolute;
    width: 437px;
    height: 0;
    background: #4d4dc6;
    top: 0;
    border-radius: 10px;
    z-index: -1;
    transition: 0.3s ease-in;
    color: white;
  }
  .find-mentor-section-four-mobile
    .industry-card-item-content-mobile-container-active {
    opacity: 1;
    height: 72px;
    padding-top: 90px;
  }

  .find-mentor-section-four-mobile .industry-card-item-content-mobile {
    font-family: "eina";
    margin: 0px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    height: 70px;
  }
  .find-mentor-section-four-mobile .industry-card-item-content-mobile span {
    color: white;
    font-size: 12px;
    margin: 1px 0px;
  }
  .find-mentor-section-four-mobile .disabled-heading,
  .find-mentor-section-four-mobile .disabled-heading .arrow {
    color: #777777;
  }
  .find-mentor-section-four-mobile .disabled-heading .arrow-active {
    color: white;
  }
  /* .find-mentor-section-four-mobile .collapse {
    width: 80%;
    max-width: 470px;
    margin: 0 auto;
    margin-top: 50px;
  }
  .find-mentor-section-four-mobile .panel {
    background-color: #e5e5e5;
    border-radius: 10px;
  }
  .find-mentor-section-four-mobile .panel__label {
    position: relative;
    display: block;
    width: 100%;
    background: none;
    border: none;
    text-align: left;
    padding: 25px 60px 25px 25px;
    transition: color 0.2s linear;
    cursor: pointer;
    height: 69px;
    font-weight: 500;
    font-family: "Neometric Medium";

    font-size: 14px;
    line-height: 17px;
  }

  .find-mentor-section-four-mobile .panel__label:after {
    content: "Select";
    position: absolute;
    right: 10px;
    top: 12%;
    padding: 18px;
    border-radius: 10px;
    background: #cbcbcb;
  }
  .find-mentor-section-four-mobile .panel__label_disabled {
    position: relative;
    display: block;
    width: 100%;
    background: none;
    border: none;
    text-align: left;
    padding: 25px 60px 25px 25px;
    transition: color 0.2s linear;
    cursor: pointer;
    height: 69px;
    font-weight: 500;
    font-family: "Neometric Regular";
    color: #777777;
    font-size: 14px;
    line-height: 17px;
  }

  .find-mentor-section-four-mobile .panel__label_disabled:after {
    content: "Coming Soon";
    position: absolute;
    right: 10px;
    color: black;
    top: 12%;
    padding: 18px;
    font-size: 14px;
  }
  .find-mentor-section-four-mobile .panel[aria-expanded="true"] {
    background: #4d4dc6;
  }
  .find-mentor-section-four-mobile
    .panel[aria-expanded="true"]
    .panel__content {
    opacity: 1;
  }

  .find-mentor-section-four-mobile .panel[aria-expanded="true"] .panel__label {
    background: #5555db;
    color: #ffffff;

    border-radius: 10px;
  }

  .find-mentor-section-four-mobile
    .panel[aria-expanded="true"]
    .panel__label:after {
    transform: rotate(0deg);
    margin-bottom: 20px;
    border-radius: 10px;

    background: #2c205b;
  }
  .find-mentor-section-four-mobile .panel__inner {
    border-radius: 0px 0px 10px 10px;
    overflow: hidden;
    will-change: height;
    transition: height 0.4s;
  }

  .find-mentor-section-four-mobile .panel__content {
    margin: 10px 0px;
    font-size: 14px;
    color: white;
    opacity: 0;
    padding-top: 10px;
    font-family: "eina";
    height: 70px;
    width: 80%;
    margin: 0 auto;
  }
  .find-mentor-section-four-mobile .panel__content .panel_items_container {
    margin: 0px auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;

    height: 55px;
  }
  .find-mentor-section-four-mobile .panel__content .panel_items_container span {
    font-family: "eina";
    flex: 0 0 10px;
    font-size: 14px;
    line-height: 16px;
    width: 50%;
    color: #ffffff;
  }
  .find-mentor-section-four-mobile .panel:not(:last-child) {
    margin-bottom: 20px;
  } */
  .find-mentor-section-five-desktop {
    display: none;
  }
  .find-mentor-section-five {
    height: 100%;
    padding-bottom: 50px;
  }
  .find-mentor-section-five-mobile {
    display: block;
  }
  .find-mentor-section-five-mobile .section-five-heading {
    font-weight: 500;
    line-height: 29px;
    text-align: center;
  }
  .find-mentor-section-five-mobile .section-five-desc {
    font-size: 14px;
    line-height: 17px;
    text-align: center;
  }
  .find-mentor-section-five-mobile .collapse {
    width: 80%;
    max-width: 470px;
    margin: 0 auto;
    margin-top: 50px;
    display: block;
  }
  .find-mentor-section-five-mobile .panel {
    background-color: #eeeefb;
    border-radius: 10px;
    border: 2px solid #5555db;
    box-sizing: border-box;
  }
  .find-mentor-section-five-mobile .panel__label {
    position: relative;
    display: block;
    width: 100%;
    background: none;
    border: none;
    text-align: left;
    padding: 25px 60px 25px 25px;
    transition: color 0.2s linear;
    cursor: pointer;
    height: 69px;
    color: #5555db;
    font-family: "Neometric Medium";
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }

  .find-mentor-section-five-mobile .panel__label:after {
    content: "";
    position: absolute;
    right: 10px;
    font-size: 25px;
    top: 15%;
    padding: 25px;
    border-radius: 100%;
    background: #ccccf4;
  }
  .find-mentor-section-five-mobile .panel[aria-expanded="true"] {
    background: #4d4dc6;
  }
  .find-mentor-section-five-mobile
    .panel[aria-expanded="true"]
    .panel__content {
    opacity: 1;
  }

  .find-mentor-section-five-mobile .panel[aria-expanded="true"] .panel__label {
    background: #5555db;
    color: #ffffff;
    border-radius: 10px;
  }

  .find-mentor-section-five-mobile
    .panel[aria-expanded="true"]
    .panel__label:after {
    transform: rotate(0deg);
    margin-bottom: 20px;
    border-radius: 100%;
    font-family: "Font Awesome 5 Free";

    content: "\f00c";

    font-weight: 900;

    background: #2c205b;
    position: absolute;
    right: 10px;
    font-size: 18px;
    top: 15%;
    padding: 17px;
    border-radius: 100%;
  }
  .find-mentor-section-five-mobile .panel__inner {
    border-radius: 0px 0px 10px 10px;
    overflow: hidden;
    will-change: height;
    transition: height 0.4s;
  }

  .find-mentor-section-five-mobile .panel__content {
    margin: 10px 0px;
    font-size: 14px;
    color: white;
    opacity: 0;
    font-family: "eina";

    padding-top: 20px;
    height: 70px;
    width: 80%;
    margin: 0 auto;
  }

  .find-mentor-section-five-mobile .panel:not(:last-child) {
    margin-bottom: 20px;
  }
  .find-mentor-section-six-desktop {
    display: none;
  }
  .find-mentor-section-six-mobile {
    display: block;
  }
  .find-mentor-section-six-mobile .mentor-container {
    width: 92% !important;
  }
  .find-mentor-section-six-mobile .mentor-container div:first-child {
    padding-bottom: 20px;
    height: 650px !important;
  }
}
/* Find Mentor Mobile Starts Here */
@media screen and (max-width: 600px) {
  .find-mentor-section-two-body .find-mentor-section-two-body-desktop {
    display: none;
  }

  .find-mentor-section-two-body .find-mentor-section-two-body-mobile {
    display: block;
    padding-top: 50px;
  }
  .find-mentor-section-two-body-mobile .card-mobile {
    width: 400px;
    height: 120px;
    padding: 30px 0px 40px 0px;
    background: #ffffff;
    margin: 0;
    margin-right: 20px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 10px;
  }
  .find-mentor-section-two-body-mobile .card-mobile .card-icon-mobile {
    background-color: #fac4dc;
    border-radius: 10px;
    width: 54px;
    height: 54px;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 30px;
  }
  .find-mentor-section-two-body-mobile .card-mobile .card-heading-mobile {
    font-family: "Neometric Medium";
    width: 300px;
    margin-top: 0;
    margin-left: 10px;
    padding-left: 5px;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
  }
  .find-mentor-section-two-body-mobile .card-mobile .card-desc-mobile {
    margin-top: 20px;
    width: 320px;
    font-size: 14px;
    line-height: 16px;
    margin-left: 30px;
  }
}
@media screen and (max-width: 480px) {
  .find-mentor-header {
    height: 100%;
  }
  /* Find Mentor Mobile Header */
  .find-mentor-header_container {
    width: 100%;
    padding-top: 130px;
  }

  .find-mentor-header-row {
    flex-direction: column-reverse;
    padding-top: 0;
  }
  .find-mentor-header-leftCol .apply_now_btn {
    margin: 0 auto;
  }
  .find-mentor-header-rightCol .find-mentor-header-desktop-img {
    display: none;
  }
  .find-mentor-header-rightCol .find-mentor-header-mobile-img {
    display: block;
    width: 100%;
  }
  .find-mentor-header-rightCol {
    margin: 0px !important;
    width: 100%;
  }
  .find-mentor-header {
    background-image: url("../assets/Mask Group-5.png");
    background-size: cover;

    background-position: center center;
  }
  .find-mentor-header-leftCol {
    text-align: center;
    padding: 40px 0px 0px 0px !important;
    margin: 0 auto !important;
  }
  .find-mentor-header-leftCol-container {
    width: 100% !important;
  }
  .find-mentor-header-leftCol .find-your-mentor-text {
    font-size: 14px;
    line-height: 105%;
    /* or 15px */
    text-align: center;

    color: #ffffff;

    margin-bottom: 20px;
  }
  .find-mentor-header-leftCol .get-tailored-text {
    font-weight: 600;
    line-height: 105%;
    /* or 28px */
    text-align: center;

    color: #ffffff;
    margin-bottom: 20px;
  }
  .find-mentor-header-leftCol .easily-access-text {
    font-size: 14px;
    line-height: 16px;
    text-align: center;

    color: #ffffff;
    margin-bottom: 20px;
  }
  .find-mentor-header-leftCol .brands-container {
    width: 314px !important;
    margin: 0 auto;
    margin-top: 50px;

    text-align: start;
  }
  .find-mentor-header-leftCol .brands {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }
  .find-mentor-header-leftCol .brands div {
    flex: 0 0 30%;
    margin-top: 10px;
  }
  .find-mentor-header-leftCol .brands div img {
    max-width: 100%;
  }
  /* Find Mentor Section One Starts Here */
  .find-mentor-section-one {
    height: 100%;
  }
  .find-mentor-section-one-row {
    display: flex;
    flex-direction: column-reverse;
  }
  .find-mentor-section-one-row .find-mentor-section-one-rightCol {
    padding: 0;
  }
  .find-mentor-section-one-rightCol img {
    width: 100%;
    padding: 0;
  }
  .find-mentor-section-one-leftCol .strike-heading {
    font-family: "eina";

    margin-top: 20px;
    text-align: center;
  }
  .find-mentor-section-one-leftCol .strike-heading:before {
    content: "";
  }
  .find-mentor-section-one-leftCol .heading {
    font-weight: 500;
    line-height: 29px;
    text-align: center;
  }
  .find-mentor-section-one-leftCol .desc {
    line-height: 19.5px;
    text-align: center;
  }
  .find-mentor-section-one-leftCol .examples-container {
    flex-wrap: wrap;
    justify-content: space-between;
    width: 249px;
  }
  .example-item {
    width: 40% !important;
    margin: 2% 0px !important;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .example-pill-container {
    width: 48% !important;
  }
  /* Find Mentor Section One Ends Here */

  /* Find Mentor Section Two Starts Here */

  .find-mentor-section-two {
    height: 100%;
    background: #f5f7f9;
  }
  .find-mentor-section-two_container {
    padding: 45px 0px 0px 0px;
  }
  .find-mentor-section-two_container .heading {
    font-weight: 500;
    line-height: 29px;
    text-align: center;

    color: #0d0a1b;
  }
  .find-mentor-section-two-body-desktop {
    display: none;
  }
  .find-mentor-section-two-body-mobile {
    display: block;
    margin-top: 30px;
    padding: 0px;
  }

  .find-mentor-section-two-body-mobile .slick-slider {
    margin-left: 20px;
  }

  .find-mentor-section-two-body-mobile .slick-slide {
    width: 295px !important;
  }
  /* Home Section 2 mobile css */

  .find-mentor-section-two-body-mobile .card-mobile {
    width: 281px;
    height: 165px;
    padding: 0px;
    background: #ffffff;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 10px;
  }
  .find-mentor-section-two-body-mobile .card-mobile .card-icon-mobile {
    background-color: #fac4dc;
    border-radius: 10px;
    width: 54px;
    height: 54px;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 15px;
  }
  .find-mentor-section-two-body-mobile .card-mobile .card-heading-mobile {
    font-family: "Neometric Medium";
    width: 150px;
    margin-top: 0;
    padding-left: 5px;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
  }
  .find-mentor-section-two-body-mobile .card-mobile .card-desc-mobile {
    margin-top: 20px;
    width: 240px;
    font-size: 14px;
    line-height: 16px;
    margin-left: 20px;
  }
  /* Find Mentor Section Two Ends Here */
  .find-mentor-section-three {
    height: 100%;
    padding-bottom: 20px;
  }
  .find-mentor-section-three-body {
    margin-top: 25px;
  }
  .find-mentor-section-three_container .heading {
    font-weight: 500;
    line-height: 29px;
    text-align: center;
  }
  .find-mentor-section-three-body .steps-container {
    flex-direction: column;
  }
  .find-mentor-section-four-desktop {
    display: none;
  }
  .find-mentor-section-four {
    height: 100%;
  }
  .find-mentor-section-four-mobile {
    display: block;
  }
  .find-mentor-section-four-mobile .section-heading {
    font-weight: 500;
    line-height: 29px;
    text-align: center;
  }
  .find-mentor-section-four-mobile .section-desc {
    font-size: 14px;
    line-height: 17px;
    text-align: center;
  }
  .find-mentor-section-four_container {
    padding: 50px 0px 20px 0px;
  }
  .find-mentor-section-four-mobile .industry-card-item-header-mobile {
    position: relative;
    width: 322px;
    height: 69px;
    background: #eaeaea;
    border-radius: 10px;
    cursor: pointer;
    margin: 0 auto;
    margin-bottom: 15px;
    transition: 0.3s ease-in;
    color: #777777;
  }
  .find-mentor-section-four-mobile .industry-card-container-mobile {
    margin-top: 40px;
  }
  .find-mentor-section-four-mobile .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    width: 90%;
    height: 69px;
  }
  .find-mentor-section-four-mobile .head h3 {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }
  .find-mentor-section-four-mobile .comingSoon {
    color: black;
  }
  .find-mentor-section-four-mobile .head-active .head p {
    font-size: 14px;

    color: white;
  }
  .find-mentor-section-four-mobile .head-active .head .select {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #2c205b;
    width: 80px;
    height: 47px;
    border-radius: 10px;
  }
  .find-mentor-section-four-mobile .arrow {
    display: inline-block;
    margin-right: 10px;
    transform: rotate(0);
    transition: 0.4s cubic-bezier(0.43, -0.85, 0.41, 1.84);
  }
  .find-mentor-section-four-mobile .arrow-active {
    transform: rotate(90deg);
    color: white;
  }
  .find-mentor-section-four-mobile .head-active {
    color: white;
    background: #5555db;
    margin-bottom: 110px;
  }
  .find-mentor-section-four-mobile .head-active h3 {
    color: white;
  }

  .find-mentor-section-four-mobile .select {
    display: flex;
    color: black;
    justify-content: center;
    align-items: center;
    background: #cfcfcf;
    width: 80px;
    height: 47px;
    border-radius: 10px;
    transition: 0.3s ease-in;
  }
  .find-mentor-section-four-mobile
    .industry-card-item-content-mobile-container {
    opacity: 0;
    padding-top: 0;
    padding-left: 20px;
    padding-right: 10px;
    position: absolute;
    width: 292px;
    height: 0;
    background: #4d4dc6;
    top: 0;
    border-radius: 10px;
    z-index: -1;
    transition: 0.3s ease-in;
    color: white;
  }
  .find-mentor-section-four-mobile
    .industry-card-item-content-mobile-container-active {
    opacity: 1;
    height: 72px;
    padding-top: 90px;
  }

  .find-mentor-section-four-mobile .industry-card-item-content-mobile {
    font-family: "eina";
    margin: 0;
    width: 292px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    height: 70px;
  }
  .find-mentor-section-four-mobile .industry-card-item-content-mobile span {
    color: white;
    width: 50%;
    font-size: 12px !important;
    margin: 1px 0px;
  }
  .find-mentor-section-four-mobile .disabled-heading,
  .find-mentor-section-four-mobile .disabled-heading .arrow {
    color: #777777;
  }
  .find-mentor-section-four-mobile .disabled-heading .arrow-active {
    color: white;
  }

  /* .find-mentor-section-four-mobile .accordion {
    width: 348px;
  }
  .find-mentor-section-four-mobile .arrow-wrapper {
    margin-left: 0px;
  }

  .find-mentor-section-four-mobile .industry-card-container-mobile {
    display: flex;
    flex-direction: column;
    width: 326px;
    margin: 0 auto;
  }
  .find-mentor-section-four-mobile .industry-card-item-mobile {
    margin: 10px 0px;
    border-radius: 10px;
    position: relative;
    cursor: pointer;
  }
  .find-mentor-section-four-mobile .industry-card-item-heading-mobile {
    background-color: #e5e5e5;
    border-radius: 10px;
    font-family: "Neometric Medium";
    position: relative;
    border: none;
    height: 69px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    margin-right: 10px;
    padding-left: 20px;
  }
  .find-mentor-section-four-mobile .industry-card-item-heading-mobile-active {
    background: #5555db;
  }
  .find-mentor-section-four-mobile
    .industry-card-item-heading-mobile-active-text {
    color: white;
  }
  .find-mentor-section-four-mobile
    .industry-card-item-heading-mobile
    .select-btn {
    position: absolute;
    right: 10px;
    top: 12%;
    padding: 18px;
    border-radius: 10px;
    background: #cbcbcb;
    border: 0;
  }
  .find-mentor-section-four-mobile
    .industry-card-item-heading-mobile
    .select-btn-active {
    color: white;
    background: #2c205b;
  }
  .find-mentor-section-four-mobile .industry-card-item-content-mobile {
    font-family: "eina";
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    height: 55px;
    margin-top: 25px;
  }
  .find-mentor-section-four-mobile .industry-card-item-content-mobile span {
    color: white;
  }

  .find-mentor-section-four-mobile .content-open {
    height: 100%;
    background: #4d4dc6;
    color: white;
    width: 338px;
    margin: 0;
    padding: 0;
    margin-left: 10px;
    border-radius: 10px;
    top: -20px;
  } */
  /* .find-mentor-section-four-mobile .collapse {
    width: 326px;
    max-width: 470px;
    margin: 0 auto;
    margin-top: 50px;
  }
  .find-mentor-section-four-mobile .panel {
    background-color: #e5e5e5;
    border-radius: 10px;
  }
  .find-mentor-section-four-mobile .panel__label {
    font-family: "Neometric Medium";
    position: relative;
    display: block;
    width: 100%;
    background: none;
    border: none;
    text-align: left;
    padding: 25px 60px 25px 25px;
    transition: color 0.2s linear;
    cursor: pointer;
    height: 69px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }

  .find-mentor-section-four-mobile .panel__label:after {
    content: "Select";
    position: absolute;
    right: 10px;
    top: 12%;
    padding: 18px;
    border-radius: 10px;
    background: #cbcbcb;
  }
  .find-mentor-section-four-mobile .panel[aria-expanded="true"] {
    background: #4d4dc6;
  }
  .find-mentor-section-four-mobile
    .panel[aria-expanded="true"]
    .panel__content {
    opacity: 1;
  }

  .find-mentor-section-four-mobile .panel[aria-expanded="true"] .panel__label {
    background: #5555db;
    color: #ffffff;

    border-radius: 10px;
  }

  .find-mentor-section-four-mobile
    .panel[aria-expanded="true"]
    .panel__label:after {
    transform: rotate(0deg);
    margin-bottom: 20px;
    border-radius: 10px;

    background: #2c205b;
  }
  .find-mentor-section-four-mobile .panel__inner {
    border-radius: 0px 0px 10px 10px;
    overflow: hidden;
    will-change: height;
    transition: height 0.4s;
  }

  .find-mentor-section-four-mobile .panel__content {
    margin: 10px 0px;
    font-size: 14px;
    color: white;
    opacity: 0;
    padding-top: 10px;
    font-family: "eina";

    height: 70px;
    width: 80%;
    margin: 0 auto;
  }
  .find-mentor-section-four-mobile .panel__content .panel_items_container {
    margin: 0px auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;

    height: 55px;
  }
  .find-mentor-section-four-mobile .panel__content .panel_items_container span {
    flex: 0 0 10px;
    font-size: 14px;
    line-height: 16px;
    width: 50%;
    color: #ffffff;
  }
  .find-mentor-section-four-mobile .panel:not(:last-child) {
    margin-bottom: 20px;
  } */
  .find-mentor-section-five-desktop {
    display: none;
  }
  .find-mentor-section-five {
    height: 100%;
    padding-bottom: 50px;
  }
  .find-mentor-section-five-mobile {
    display: block;
  }
  .find-mentor-section-five-mobile .section-five-heading {
    font-weight: 500;
    line-height: 29px;
    text-align: center;
    margin-bottom: 10px;
  }
  .find-mentor-section-five-mobile .section-five-desc {
    font-size: 14px;
    line-height: 17px;
    text-align: center;
  }
  .find-mentor-section-five-mobile .collapse {
    width: 326px;
    max-width: 470px;
    margin: 0 auto;
    margin-top: 50px;
  }
  .find-mentor-section-five-mobile .panel {
    background-color: #eeeefb;
    border-radius: 10px;
    border: 2px solid #5555db;
    box-sizing: border-box;
  }
  .find-mentor-section-five-mobile .panel__label {
    font-family: "Neometric Medium";
    position: relative;
    display: block;
    width: 100%;
    background: none;
    border: none;
    text-align: left;
    padding: 25px 60px 25px 25px;
    transition: color 0.2s linear;
    cursor: pointer;
    height: 69px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }

  .find-mentor-section-five-mobile .panel__label:after {
    content: "";
    position: absolute;
    right: 10px;
    font-size: 25px;
    top: 15%;
    padding: 25px;
    border-radius: 100%;
    background: #ccccf4;
  }
  .find-mentor-section-five-mobile .panel[aria-expanded="true"] {
    background: #4d4dc6;
  }
  .find-mentor-section-five-mobile
    .panel[aria-expanded="true"]
    .panel__content {
    opacity: 1;
  }

  .find-mentor-section-five-mobile .panel[aria-expanded="true"] .panel__label {
    background: #5555db;
    color: #ffffff;
    border-radius: 10px;
  }

  .find-mentor-section-five-mobile
    .panel[aria-expanded="true"]
    .panel__label:after {
    transform: rotate(0deg);
    margin-bottom: 20px;
    border-radius: 100%;
    font-family: "Font Awesome 5 Free";
    background: #2c205b;
    content: "\f00c";
    font-weight: 900;
    position: absolute;
    right: 10px;
    font-size: 18px;
    top: 15%;
    padding: 17px;
    border-radius: 100%;
  }
  .find-mentor-section-five-mobile .panel__inner {
    border-radius: 0px 0px 10px 10px;
    overflow: hidden;
    will-change: height;
    transition: height 0.4s;
  }

  .find-mentor-section-five-mobile .panel__content {
    margin: 10px 0px;
    font-size: 14px;
    font-family: "eina";
    line-height: 19.5px;
    color: white;
    opacity: 0;
    padding-top: 15px;
    height: 70px;
    width: 80%;
    margin: 0 auto;
  }

  .find-mentor-section-five-mobile .panel:not(:last-child) {
    margin-bottom: 20px;
  }
  .find-mentor-section-six_container .find-mentor-section-six-heading {
    font-weight: 500;
    line-height: 29px;
    text-align: center;
  }
  .find-mentor-section-six_container .find-mentor-section-six-desc {
    font-size: 14px;
    line-height: 17px;
    text-align: center;
  }
  .find-mentor-section-six-desktop {
    display: none;
  }
  .find-mentor-section-six-mobile {
    display: block;
  }
  .find-mentor-section-seven {
    height: 100%;
    padding-top: 10px;
  }
  .find-mentor-section-seven-leftCol {
    padding-top: 0px;
  }
  .find-mentor-section-seven-leftCol img {
    padding-top: 0px;
    width: 100%;
  }
  .find-mentor-section-seven-row {
    margin-top: 0px;
    display: flex;
    flex-direction: column;
  }
  .find-mentor-section-seven-rightCol {
    width: 320px;
    margin: 0 auto !important;
    padding-top: 40px;
  }
  .find-mentor-section-seven-rightCol .section-five-heading {
    font-weight: 500;
    line-height: 106.5%;
  }
  .find-mentor-section-six-mobile .mentor-container div:first-child {
    padding-bottom: 20px;
    height: 434px !important;
  }
  .apply-now-container {
    text-align: center;
  }
  .contact-for-help-text {
    font-size: 14px;
    line-height: 106.5%;
    text-align: center;
  }
  .find-mentor-section-seven .title {
    font-weight: 500;
    font-size: 14px;
    line-height: 106.5%;
  }
  .find-mentor-section-seven .title-text {
    font-size: 14px;
  }
  .find-mentor-section-seven .contact-for-help-text {
    line-height: 106.5%;
    /* or 15px */
    text-align: center;
  }
  .find-mentor-section-seven .content {
    height: fit-content;
  }
  .find-mentor-section-seven .content-text {
    overflow: hidden;
    font-weight: normal;
    font-size: 14px;
    line-height: 100%;
    text-align: start !important;
    /* or 15px */
  }
  .find-mentor-section-seven .content-open {
    margin-bottom: 20px;
    margin-top: 5px;
  }
}

/* Find Mentor Mobile Ends Here */
