/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

/*init*/

.home-header {
  width: 100%;
  height: 120vh;
  max-height: 760px;
}
.home-header_container,
.home-header_row {
  height: 100%;
  padding-top: 150px;
}
.home-header_row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.home-header_leftCol,
.home-header_rightCol {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
  width: 50%;
}
.home-header_rightCol img {
  max-width: 100%;
  min-width: 682px;
}
.home-header_leftCol .desktop-heading {
  font-weight: 600;
  line-height: 117.02%;
  margin-bottom: 25px;
}

.home-header_leftCol .desktop-content {
  width: 80%;
  line-height: 21px;
}

.find-mentor-btn,
.become-mentor-btn {
  cursor: pointer;
  border-radius: 7.55556px;
  margin: 30px 15px 30px 0px;
  width: 155px;
  height: 45px;
  border: 0px;
  font-weight: 700;
  font-family: "Mulish", sans-serif;
}
.btn-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.find-mentor-btn {
  text-decoration: none;

  color: black;
  background-color: #fac4dc;
}
.become-mentor-btn {
  color: white;
  background-color: #6565fc;
}
.homebtn-pink {
  font-size: 13px;
}
.home-header_leftCol .strike-text {
  font-size: 9px;
  font-family: "eina";
  line-height: 117.02%;
  /* identical to box height, or 11px */
  letter-spacing: 0.1em;
}
.home-header_leftCol .strike-text:before {
  content: "\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0";
  text-decoration: line-through;
  margin-right: 1rem;
}

.home-header_leftCol .brands {
  width: 380px;
  margin-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.home-header_leftCol .brands div {
  margin-bottom: 20px;
  list-style: none;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33%;
  flex: 0 0 33%;
}

.home-section-one {
  width: 100%;
  height: 100%;
}
.home-section-one-container {
  width: 1440px;
  margin: 0px auto;
  height: 100%;
  padding-bottom: 100px;
}
.home-section-one-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 1200px;
  margin: 0px auto !important;
}
.home-section-one-row-two {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 1150px;

  margin: 0px auto !important;
}
/* .home-section-one-container {
}  */
.home-section-one-row-one-leftCol,
.home-section-one-row-one-rightCol {
  width: 41%;
}
.home-section-one-row-two-leftCol {
  width: 50%;
}
.box-shape {
  position: relative;
  width: 486px;
  height: 359px;
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.home-section-one-row-one-rightCol .strike-text-desktop,
.home-section-one-row-two-leftCol .strike-text-desktop {
  font-family: "eina";
  font-size: 11px;
  line-height: 117.02%;
  /* identical to box height, or 13px */
  letter-spacing: 0.1em;

  color: #4d4dc6;
}
.home-section-one-row-one-rightCol .strike-text-desktop:before,
.home-section-one-row-two-leftCol .strike-text-desktop:before {
  content: "\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0";
  text-decoration: line-through;
  margin-right: 1rem;
}

.home-section-one-row-one-rightCol .strike-text-mobile,
.home-section-one-row-two-leftCol .strike-text-mobile {
  font-family: "eina";
  display: none;
}
.home-section-one h2 {
  margin-top: 20px;
  font-weight: 500;
  line-height: 43px;
  width: 100%;
}
.home-section-one p {
  width: 100%;
  margin-top: 20px;
  line-height: 21px;
}
.middle-img-section-two {
  opacity: 0;
  -webkit-transition: 0.3s ease-in;
  -o-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
  background-size: 98% 100%;
  background-position: bottom;
  background-repeat: no-repeat;
}
.home-section-two-toggle {
  opacity: 1;
  background-size: 100% 100%;
}

.home-section-two {
  height: 520px;
  background-color: #f5f7f9;
}
.home-section2-mobile {
  overflow: hidden;
  display: none;
}
.home-section-two-container {
  position: relative;
  text-align: center;
  padding: 40px 0px;
  width: 1440px;
  margin: 0 auto;
}
.home-section-two-container .heading {
  font-weight: 500;
  line-height: 43px;
  margin-bottom: 50px;
  text-align: center;
}

.home-section-two-container .bubbles {
  border: 0;
  font-family: "Neometric Medium";
  position: relative;
  border-radius: 32.7808px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  margin: 0px 25px;
  width: 157.35px;
  height: 35.24px;
  cursor: pointer;
  color: black;
}
.home-section-two-container .mentee-bubble-man {
  background: #fac4dc;
}
.home-section-two-container .mentee-bubble-man:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-right: 8px solid transparent;
  border-left: 8px solid #fac4dc;
  border-top: 8px solid #fac4dc;
  border-bottom: 8px solid transparent;
  right: 100px;
  bottom: -15px;
}
.home-section-one-row-one-leftCol,
.home-section-one-row-one-rightCol,
.home-section-one-row-two-leftCol {
  padding: 60px 20px 30px 20px !important;
}
.home-section-two-container .mentee-bubble-girl {
  background: #e1e1e1;
}
.home-section-two-container .mentee-bubble-girl:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-right: 8px solid transparent;
  border-left: 8px solid #e1e1e1;
  border-top: 8px solid #e1e1e1;
  border-bottom: 8px solid transparent;
  right: 100px;
  bottom: -15px;
}
.home-section-two-container .mentor-bubble-man {
  background: #e1e1e1;
}
.home-section-two-container .mentor-bubble-man:before {
  content: "";

  position: absolute;
  border-left: 8px solid transparent;
  border-right: 8px solid #e1e1e1;
  border-top: 8px solid #e1e1e1;
  border-bottom: 8px solid transparent;
  right: 15px;
  bottom: -15px;
}
.home-section-two-container .mentor-bubble-girl {
  background: #5555db;
  color: white;
}
.home-section-two-container .mentor-bubble-girl:before {
  content: "";

  position: absolute;
  border-left: 8px solid transparent;
  border-right: 8px solid #5555db;
  border-top: 8px solid #5555db;
  border-bottom: 8px solid transparent;
  right: 15px;
  bottom: -15px;
}
.home-section-two-body .info-container {
  position: relative;

  margin-bottom: 30px;
}
.home-section-two-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.rectangle-left {
  opacity: 0;
  -webkit-transform: translateX(-50px);
  -ms-transform: translateX(-50px);
  transform: translateX(-50px);
  -webkit-transition: 0.3s cubic-bezier(0.47, 0, 0.745, 0.715);
  -o-transition: 0.3s cubic-bezier(0.47, 0, 0.745, 0.715);
  transition: 0.3s cubic-bezier(0.47, 0, 0.745, 0.715);
}
.rectangle-right {
  opacity: 0;
  -webkit-transform: translateX(50px);
  -ms-transform: translateX(50px);
  transform: translateX(50px);
  -webkit-transition: 0.3s cubic-bezier(0.47, 0, 0.745, 0.715);
  -o-transition: 0.3s cubic-bezier(0.47, 0, 0.745, 0.715);
  transition: 0.3s cubic-bezier(0.47, 0, 0.745, 0.715);
}
.rectangle-active {
  opacity: 1;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}
.home-section-two-body .rectangle-container {
  position: absolute;
  -webkit-box-shadow: 0px 3.27807px 12.2928px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 3.27807px 12.2928px rgba(0, 0, 0, 0.1);
  border-radius: 8.19519px;
  text-align: left !important;
  width: 249px;
  height: 103px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: white;
}
.rectangle-container .content-heading {
  font-family: "Neometric Medium";
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 10px;
  margin-left: 15px;
  margin-top: 15px;
  line-height: 14px;
}
.rectangle-container .content-text {
  visibility: visible;
  opacity: 1;
  padding: 0px;
  width: 85%;
  height: 100%;
  margin-left: 15px;
  font-size: 12px;
  font-family: "eina";
  line-height: 14px;
  overflow-y: hidden;
  /* color: black !important; */
}
.home-section-two-body .leftOne {
  left: 21%;
}

.home-section-two-body .rightOne {
  left: 57%;
}
.home-section-two-body .leftTwo {
  left: 24%;
}
.home-section-two-body .rightTwo {
  left: 60%;
}

.home-section-three {
  height: 501px;
  background-color: #5555db;
}
.home-section-three-container {
  text-align: center;

  padding: 50px 0px;
}
.home-section-three-container .heading {
  font-weight: 500;
  line-height: 43px;
  text-align: center;

  color: #ffffff;
}
.home-section-three-body .card-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-pack: space-around;
  -ms-flex-pack: space-around;
  justify-content: space-around;
  width: 950px;
  margin: 0 auto;
}
.home-section-three-body .card-container .card {
  background: #6666df;
  border-radius: 20px;
  padding: 0;
  text-align: center;
  position: relative;
  width: 222px;
  height: 245px;
  margin: 0 4%;
  z-index: 1;
}
.card-inner {
  position: absolute;
  left: 50%;
  margin: auto 0 auto 0;
  top: 35px;
  width: 100%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.middle_line {
  position: absolute;
  left: 50%;
  top: 50%;
  background-color: #8282ff;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  height: 7px;
  width: 700px;
}

.home-section-three-body .card-container .card .icon-container {
  background-color: white;
  width: 44px;
  height: 44px;
  border-radius: 10px;
  margin: 0 auto;
  position: relative;
}
.home-section-three-body .card-container .card .icon-container img {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.home-section-three-body .card-container .card .card-heading {
  margin-top: 20px;
  font-weight: 600;
  font-family: "Neometric Bold";
  font-size: 15px;
  line-height: 17px;
  color: white;
}
.home-section-three-body .card-container .card .card-content {
  font-family: "Neometric Regular";
  margin-top: 30px;
  font-size: 14px;
  line-height: 17px;

  color: #ffffff;
}
.home-section-four {
  height: 100%;
  padding-bottom: 100px;
}
.home-section-four-container {
  padding: 70px 0px;
}
.home-section-four-container .heading {
  font-weight: 500;
  line-height: 43px;
  text-align: center;
  color: #0d0a1b;
}

.home-section-four-body {
  margin-top: 50px;
  text-align: center;
}
.mentors-container {
  display: "flex";
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: "100px";
}
.mentor-card {
  height: "502.7px";
  width: "332.6px";
  border-radius: "16.798px";
  margin: "0px 20px";
  position: "relative";
}

.home-section-five {
  height: 100%;
  padding-bottom: 350px;
}
.home-section-five-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.home-section-five-leftCol {
  width: 35%;
}
.home-section-five-body .home-section-five-rightCol {
  padding: 10px 10px 10px 100px;
}
.home-section-five-body .home-section-five-rightCol .desktop-heading {
  line-height: 117.02%;
  /* identical to box height, or 13px */
  font-family: "eina";
  letter-spacing: 0.1em;
  font-size: 11px;
  color: #4d4dc6;
}
.home-section-five-body .home-section-five-rightCol .desktop-heading:before {
  content: "\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0";
  text-decoration: line-through;
  margin-right: 1rem;
}
.home-section-five-body .home-section-five-rightCol .heading {
  margin-top: 10px;
  font-weight: 600;
  line-height: 52px;
}
.home-section-five-item {
  margin-top: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.home-section-five-item div:last-child {
  width: 80%;
}
.home-section-five-item .item-heading {
  font-family: "Neometric Bold";
  font-weight: 600;
  line-height: 29px;
  margin-bottom: 10px;
}
.home-section-five-item .item-desc {
  font-size: 14px;
  line-height: 16px;
  font-family: "eina";
  color: #000000;
}
.item-image-container {
  width: 69px;
  height: 69px;
  position: relative;
  margin-right: 20px;
  background: #fac4dc;
  -webkit-box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.03);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  float: left;
}
.item-image-container img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.home-section-five-rightCol .btn {
  font-family: "Mulish";
  width: 205px;
  height: 45px;
  background: #5555db;
  border-radius: 10px;
  border: 0px;
  color: white;
  font-weight: 700;

  cursor: pointer;
}
.home-section-five-rightCol .mobile-heading {
  display: none;
}
.home-section-four-body .btn-group-section-four {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: center;
}
.home-section-four-body .btn-group-item {
  width: 140.8px;
  height: 44px;
  background-color: #f1f1f1;
  color: #c1c1c1;
  font-family: "Neometric Medium";
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
  text-align: center;

  border: none;
  cursor: pointer;
  border-radius: 0%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-section-four-body .active-btn-group-item {
  background-color: #5555db;
  border-radius: 0%;
  color: white;
  outline: #5555db;
}
.home-section-four-body .soon {
  font-size: 8px;
  line-height: 10px;
  text-align: center;

  color: #363636;
  opacity: 0.6;
  position: absolute;
  top: 2px;
  right: 5px;
}
.home-section-four-body .soon .active-btn-group-item {
  color: white;
}

.support-our-mission {
  position: absolute;
  background: #2c205b;
  -webkit-box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
  border-radius: 40px;
  padding: 70px 60px;
  width: 847px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.mentor-mobile {
  display: none;
}
.suport-out-mission-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.suport-out-mission-container .heading {
  font-weight: 600;
  line-height: 29px;
  font-size: 24px;
  margin-bottom: 20px;
  color: #ffffff;
}
.suport-out-mission-container .desc {
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  width: 80%;
  color: #ffffff;
}
.suport-out-mission-container .donate-btn {
  background: #5555db;
  -webkit-box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
  border: 0;
  padding: 0px 50px;
  color: white;
  margin: 0;
  border-radius: 10px;
  width: 145px;
  height: 45px;
  font-family: "Mulish";
  font-weight: 600;

  cursor: pointer;
}
.home-section-five-body .arrow-img-container {
  position: absolute;
  z-index: -1;
  top: 0px;
  left: 60px;
}
.homeSection5mobile {
  display: none;
}

.home-section-five-item-mobile {
  display: none;
}
@media screen and (max-width: 1280px) {
  .home-header {
    height: 80vh;

    min-height: 700px;
  }
  .home-header_row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .home-section-two-container {
    width: 100%;
    max-width: 950px;
  }
  .home-header_leftCol,
  .home-header_rightCol {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
    width: 50%;
  }
  .home-header_rightCol img {
    max-width: 100%;
  }
  .home-section-one-container {
    width: 100%;
    margin: 0px auto;
  }
  .home-section-one-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;

    margin: 0px auto;
  }

  .home-section-one-row-two {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    margin: 0px auto !important;
  }
  /* .home-section-one-container {
  }  */
  .home-section-one-row-one-leftCol,
  .home-section-one-row-one-rightCol,
  .home-section-one-row-two-rightCol,
  .home-section-one-row-two-leftCol {
    width: 40%;
  }

  .box-shape {
    width: 416px;
    height: 299px;
  }
  .home-section-two-body .leftOne {
    left: 7%;
  }

  .home-section-two-body .rightOne {
    left: 62%;
  }
  .home-section-two-body .leftTwo {
    left: 10%;
  }
  .home-section-two-body .rightTwo {
    left: 65%;
  }

  .home-section-five-container {
    width: 100%;
  }
  .home-section-five-item div:last-child {
    width: 70%;
  }
}

@media screen and (max-width: 1023px) {
  .home-header {
    height: 0;
    min-height: 1120px;
    max-height: 1300px;
  }
  .home-header_row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    padding-top: 100px;
  }

  .home-header_rightCol img {
    width: 80%;
  }
  .home-header_leftCol,
  .home-header_rightCol {
    padding-bottom: 0px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
  .home-section-one-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    -webkit-box-align: center;

    -ms-flex-align: center;

    align-items: center;
    width: 100%;
    margin: 0px auto;
  }

  .home-section-one-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;

    margin: 0px auto;
  }

  .home-section-one-row-two {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    margin: 0px auto !important;
  }

  .home-section-one-row-one-leftCol,
  .home-section-one-row-one-rightCol,
  .home-section-one-row-two-rightCol .home-section-one-row-two-leftCol {
    width: 40%;
  }
  .box-shape {
    width: 340px;
    height: 289px;
  }

  .home-section-two-body .leftOne {
    left: 0px;
  }
  .home-section-two-body .rightOne {
    left: 62%;
  }
  .home-section-two-body .leftTwo {
    left: 10px;
  }
  .home-section-two-body .rightTwo {
    left: 68%;
  }
  .home-section-three {
    height: 100%;
    padding-bottom: 50px;
  }
  .home-section-three-container .heading {
    font-weight: 500;
    line-height: 29px;
    text-align: center;
  }
  .home-section-three-body .card-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    width: 100%;
  }
  .home-section-three-body .card-container .card {
    z-index: 1;

    background: #6666df;
    border-radius: 20px;
    padding: 50px 60px;
    text-align: center;
    width: 160px;
    height: 200px;
    margin: 4% 2.5%;
  }
  .middle_line {
    position: absolute;
    left: 51%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    height: 790px;
    width: 7px;
  }
  .home-section-five-leftCol {
    width: 100%;
  }
  .support-our-mission {
    position: absolute;
    background: #2c205b;
    -webkit-box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    border-radius: 40px;
    padding: 70px 60px;
    width: 60%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -70%);
    -ms-transform: translate(-50%, -70%);
    transform: translate(-50%, -70%);
  }
}
@media screen and (max-width: 900px) {
  .home-header_row {
    padding-top: 100px;
  }
  .home-header_rightCol img {
    width: 80%;
  }
  .home-section-five-body {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .home-section-five-leftCol {
    width: 100%;
  }
  .home-section-five-leftCol .homeSection5desktop {
    display: none;
  }
  .home-section-five-leftCol .homeSection5mobile {
    display: block;
    width: 100%;
  }
  .home-section-five-leftCol {
    padding-bottom: 100px;
  }
  .home-section-five-rightCol {
    margin-bottom: 200px;
  }

  .support-our-mission .suport-out-mission-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .suport-out-mission-container div {
    padding-bottom: 10px;
  }
}
@media screen and (max-width: 768px) {
  .home-header_rightCol .animated {
    width: 100%;
    text-align: center;
  }
  .home-header {
    min-height: 985px;
  }
  .home-section2-desktop {
    display: none;
  }
  .home-section2-mobile {
    overflow: hidden;
    display: block;
  }
  .home-header_row {
    padding-top: 100px;
  }
  .home-header_rightCol img {
    width: 80%;
  }
  .slider-container {
    margin-top: 50px;
    width: 100% !important;
    padding-left: 90px;
  }
  .home-section-two {
    height: 480px;
  }
  .home-section2-mobile .info-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .home-section-two-container .heading {
    font-weight: 500;
    line-height: 29px;
    /* identical to box height */
    text-align: center;
  }
  .rectangle-container-mobile {
    text-align: start;
    background: #ffffff;
    -webkit-box-shadow: 0px 3.27807px 12.2928px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 3.27807px 12.2928px rgba(0, 0, 0, 0.1);
    border-radius: 8.19519px;
    height: 170px;
    width: 450px !important;
    margin: 0 7px;
    padding: 10px 20px;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
  }
  .rectangle-container-mobile .content-heading {
    font-family: "Neometric Medium";

    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }
  .home-section-two-container .bubbles {
    margin: 0px 8px;
  }
  .rectangle-container-mobile .content-text {
    font-family: "eina";
    text-align: start;
    font-size: 14px;
    line-height: 16px;
    padding: 0px;
    width: 100%;
    visibility: visible;
    opacity: 1;
    /* color: black !important; */
  }
  .slick-track {
    padding: 10px 0;
  }
  .mentor-desktop {
    display: none;
  }
  .mentor-mobile {
    display: block;
  }
  .mentor-mobile .mentor-container {
    width: 92% !important;
  }
  .mentor-mobile .mentor-container div:first-child {
    padding-bottom: 20px;
    height: 650px !important;
  }
  .home-section-one-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;

    margin: 0px auto;
  }

  .home-section-one-row-two {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    /* width: 1000px; */
    margin: 0px auto;
  }
  /* .home-section-one-container {
  }  */
  .home-section-one-row-two-leftCol-container {
    text-align: center;
  }
  .home-section-one-row-one-rightCol,
  .home-section-one-row-two-leftCol,
  .home-section-one-row-one-leftCol,
  .home-section-one-row-two-rightCol {
    width: 80%;
    text-align: center;
  }
  .box-shape {
    margin: 0 auto;
    width: 486px;
    height: 359px;
  }
  .home-section-one-row-one-rightCol-container h2,
  .home-section-one-row-two-leftCol-container h2 {
    width: 80%;
    margin: 0 auto;
  }
  .home-section-one-row-one-rightCol-container p,
  .home-section-one-row-two-leftCol-container p {
    width: 80%;
    margin: 0 auto;
  }
  .home-section-one-row-one-rightCol,
  .home-section-one-row-two-leftCol {
    padding-top: 0px;
  }
}

@media screen and (max-width: 420px) {
  .home-header {
    min-height: 836px;
  }
  .home-header_leftCol,
  .home-header_rightCol {
    width: 96%;
  }
  .home-section-three {
    height: 100%;
    padding-bottom: 50px;
  }
  .home-section-three-container {
    padding: 25px 0px 10px 0px;
  }
  .home-section-three-container .heading {
    font-weight: 500;
    line-height: 29px;
    text-align: center;
  }
  .home-section-three-body .card-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    width: 100%;
  }

  .home-header_leftCol .desktop-content {
    width: 330px;
    margin: 15px 0 15px 5px;
  }
  .home-section-three-body .card-container .card {
    z-index: 1;
    background: #6666df;
    border-radius: 20px;
    padding: 0;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 222px;
    height: 245px;
    margin: 4% 2.5%;
  }
  .home-section2-desktop {
    display: none;
  }
  .home-section2-mobile {
    display: block;
  }

  .slider-container {
    margin-top: 30px;
    width: 100% !important;
    padding-left: 25px;
  }
  .home-section-two {
    height: 375px;
  }
  .home-section-two-container .heading {
    font-weight: 500;
    line-height: 29px;
    /* identical to box height */
    text-align: center;
    margin-bottom: 40px;
  }

  .rectangle-container-mobile {
    background: #ffffff;
    -webkit-box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
    border-radius: 8.19519px;
    height: 121px;
    width: 240px !important;
    margin: 0 7px;
    padding: 10px 20px;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
  }
  .rectangle-container-mobile .content-heading {
    font-family: "Neometric Medium";

    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }
  .home-section-two-container .bubbles {
    margin: 0px 4px;
  }
  .rectangle-container-mobile .content-text {
    font-family: "eina";
    text-align: start;
    font-size: 14px;
    line-height: 16px;
    visibility: visible;
    opacity: 1;
    /* color: black !important; */
  }
  .slick-track {
    padding: 10px 0;
  }
  .middle_line {
    position: absolute;
    left: 52%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    height: 760px;
    width: 7px;
  }
  .home-header {
    height: 100%;
  }
  .home-header_row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    padding-top: 80px;
  }
  .homeMainImage {
    min-width: 70% !important;
    width: 80%;
  }

  .home-header_leftCol .desktop-heading {
    display: block;
    margin-top: 20px;
    margin-left: 5px;
    margin-bottom: 5px;
    font-weight: 600;
    line-height: 117.02%;
  }

  .home-section-one-container {
    padding-bottom: 50px;
  }
  .home-section-one-row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    font-size: 14px;
    line-height: 16px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .home-section-one-row-two {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .home-header_rightCol {
    margin: 0 !important;
  }
  .home-section-one-row-one-rightCol .strike-text-desktop,
  .home-section-one-row-two-leftCol .strike-text-desktop {
    display: none;
  }
  .home-section-one-row-one-rightCol .strike-text-mobile,
  .home-section-one-row-two-leftCol .strike-text-mobile {
    display: block;
    margin-top: 0px;
    margin-bottom: 25px;
    font-size: 9px;
    line-height: 117.02%;
    /* identical to box height, or 11px */
    text-align: center;
    letter-spacing: 0.1em;
    color: #4d4dc6;
  }
  .box-shape {
    width: 324.9px;
    height: 240px;
    margin: 0 auto;
  }
  .find-mentor-btn,
  .become-mentor-btn {
    cursor: pointer;
    border-radius: 7.55556px;
    margin: 15px 5px 30px 5px;
    width: 160px;
    height: 40px;
    border: 0px;
    font-family: "Mulish", sans-serif;
    font-weight: 700;
    /* margin: 0 auto; */
  }
  .home-section-one h2 {
    text-align: center;
    width: 100%;
    font-weight: 500;
    line-height: 29px;
    text-align: center;
  }
  .home-section-one p {
    text-align: center;
    width: 100%;
    margin: 25px auto 10px auto;
    line-height: 20px;
    text-align: center;
  }
  .home-section-one-row-one-rightCol-container,
  .home-section-one-row-two-leftCol-container {
    text-align: center;
  }
  .btn-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .btn-group .btn-group-item {
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
  }

  .home-section-one-row-one-leftCol,
  .home-section-one-row-two-rightCol {
    padding: 0px 0px 30px 0px !important;
    width: 95%;

    margin: 0 auto;
  }
  .home-section-one-row-one-rightCol,
  .home-section-one-row-two-leftCol {
    padding: 0px 0px 30px 0px !important;
    width: 95%;

    margin: 0 auto;
  }
  .home-section-four-container {
    padding: 40px 0px 0px 0px;
  }

  .home-section-four-container .heading {
    font-weight: 500;
    line-height: 29px;
    text-align: center;
  }
  .home-section-four-body {
    margin-top: 30px;
  }
  .btn-group-section-four {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: center;
  }
  .home-section-four-body .btn-group-item {
    width: 110px;

    font-size: 13px;
    line-height: 17px;
    /* identical to box height */
    text-align: center;
    padding: 0;
  }
  .home-section-five-rightCol .btn {
    margin: 0 auto;
  }

  .mentor-desktop {
    display: none;
  }
  .mentor-mobile {
    display: block;
  }
  .mentor-container {
    height: 470px !important;
    width: 95% !important;
  }
  .mentor-mobile .mentor-container div:first-child {
    padding-bottom: 20px;
    height: 380px !important;
  }

  /* section five */
  .home-section-five-body {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .home-section-four {
    height: 100%;
    padding-bottom: 0px;
  }
  .home-section-five {
    height: 100%;
    padding-bottom: 300px;
  }
  .homeSection5desktop {
    display: none;
  }
  .homeSection5mobile {
    display: block;
  }
  .home-section-five-leftCol {
    padding-bottom: 0px;
  }
  .home-section-five-rightCol .desktop-heading {
    display: none;
  }
  .home-section-five-rightCol .mobile-heading {
    display: block;
    font-size: 11px;
    font-family: "eina";
    color: #4d4dc6;
    line-height: 117.02%;
    /* identical to box height, or 13px */
    text-align: center;
    letter-spacing: 0.1em;
    margin-bottom: 20px;
  }
  .home-section-five-rightCol .heading {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
  }
  .item-image-container {
    width: 49px;
    height: 49px;
    padding: 5px;
  }
  .home-section-five-item {
    display: none;
  }
  .home-section-five-item-mobile {
    margin-top: 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .home-section-five-rightCol {
    padding: 20px !important;
    padding-bottom: 70px !important;
    width: 85%;
    margin: 15px auto 0 auto;
  }
  .home-section-five-rightCol .heading {
    font-weight: 600 !important;
    line-height: 29px !important;
    text-align: center !important;
  }

  .home-section-five-rightCol .item-heading {
    font-size: 16px;
    font-family: "Neometric Bold";
    font-weight: 600;
    line-height: 19px;
    width: auto;
  }
  .home-section-five-rightCol .item-desc {
    margin-top: 15px;
    font-size: 16px;
    line-height: 19px;
    width: 306px;
    color: black;
  }
  .learnmore {
    text-align: center;
  }
  .arrow-img-container {
    left: 37% !important;
  }
  .support-our-mission {
    border-radius: 20px;
    padding: 0;
    width: 326px;
    height: 334px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -60%);
    -ms-transform: translate(-50%, -60%);
    transform: translate(-50%, -60%);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .suport-out-mission-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 286px;
  }
  .suport-out-mission-container .desc {
    width: 100%;
  }
  .donate-btn {
    margin-top: 15px !important;
  }
}
