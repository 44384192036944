:root {
  --popup-width: 900px;
}
.onboarding_popup_component {
  font-family: "Neometric Regular";
}

.onboarding_overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
}

.onboarding_popup_settings {
  width: var(--popup-width);
  height: 700px;
  background-color: white;
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  position: relative;
}
.onboarding_close_btn {
  position: absolute;
  top: 5px;
  font-size: 40px;
  right: 15px;
  z-index: 999999;
  cursor: pointer;
}

.onboarding_slider_wrapper {
  height: 100%;
  width: 95%;

  left: 50%;
  transform: translateX(-50%);
  top: 0;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  z-index: 999;
}
.onboarding_arrow {
  cursor: pointer;
}
.onboarding_arrow > div > i {
  width: 25px;
  display: flex;
  justify-content: center;
  color: #5555db;
}
.onboarding_slide {
  min-width: calc(var(--popup-width) - 200px);
  transition: 0.3s;
  padding: 0 100px;
}

.onboarding_slide_title {
  font-size: 26px;
  font-weight: bold;
  font-family: "Neometric Medium";
  padding-bottom: 10px;
  text-align: center;
}

.onboarding_slide_subtitle {
  text-align: center;
  font-size: 18px;

  padding-top: 10px;
}

.onboarding_slide_image img {
  width: 100%;
  height: 370px;
  object-fit: contain;
  object-position: top;
  margin: 40px 0 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 13px -5px rgb(0 0 0 / 20%);
  border-radius: 20px;
}

.onboarding_slider_container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  overflow: hidden;
}

.onboarding_slider_track {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  z-index: 1;
  transition: 0.2s;
  position: relative;
}

.onboarding_checkbox {
  cursor: pointer;
  display: flex;
  justify-content: right;
  position: absolute;
  bottom: 15px;
  padding: 10px 15px;
  color: white;
  border-radius: 20px;
  right: 15px;
  background-color: #5555db;
  width: fit-content;
  transition: 0.2s;
  z-index: 999;
}
.onboarding_checkbox:hover {
  background-color: #3a3ab4;
}

.onboarding_dots {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 15px;
  left: 0;
  right: 0;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.3);
  transition: 0.2s;
  margin: 5px;
}

.dot.active {
  background-color: #5555db;
}

@media screen and (max-height: 800px) {
  .onboarding_popup_settings {
    height: 600px;
  }
  .onboarding_slide_image img {
    margin: 20px 0 10px 0;
  }
  .onboarding_slide_subtitle {
    font-size: 16px;
  }
  .onboarding_checkbox {
    font-size: 14px;
    padding: 8px 13px;
  }
}
