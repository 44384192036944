.admin_dashboard_content_area_title {
  text-transform: capitalize;
  font-family: "Neometric Medium";
  font-size: 24px;
  color: #2c205b;
  line-height: 29px;
}
.admin_dashboard_save_btn {
  width: 70px;
  margin: 0 32px;
  font-size: 10px;
  border-radius: 5px;
  height: 24px;
}
.admin_dashboard_content_area_container {
  margin-top: 50px;
  display: flex;
  align-items: flex-end;
}
