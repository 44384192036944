.team_card_container {
  text-align: start;
}
.team_card {
  cursor: pointer;
}
.team_card_data_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 40px 20px;
  height: 95%;
}
.team_card_inner_bio {
  font-family: "eina";
  text-align: start;
  font-size: 14px;
  line-height: 145.6%;
  color: #fff;
}
.team_card_inner_bio > a {
  font-family: "eina";
  text-decoration: underline;
  color: #fff;
}
.team_card_teamMemberName {
  margin-top: 10px;
  font-family: "Neometric Medium";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  text-transform: capitalize;
  line-height: 30px;
  letter-spacing: -0.55px;
  color: #000000;
}
.team_card_teamMemberTitle {
  font-family: "eina";
  margin-top: 5px;

  font-size: 18px;
  line-height: 26px;
  text-transform: capitalize;

  letter-spacing: -0.3px;
  color: #000000;
}
