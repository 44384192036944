.calendly_top_cards_wrapper {
  width: 320px;
  height: 165px;
  background: #ffffff;
  border: 1px;
  border-radius: 20px;
}
.calendly_top_cards_wrapper_container {
  margin: 20px 24px;

  height: 125px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.calendly_seats_card_second_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.calendly_seats_card_second {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  height: 125px;
}
.calendly_seats_card_second:last-child {
  border-left: 1px solid#5555DB;
}
.calendly_seats_remainingSeats {
  color: #ca5252;
}
.calendly_seats_seatsInUse {
  color: #5555db;
}
.calendly_seats_expenses {
  color: #8eca52;
}
.calendly_top_cards_wrapper h1.calendly_seats_wrapper {
  font-family: "Neometric Medium";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 56px;
  margin: 5px 0;
}
.calendly_top_cards_wrapper p.calendly_seats_wrapper {
  font-family: "Neometric Medium";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: black;
  margin: 5px 0;
}
/* .calendly_top_cards_wrapper hr{
    border: 1px solid #5555DB;
    width: 125px;
    margin: 0;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
} */
