.sign-in-logo-container {
  position: absolute;
  top: 55px;
  left: 80px;
}
.sign-in-container {
  padding: 50px 80px 80px 80px;
}
.sign-in-row {
  display: flex;
  height: 100vh;
}
.sign-in-row .sign-in-leftCol {
  background: #6565fc;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.sign-in-leftCol .leftCol-mainImg-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.sign-in-leftCol .leftCol-mainImg-container img {
  width: 100%;
  max-width: 523px;
}
.sign-in-row .sign-in-rightCol {
  margin: 0 auto;
}
.sign-in-rightCol .sign-in-rightCol-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
}
.sign-in-rightCol-heading {
  color: #2c205b;
}
.sign-in-rightCol-desc {
  line-height: 36px;
}
.sign-in-rightCol-linkedin-btn-container {
  margin: 40px 0px;
}
.sign-in-rightCol-linkedin-btn-container-error {
  margin-top: 10px;
}
.sign-in-dont-have-account-text {
  margin: 10px 0px 35px 0px;
}
.sign-in-terms-of-agreement {
  margin: 8px 0px 35px 0px;
  line-height: 21px;
}
.sign-in-user-exists-error {
  font-style: italic;
  font-size: 14px;
  color: rgb(119, 119, 119);
  margin-top: 10px;
  margin-bottom: -20px;
  line-height: 20px;
}
.sign-in-link {
  color: #6565fc;
  text-decoration: none;
  font-style: normal;
}
.sign-in-link:hover {
  cursor: pointer;
  color: #2c205b;
}
