.admin_dashboard_content_cards_container {
  display: flex;
  margin-top: 50px;
}
.admin_dashboard_content_card {
  margin: 0 16px;
}
.admin_dashboard_content_card:first-child {
  margin-left: 0;
}
.admin_dashboard_content_card_content {
  width: 183px;
  height: 110px;
  background: #ffffff;
  border-radius: 5px;
  display: flex;
  align-items: space-around;
  justify-content: center;
  margin: 24px 0;
}

.admin_dashboard_content_card_content_wrapper {
  margin: 10px;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.admin_dashboard_content_card_input {
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  width: 97%;
}
.admin_dashboard_content_card_input:hover,
.admin_dashboard_content_card_input:focus {
  outline: none;

  border-bottom: 1px solid #5555db;
}
.admin_dashboard_content_input_counter {
  position: absolute;
  bottom: 2px;
  right: 5px;
  font-size: 14px;
  color: rgba(85, 85, 219, 0.4);
  opacity: 0;
}
.admin_dashboard_content_card_input_link_title:focus
  + .admin_dashboard_content_input_counter {
  opacity: 1;
}
.admin_dashboard_content_card_input_title:focus
  + .admin_dashboard_content_input_counter {
  opacity: 1;
}
.admin_dashboard_content_card_input_title {
  font-family: "Neometric Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}
.admin_dashboard_content_card_input_link_title {
  font-family: "eina";
  font-size: 14px;
  resize: none;
  line-height: 24px;
  color: #5555db;
}
.admin_dashboard_content_card_input_link {
  color: silver;
}
