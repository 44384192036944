.signup-logo-container {
  position: absolute;
  top: 55px;
  left: 80px;
}
/* .sign-up-container {
  padding: 50px 80px 80px 80px;
} */
.sign-up-row {
  display: flex;
  height: 100vh;
}
.sign-up-row .sign-up-thankyou-leftCol {
  background-color: #f6f6f9;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sign-up-leftCol .leftCol-mainImg-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.sign-up-leftCol .leftCol-mainImg-container img {
  width: 100%;
  max-width: 523px;
}
.sign-up-row .sign-up-rightCol {
  margin: 0 auto;
}
.sign-up-rightCol .sign-up-rightCol-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
}

.sign-up-rightCol .sign-up-rightCol-thankyou-container-desc {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  text-align: start;
  height: 510px;
  width: 446px;
}
.sign-up-rightCol .sign-up-rightCol-thankyou-title {
  color: #2c205b;
}
.sign-up-rightCol-thankyou-desc {
  color: #2c205b;
}
