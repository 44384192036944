.admin_dashboard_btn_reject {
  border: 2px solid rgba(202, 82, 82, 0.2);
  color: #ca5252;
  background: transparent;
  margin-right: 4px !important;
}
.admin_dashboard_btn_accept {
  border: 2px solid rgba(142, 202, 82, 0.2);
  color: #8eca52;
  background: transparent;
  margin-left: 4px !important;
}
.admin_dashboard_btn_reject:hover {
  border: 2px solid rgba(202, 82, 82, 1);
}
.admin_dashboard_btn_accept:hover {
  border: 2px solid rgba(142, 202, 82, 1);
}
.admin_applications_header {
  font-family: "Neometric Medium";
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  margin-bottom: 60px;
}
