.mentor-video-call {
  min-height: calc(100vh - 50px);
  background-color: #f5f7f9;
  padding: 50px 30px 0;
}

.mentor-video-header-main {
  display: flex;
  justify-content: space-between;
}

.mentor-video-call-main-left {
  width: 70%;
  padding-right: 15px;
}

.mentor-video-call-header-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 30px;
}

.mentor-video-call-header-row .mentor-video-call-right-header {
  display: flex;
  align-items: center;
}

.start-and-time {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 15px 20px;
  font-size: 16px;
  color: #000000;
  font-family: "Neometric Regular";
  margin-right: 30px;
}

.start-and-time b {
  font-family: "Neometric Bold";
}

.mentor-setting-button button {
  background-color: #ffffff;
  color: #000000;
  border: 0;
  padding: 13px 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
}

.mentor-action-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}

.video-call-button-center {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.action-button-group {
  text-align: center;
}

.video-call-button-center .action-button-group {
  margin: 0 8px;
}

.action-button-group span {
  display: block;
  margin-top: 10px;
  font-weight: 600;
  font-family: "Neometric Regular";
  font-size: 14px;
}

.action-button-group button {
  width: 65px;
  height: 65px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}

.action-button-group button img {
  transition: all 0.5s ease-in-out;
}

.action-button-group button.active img.mute,
.action-button-group button.active img.video-off,
.action-button-group button.active img.share-screen {
  display: none;
}

.action-button-group button.disabled img.speaker,
.action-button-group button.disabled img.video,
.action-button-group button.disabled img.screen {
  display: none;
}

.action-button-group.screen-button-group button.disabled {
  background-color: #5555db;
}

.action-button-group button.disabled {
  background-color: #f22b2b;
}

.end-button-group button {
  background-color: #f22b2b;
}

.action-button-group button img.mute {
  width: 20px;
}

.action-button-group button img.video,
.action-button-group button img.video-off {
  width: 26px;
}

.mentor-video-call-main-right {
  width: 30%;
  padding-left: 15px;
}

.mentor-video-call-main-right .mentor-video-sidebar {
  height: 100%;
}
.mentorship-name {
  padding: 0 25px;
  background-color: #ffffff;
  border-radius: 15px;
  margin-bottom: 30px;
}

.mentorship-name span {
  font-size: 12px;
  font-weight: 400;
  font-family: "Neometric Regular";
}

.mentorship-name h3,
.mentor-chat-box h3 {
  font-size: 25px;
  font-weight: 600;
  font-family: "Neometric bold";
  margin-top: 5px;
}

.mentor-chat-box {
  /*height: 100%;*/
  border-radius: 20px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 616px;
}

.chet-box-header {
  padding: 30px 35px 0;
}
.content {
  overflow-y: auto;
}
.chet-box-body {
  margin-top: 30px;
  padding: 0 35px 5px;
  /*height: calc(100vh - 554px);*/
  overflow-y: scroll;
}

.chet-box-body::-webkit-scrollbar {
  width: 0;
}

.conversation-box .left-message {
  padding-right: 30px;
  text-align: left;
  margin-bottom: 5px;
}

.conversation-box .right-message {
  padding-left: 30px;
  text-align: right;
  margin-bottom: 5px;
}

.conversation-box .left-message .message-box {
  display: flex;
}

.conversation-box .left-message .message-box .img-wrapper {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 5px;
}

.conversation-box .left-message .message-box .img-wrapper img {
  width: 100%;
  height: auto;
}

.conversation-box .left-message .message-box .message {
  width: calc(100% - 45px);
}

.conversation-box .left-message .message-box .message span {
  display: block;
  width: fit-content;
  padding: 10px 15px;
  font-size: 14px;
  font-weight: 400;
  font-family: "Neometric Regular";
  background-color: #eeeefb;
  border-radius: 20px 20px 20px 5px;
  margin-bottom: 5px;
}

.conversation-box .left-message .message-box .message span + span {
  border-radius: 5px 20px 20px 20px;
}

.conversation-box .right-message .message-box .message span {
  display: block;
  width: fit-content;
  padding: 10px 15px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  font-family: "Neometric Regular";
  background-color: #5555db;
  margin-bottom: 5px;
  margin-left: auto;
  border-radius: 20px 20px 5px 20px;
}

.conversation-box .right-message .message-box .message span + span {
  border-radius: 20px 5px 20px 20px;
}

.chet-box-footer {
  padding: 19px 35px 67px;
  border-top: 1px solid rgba(0, 0, 0, 0.13);
}

.chet-box-footer .footer-content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.chet-box-footer .footer-content .type-box-media {
  display: flex;
  align-items: center;
  margin-left: 15px;
}

.chet-box-footer .footer-content .type-box-media button {
  background-color: transparent;
  border: 0;
  cursor: pointer;
}

.chet-box-footer .footer-content .message-type-box input {
  width: 100%;
  border: 0;
  color: #000000;
}

.chet-box-footer .footer-content .message-type-box input::placeholder {
  font-size: 14px;
  font-family: "Neometric Regular";
  color: #bfbfbf;
}

.chet-box-footer .footer-content .message-type-box input:focus {
  outline: none;
}

.chet-box-footer .footer-content .message-type-box {
  width: 100%;
}

.mentor-video-main img {
  width: 100%;
  height: auto;
}

.mentor-video-main {
  height: 500px;
  /*height: calc(25px + (375 - 25) * ((100vw - 320px) / (1920 - 320)));;*/
  position: relative;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #5555db;
  font-weight: 500;
}

.mentor-video-main .video-call-camera-off-setting {
  height: 500px;
}
.mentor-video-main > div:nth-child(1) {
  height: 100%;
}
.mentor-video-time {
  position: absolute;
  padding: 3px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 40px;
  left: 40px;
  background-color: #ffffff;
  border-radius: 10px;
  font-weight: 500;
  font-size: 20px;
  font-family: "Neometric Regular";
}

.mentor-video-time img {
  margin-right: 5px;
}

.mentor-video-network {
  padding: 5px 10px;
  border-radius: 10px;
  position: absolute;
  right: 30px;
  top: 35px;
  background-color: #ffffff;
  transform: rotateY(180deg);
}

.mentor-opposite-video {
  position: absolute;
  right: 30px;
  bottom: 30px;
}

@media (max-width: 1500px) {
  .mentor-video-main,
  .mentor-video-main .video-call-camera-off-setting {
    height: calc(102px + (620 - 102) * ((100vw - 320px) / (1920 - 320)));
  }
  .mentor-chat-box {
    height: calc(231px + (620 - 102) * ((100vw - 320px) / (1920 - 320)));
  }
}
@media (max-width: 959px) {
  .mentor-video-main,
  .mentor-video-main .video-call-camera-off-setting {
    height: calc(102px + (500 - 102) * ((100vw - 320px) / (1920 - 320)));
  }
  .mentor-chat-box {
    height: calc(230px + (500 - 102) * ((100vw - 320px) / (1920 - 320)));
  }
}
