.main-content {
  width: 800px;
  margin: 0 auto;
  padding: 150px 0 100px 0;
}

[data-custom-class="body"],
[data-custom-class="body"] * {
  background: transparent !important;
}
[data-custom-class="title"],
[data-custom-class="title"] * {
  font-family: eina !important;
  font-size: 26px !important;
  color: #000000 !important;
}
[data-custom-class="subtitle"],
[data-custom-class="subtitle"] * {
  font-family: eina !important;
  color: #595959 !important;
  font-size: 14px !important;
}
[data-custom-class="heading_1"],
[data-custom-class="heading_1"] * {
  font-family: eina !important;
  font-size: 19px !important;
  color: #000000 !important;
}
[data-custom-class="heading_2"],
[data-custom-class="heading_2"] * {
  font-family: eina !important;
  font-size: 17px !important;
  color: #000000 !important;
}
[data-custom-class="body_text"],
[data-custom-class="body_text"] * {
  color: #595959 !important;
  font-size: 14px !important;
  font-family: eina !important;
}
[data-custom-class="link"],
[data-custom-class="link"] * {
  color: #3030f1 !important;
  font-size: 14px !important;
  font-family: eina !important;
  word-break: break-word !important;
}

@media screen and (max-width: 1024px) {
  .main-content {
    width: 90%;
  }
}

@media screen and (max-width: 420px) {
  .main-content {
    width: 95%;
  }
}
