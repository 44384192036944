.sign-up-body {
  min-height: 100vh;
  background: url("../../assets/sign-up/Group\ 681-2.png"), #2c205b;
  background-attachment: fixed;
  display: flex;
}
.signup-logo-container {
  position: absolute;
  top: 55px;
  left: 80px;
  z-index: 1;
}
.sign-up-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 120px;
  padding-bottom: 100px;
}
.sign-up-row {
  display: flex;
  height: 100vh;
}
.sign-up-row .sign-up-leftCol {
  background-color: #2c205b;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sign-up-leftCol .leftCol-mainImg-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.sign-up-leftCol .leftCol-mainImg-container img {
  width: 100%;
  max-width: 523px;
}
.sign-up-row .sign-up-rightCol {
  margin: 0 auto;
}
.sign-up-rightCol .sign-up-rightCol-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
}
.sign-up-rightCol-heading {
  color: #2c205b;
}
.sign-up-rightCol-desc {
  line-height: 36px;
}
.sign-up-role-cards-container {
  display: flex;
  margin: 25px 0;
}
.sign-up-role-card-item {
  width: 250px;
  height: 178px;

  background: #ffffff;
  border: 1.5px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 20px;
  margin: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  transition: 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);
}
.sign-up-role-card-item-title {
  margin-top: 5px;
  font-size: 14px;
  line-height: 20px;
}

.sign-up-role-card-item-active {
  border: 1.5px solid #5555db;

  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
}
.sign-up-role-card-item:hover {
  border: 1.5px solid #5555db;

  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
}
.already-have-account-text {
  margin: 25px 0;
}
.sign-up-terms-of-agreement {
  margin: 25px 0;
  line-height: 21px;
}
.sign-up-link {
  color: #5555db;
  text-decoration: none;
  font-style: normal;
}
.sign-up-link:hover {
  cursor: pointer;
  color: #2c205b;
}
.sign-up-panel {
  width: 800px;
  max-width: 800px;
  min-height: 450px;
  padding: 45px 0;
  border-radius: 35px;
  background: white;
  margin: 0 auto;
  position: relative;
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
}
.form-wrapper {
  /* position: relative; */
  margin: 0px 55px;
}
.sign-up-form-heading {
  color: #2c205b;
  font-family: "Neometric Bold";
}
.sign-up-form-paragraph,
.sign-up-form-paragraph p {
  color: #2c205b;
  font-size: 16px !important;
}
.sign-up-form-paragraph strong {
  font-family: "Neometric Medium";
}
.sign-up-form-paragraph a {
  color: #5555db;
  text-decoration: none;
}
.sign-up-form-paragraph a:hover {
  color: #2c205b;
  text-decoration: none;
}
.sign-up-verify-step-paragraph {
  color: #777777;
  font-size: 15px;
}
.sign-up-verify-step-paragraph-note {
  font-style: italic;
}
.sign-up-panel .form-step-btn-group {
  position: absolute;
  bottom: 20px;
  right: 60px;
  display: flex;
}
.sign-up-panel .next-btn {
  background: #5555db;
  color: white;
}
.sign-up-panel .back-btn {
  background: #eeeefb;
  color: #5555db;
}
.sign-up-panel .submit-btn:enabled {
  background: #5555db;
  color: white;
}
.sign-up-panel .next-btn:disabled {
  background: #c4c4c4;
  color: white;
  cursor: default;
}
.sign-up-panel .submit-btn:disabled {
  background: #c4c4c4;
  color: white;
  cursor: default;
}
.steps-icon-container {
  height: 62px;
  width: 587px;
  background: #eeeefb;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding-left: 25px;
}
.step-icon-container {
  display: flex;
  align-items: center;
}
.step-icon-img-container {
  height: 33px;
  width: 33px;
  border-radius: 10px;
  position: relative;
  background: #ffffff;
}
.step-icon-container-active .step-icon-img-container {
  background: #5555db;
  margin-right: 10px;
}
.step-icon-img-container img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.step-icon-container .step-icon-title {
  display: none;
}
.step-icon-container-active .step-icon-title {
  display: block;
}
.step-icon-no {
  font-size: 11px;
  color: #2c205b;
  line-height: 14px;
}
.step-icon-name {
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;

  color: #2c205b;
}
.steps-icon-container .divider {
  width: 36px;
  height: 0px;
  border: 1px solid rgba(0, 0, 0, 0.14);
  transform: rotate(90deg);
}
/* Radio Btn Css Starts Here */
.sign-up-panel .form-radio-item {
  position: relative;
  margin: 5px 25px 0px 0px;
}
.sign-up-panel .form-radio-item input[type="radio"] {
  position: absolute;
  visibility: hidden;
  transition: 0.3s ease-in;
}

.sign-up-panel .form-radio-item label {
  /* color: #2c205b; */
  color: #000000;

  cursor: pointer;
  font-size: 14px;
  padding: 10px 0 10px 34px;
  z-index: 10;
  display: inline-block;
  position: relative;
  font-family: "Neometric Regular";
  line-height: 20px;
}
.sign-up-panel .form-radio-item .check {
  cursor: pointer;
  display: block;
  position: absolute;
  border: 1px solid #aaaaaa;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  /*  */
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  z-index: 1;
  transition: border 0.25s linear;
  -webkit-transition: border 0.25s linear;
}
.sign-up-panel input[type="radio"]:disabled ~ .check {
  background: #cacaca;
}
.sign-up-panel input[type="radio"]:disabled ~ .check::before {
  background: #cacaca;
}

.sign-up-panel input[type="radio"]:checked ~ .check {
  border: 1px solid #5555db;
  background: #5555db;
}

.sign-up-panel input[type="radio"]:checked ~ .check::before {
  background: #5555db;
}
.sign-up-panel .file-btn {
  margin: 10px 0px 5px 0px;
  background: #eeeefb;
  color: #5555db;
  transition: 0.3s ease-in;
}
.sign-up-panel .file-btn:hover {
  /* background: #5555db;
  color: white; */
  box-shadow: 0 5px 5px 1px rgba(189, 189, 189, 0.25);
}
.sign-up-panel .remove-file {
  cursor: pointer;
  margin: 0px 10px;
}
.sign-up-panel .remove-file i {
  color: red;
}
.sign-up-panel .file-chosen-text {
  font-size: 12px;
  display: inline-block;
  margin: 0px 20px;
}
.sign-up-panel .editable-form-radio {
  background: #fbfbff;
  border: 1.5px solid #d8d8d8;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 10px;
  width: 90%;
}
.sign-up-panel .editable-form-radio:focus {
  border: 1.5px solid #5555db;
}
.sign-up-panel .editable-form-radio:not(:focus) {
  border: 1.5px solid #d8d8d8;
}
/* Radio Btn Css Ends Here */

/* Checkbox Css Starts Here */
.sign-up-panel .checkbox-container {
  position: relative;
}
.sign-up-panel input[type="checkbox"] {
  display: none;
}
.sign-up-panel .form-checkbox-item label {
  color: #000000;

  padding: 10px 0 10px 35px;
  cursor: pointer;
  position: relative;
  font-family: "Neometric Regular";
  font-size: 14px;
}
.sign-up-panel .form-checkbox-item {
  margin-bottom: 25px;
  max-height: 100px;
  /* width: 50%; */
}
.sign-up-panel .form-checkbox-item:last-child {
  margin-bottom: 17px;
  max-height: 100px;
  /* width: 50%; */
}
.sign-up-panel .form-checkbox-item label::before {
  content: "";
  height: 25px;
  width: 25px;
  background-color: transparent;
  border: 1px solid #aaaaaa;
  border-radius: 5px;
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  cursor: pointer;
}
.sign-up-panel input[type="checkbox"]:checked ~ label::before {
  border-color: #5555db;
  background-color: #5555db;
}
.sign-up-panel .list-checkbox {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  /* height: 125px; */
  flex-wrap: wrap;
  align-items: flex-start;
}

/* checkbox Css ends here */
/*input text style*/
.sign-up-panel .form-textbox {
  font-family: "Neometric Regular";

  background: #fbfbff;
  color: #000000;

  border: 1.5px solid #d8d8d8;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 10px;
  width: 90%;
  margin: 5px 0px;
}
.sign-up-panel .form-textbox:focus {
  border: 1.5px solid #5555db;
}
.sign-up-panel .form-textbox:not(:focus) {
  border: 1.5px solid #d8d8d8;
}
/* number css */
.sign-up-panel .form-number {
  font-family: "Neometric Regular";
  color: #000000;

  background: #fbfbff;
  border: 1.5px solid #d8d8d8;
  box-sizing: border-box;
  border-radius: 10px;
  display: block;
  padding: 10px;
  width: 15%;
  margin: 5px 0px;
}
.sign-up-panel .form-number:focus {
  border: 1.5px solid #5555db;
}
.sign-up-panel .form-number:not(:focus) {
  border: 1.5px solid #d8d8d8;
}
/* select css starts here */
.sign-up-panel .form-select {
  background: #fbfbff;
  border: 1.5px solid #d8d8d8;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 10px;
  width: 90%;
}
.sign-up-panel .form-select:focus {
  border: 1.5px solid #5555db;
}
.sign-up-panel .form-select:not(:focus) {
  border: 1.5px solid #d8d8d8;
}
/* select ends here */

.sign-up-panel .form-line {
  margin: 20px 0px;
}
.sign-up-panel .step-form-content {
  margin: 25px 0px 90px 0px;
}
.sign-up-panel .form-label {
  font-family: "Neometric Medium";
  /* display: inline-block; */
  display: flex;
  margin: 8px 0px;
  color: #2c205b;
  font-size: 14px;
  line-height: 20px;
}
.login-with-linkedin {
  display: inline-block;
  background-image: url("../../assets/linkedin-btn/linkedin.svg");
  height: 50px;
  background-repeat: no-repeat;
  width: 255px;
}
.login-with-linkedin-container {
  margin: 30px 0px;
}
.login-with-linkedin-error-container {
  margin-top: 5px;
}
.login-with-linkedin:hover {
  height: 50px;
  background-image: url("../../assets/linkedin-btn/linkedin-hover.svg");

  cursor: pointer;
}
@media screen and (max-width: 1024px) {
  .sign-up-role-card-item {
    width: 215px;
    height: 178px;

    margin: 0 10px;
  }
}
