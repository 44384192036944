.team_details_popup {
  display: block;
  position: fixed; /* Stay in place */
  z-index: 555555;
  left: 0;
  top: 0px;
  width: 100%; /* Full width */
  height: 100vh; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  z-index: 111111111111111111;
}

/* Modal Content */
.team_details_pop_content {
  background-color: #fefefe;
  border-radius: 20px;
  margin: auto;
  border: 1px solid #888;
  width: 1069px;
  height: 90vh;
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.team_details_pop_content_wrapper {
  margin: 50px;
}
.team_details_pop_content_image_container {
  height: 200px;
  width: 180px;
  border-radius: 10px;
  /* background: #000; */
}
.team_details_pop_content_teamMember_name {
  margin: 10px 0;
  font-family: "Neometric Medium";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
}
.team_details_pop_name_and_linkedin {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.team_details_pop_content_teamMember_title {
  margin: 10px 0;

  font-family: "eina";
  font-size: 18px;
  line-height: 26px;

  letter-spacing: -0.3px;
}
.team_details_pop_content_teamMember_bio {
  margin: 10px 0;
  font-family: "eina";
  font-size: 14px;
  line-height: 145.6%;
}
/* The Close Button */
.close {
  color: #aaaaaa;
  font-size: 28px;
  font-weight: bold;
  position: absolute;
  right: 20px;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .team_details_pop_content {
    width: 85%;
    height: 85%;
    overflow: auto;
  }
  .team_details_pop_content_wrapper {
    margin: 25px;
  }
}
