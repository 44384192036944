.popup-form-validation {
  position: absolute;
  color: red;
  font-family: eina;
  font-style: italic;

  font-size: 12px;
  margin-left: 0px;
  margin-top: 0px;
  top: 25px;
}
.signup-form-validation {
  position: relative;
  color: red;
  font-family: eina;
  font-style: italic;

  font-size: 12px;
  margin-left: 0px;
  margin-top: 0px;
  /* top: 5px; */
  /* top: 25px; */
}
@media screen and (max-width: 420px) {
  .popup-form-validation {
    top: 10px;
    font-size: 9px;
    margin-bottom: 4px;
    margin-top: 11px;
  }
}
