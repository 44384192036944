/**
*** SIMPLE GRID
*** (C) ZACH COLE 2016
**/
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");

@font-face {
  font-family: "eina";
  src: local("eina"), url(/src/assets/fonts/Eina01-Regular.ttf) format("woff");
}

@font-face {
  font-family: "Neometric Regular";
  src: local("Neometric"),
    url(/src/assets/fonts/NeometricLight.woff) format("woff");
}
@font-face {
  font-family: "Neometric Medium";
  src: local("Neometric"),
    url(/src/assets/fonts/Neometric-Medium-Regular.woff) format("woff");
}
@font-face {
  font-family: "Neometric Bold";
  src: local("Neometric"),
    url(/src/assets/fonts/Unnamed-Bold-Regular.woff) format("woff");
}

/* init */
@media screen and (min-width: 1023px) {
  .br_mobile {
    display: none;
  }
}

/* UNIVERSAL */

html,
body {
  overflow-x: hidden;

  width: 100%;
  margin: 0;
  padding: 0;
  left: 0;
  top: 0;
  font-size: 100%;
}
a {
  text-decoration: none;
}
.page_wrapper.hidden {
  overflow-y: hidden;
  scroll-behavior: smooth;
}

h1 {
  font-family: "Neometric Bold";
  font-size: 46.6px;
}

h2 {
  font-family: "Neometric Medium";
  padding: 0;
  margin: 0;
  font-size: 36px;
}

h3 {
  font-family: "Neometric Medium";

  padding: 0;
  margin: 0;
  font-size: 24px;
}

h4 {
  font-family: "Neometric Regular";
  padding: 0;
  margin: 0;
  font-size: 14px;
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: 0.875rem;
}

p {
  font-family: "eina";
  font-size: 18px;
  line-height: 21px;
  padding: 0;
  margin: 0;
}
.cardo-link {
  color: #5555db;
  text-decoration: none;
}
.cardo-link:hover {
  text-decoration: underline;
}
.font-light {
  font-weight: 300;
}

.font-regular {
  font-weight: 400;
}

.font-heavy {
  font-weight: 700;
}

/* POSITIONING */

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.justify {
  text-align: justify;
}

/* ==== GRID SYSTEM ==== */

.container_wide {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.container_narrow {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.row {
  position: relative;
  width: 100%;
}

.row [class^="col"] {
  float: left;
  margin: 0 2%;
  min-height: 0.125rem;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
  width: 96%;
}

.col-1-sm {
  width: 4.33%;
}

.col-2-sm {
  width: 12.66%;
}

.col-3-sm {
  width: 21%;
}

.col-4-sm {
  width: 29.33%;
}

.col-5-sm {
  width: 37.66%;
}

.col-6-sm {
  width: 46%;
}

.col-7-sm {
  width: 54.33%;
}

.col-8-sm {
  width: 62.66%;
}

.col-9-sm {
  width: 71%;
}

.col-10-sm {
  width: 79.33%;
}

.col-11-sm {
  width: 87.66%;
}

.col-12-sm {
  width: 96%;
}

.row::after {
  content: "";
  display: table;
  clear: both;
}

.hidden-sm {
  display: none;
}
.br_mobile {
  display: none;
}
.img_desk {
  display: block;
}
.img_mobile {
  display: none;
}
.text-decoration-none {
  text-decoration: none;
}

.cardo-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7.55556px;
  margin: 30px 15px 30px 0px;
  width: 155px;
  height: 45px;
  border: 0px;
  font-size: 12px;
  letter-spacing: 0.5px;
  font-family: "Neometric Regular";
}
.cardo-btn-sm {
  width: 97px;
  height: 32px;
  font-weight: 500;
}
.save-btn {
  width: 205px;
  height: 65px;
  font-size: 16px;
}
.landing_page_btn {
  font-family: "Mulish";
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
}
.pink-btn,
.blue-btn {
  transition: 0.3s ease-in;
}
.pink-btn {
  font-size: 16px;
  color: black;
  background: #fac4dc;
}
.pink-btn:hover {
  background: rgb(242, 188, 212);
  transform: scale(1.005) translatey(-2px);
  box-shadow: 0 5px 5px 1px rgba(189, 189, 189, 0.25);
}
/*  */
.transparent_btn_lightblue {
  background: transparent;
  border: 1px solid #5555db;
  color: #5555db;
}
.transparent_btn_lightblue:hover {
  background: #5555db;
  color: white;
}
.blue-btn:hover {
  background: #2c205b;
  transform: scale(1.005) translatey(-2px);
  box-shadow: 0 5px 5px 1px rgba(189, 189, 189, 0.25);
}
.btn-bg-lightblue {
  background: #5555db;
  color: white;
}
.btn-bg-lightindigo {
  background: #eeeefb;
  color: #5555db;
}
.btn-bg-darkblue {
  background: #2c205b;
  color: aliceblue;
}
.btn-bg-pink {
  background: #fac4dc;
}
.position-relative {
  position: relative;
}
.display-flex {
  display: flex;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-between {
  justify-content: space-between;
}
.align-items-center {
  align-items: center;
}
.flex-direction-column {
  flex-direction: column;
}

.m-0 {
  margin: 0px;
}
.m-5 {
  margin: 5px;
}
.m-10 {
  margin: 10px;
}
.m-15 {
  margin: 15px;
}
.m-20 {
  margin: 20px;
}
.m-25 {
  margin: 25px;
}
.mr-0 {
  margin-right: 0px;
}
.mr-5 {
  margin-right: 5px;
}
.mr-10 {
  margin-right: 10px;
}
.mr-15 {
  margin-right: 15px;
}
.mr-20 {
  margin-right: 20px;
}
.mr-25 {
  margin-right: 25px;
}
.ml-0 {
  margin-left: 0px;
}
.ml-5 {
  margin-left: 5px;
}
.ml-10 {
  margin-left: 10px;
}
.ml-15 {
  margin-left: 15px;
}
.ml-20 {
  margin-left: 20px;
}
.ml-25 {
  margin-left: 25px;
}
.mt-0 {
  margin-top: 0px;
}
.mt-5 {
  margin-top: 5px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-25 {
  margin-top: 25px;
}
.mb-0 {
  margin-bottom: 0px;
}
.mb-5 {
  margin-bottom: 5px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-25 {
  margin-bottom: 25px;
}

@media screen and (max-width: 1280px) {
  h1 {
    font-size: 36px;
    font-family: "Neometric Bold";
  }
  h2 {
    font-family: "Neometric Medium";
    padding: 0;
    margin: 0;
    font-size: 30px;
  }

  h3 {
    padding: 0;
    margin: 0;
    font-size: 20px;
  }
  p {
    font-family: "eina";
    font-size: 15px;
    line-height: 21px;
    padding: 0;
    margin: 0;
  }
}
.overflow-scroll {
  overflow: scroll;
}
.font-Mulish {
  font-family: "Mulish";
}
.font-Neometric-Bold {
  font-family: "Neometric Bold";
}
.font-Neometric-Regular {
  font-family: "Neometric Regular";
}
.font-Neometric-Medium {
  font-family: "Neometric Medium";
}

.font-eina {
  font-family: "eina";
}
.text-align-center {
  text-align: center;
}
.line-height {
  line-height: 22px;
}
.line-height-12 {
  line-height: 12px;
}
.line-height-14 {
  line-height: 14px;
}
.line-height-16 {
  line-height: 16px;
}
.line-height-18 {
  line-height: 18px;
}
.line-height-20 {
  line-height: 20px;
}
.font-size-10 {
  font-size: 10px;
}
.font-size-12 {
  font-size: 12px;
}
.font-size-14 {
  font-size: 14px;
}
.font-size-16 {
  font-size: 16px;
}
.font-size-18 {
  font-size: 18px;
}
.font-size-20 {
  font-size: 20px;
}
.font-color-darkblue {
  color: #2c205b;
}
.font-color-black {
  color: #000000;
}
@media screen and (max-width: 1024px) {
  h1 {
    font-size: 30px;
    font-family: "Neometric Bold";
  }
  h2 {
    font-family: "Neometric Medium";
    padding: 0;
    margin: 0;
    font-size: 24px;
  }

  h4 {
    font-family: "Neometric Regular";
    padding: 0;
    margin: 0;
    font-size: 14px;
  }
  p {
    font-family: "eina";
    font-size: 12px;
    line-height: 21px;
    padding: 0;
    margin: 0;
  }
}
@media only screen and (min-width: 33.75em) {
  /* 540px */
  .container_wide {
    width: 80%;
  }
  .container_narrow {
    width: 80%;
  }
}

@media only screen and (min-width: 45em) {
  /* 720px */
  .col-1 {
    width: 4.33%;
  }

  .col-2 {
    width: 12.66%;
  }

  .col-3 {
    width: 21%;
  }

  .col-4 {
    width: 29.33%;
  }

  .col-5 {
    width: 37.66%;
  }

  .col-6 {
    width: 46%;
  }

  .col-7 {
    width: 54.33%;
  }

  .col-8 {
    width: 62.66%;
  }

  .col-9 {
    width: 71%;
  }

  .col-10 {
    width: 79.33%;
  }

  .col-11 {
    width: 87.66%;
  }

  .col-12 {
    width: 96%;
  }

  .hidden-sm {
    display: block;
  }
}

@media only screen and (min-width: 60em) {
  /* 960px */
  .container_wide {
    width: 90%;
    max-width: 1300px;
  }
  .container_narrow {
    width: 90%;
    max-width: 1140px;
  }
}
@media screen and (max-width: 420px) {
  .img_desk {
    display: none;
  }
  .img_mobile {
    display: block;
  }
  .br_desk {
    display: none;
  }
  .br_mobile {
    display: block;
  }
  h1 {
    font-size: 27px;
    line-height: 31.5px;
  }

  h2 {
    padding: 0;
    margin: 0;
    font-size: 24px;
    line-height: 29px;
  }

  h3 {
    padding: 0;
    margin: 0;
    font-size: 12px;
  }

  h4 {
    font-size: 1.125rem;
  }

  h5 {
    font-size: 1rem;
  }

  h6 {
    font-size: 0.875rem;
  }

  p {
    font-size: 14px;
    line-height: 16px;
    padding: 0;
    margin: 0;
  }
  .container_wide {
    width: 100%;
  }
}

/*spinner*/
.loader {
  display: none;
  position: relative;
  width: 80px;
  height: 80px;
}
.loader.active {
  display: inline-block;
}
.loader div {
  box-sizing: border-box;
  display: block;
  position: absolute;

  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #2c205b;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #2c205b transparent transparent transparent;
}
.loader div:nth-child(1) {
  animation-delay: -0.45s;
}
.loader div:nth-child(2) {
  animation-delay: -0.3s;
}
.loader div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
